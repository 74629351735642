import { useEffect, useState } from "react";

/**
 * @typedef {import("type/response/Course").IKurikulumJSON} IKurikulumJSON
 * @typedef {import('type/response/Course').IKurikulum[]} IKurikulum
 * @param {IKurikulumJSON} curriculumJSON
 * @returns {IKurikulum}
 * menjadikan struktur kurikulum bentuk JSON array menjadi json yang lebih terstruktur
 */
const useScrapeCurriculum = (curriculumJSON) => {
  /**
   * @type {[IKurikulum]}
   */
  const [kurikulum, setKurikulum] = useState([]);

  useEffect(() => {
    //kurikulum
    setKurikulum([]);
    if (curriculumJSON) {
      const dataDapat = curriculumJSON.data;
      const kurikulumDapat = dataDapat;
      const kurikulumProses = [];
      let seksi = true;
      let seksiSebelumnya = 0;
      let durasiKumulatif = 0;
      for (let i = 0; i < kurikulumDapat?.length; i++) {
        if (kurikulumDapat[i]["type"] === "section") {
          seksi = true;
          durasiKumulatif = 0;
        } else {
          seksi = false;
        }
        if (seksi) {
          kurikulumProses.push({
            bagian: kurikulumDapat[i]["title"],
            menit: kurikulumDapat[i]["duration"] / 60,
            isiKelas: [],
          });
          seksiSebelumnya = (kurikulumProses.length ?? 1) - 1;
        } else {
          // const hurufPertamaAngka = kurikulumDapat[i]["title"]
          //   ?.substring(0, 2)
          //   ?.match(/\d+/g);
          kurikulumProses[seksiSebelumnya].isiKelas.push({
            kelas: kurikulumDapat[i]["title"],
            idItem: kurikulumDapat[i]["id"],
            menit:
              kurikulumDapat[i]["duration"] < 500000
                ? kurikulumDapat[i]["duration"] / 60
                : -1,
            jenis:
              kurikulumDapat[i]["type"] === "unit" &&
              kurikulumDapat[i]["format"] === "play"
                ? "VIDEO"
                : kurikulumDapat[i]["type"] === "unit"
                ? "INFO"
                : kurikulumDapat[i]["type"] === "quiz"
                ? "KUIS"
                : undefined,
            jenisKuis:
              kurikulumDapat[i]["ark_quiz_type"] === "kuis"
                ? "KUIS"
                : kurikulumDapat[i]["ark_quiz_type"] === "posttest"
                ? "POST"
                : kurikulumDapat[i]["ark_quiz_type"] === "pretest"
                ? "PRE"
                : undefined,
          });
        }
        //kuis, posttest, pretest
        // if (
        //   (kurikulumDapat[i + 1] &&
        //     kurikulumDapat[i + 1]["type"] === "section") ||
        //   kurikulumDapat[i + 1] == null
        // ) {
        //   const durasiDapat = kurikulumDapat[i]["duration"];
        //   if (durasiDapat < 500000) {
        //     durasiKumulatif = durasiKumulatif + durasiDapat;
        //   }
        //   kurikulumProses[seksiSebelumnya].menit = durasiKumulatif / 60;
        // } else if (!seksi) {
        //   const durasiDapat = kurikulumDapat[i]["duration"];
        //   if (durasiDapat < 500000) {
        //     durasiKumulatif = durasiKumulatif + durasiDapat;
        //   }
        // }
      }
      setKurikulum([...kurikulumProses]);
    }
  }, [curriculumJSON]);

  return kurikulum;
};

export default useScrapeCurriculum;
