import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setDataGlobalWatchCourse } from "store/actions/globalWatchCourse";

const useReset = () => {
  const dispatch = useDispatch();

  const resetAll = useCallback(() => {
    dispatch(setDataGlobalWatchCourse("watchCourseVideoSelesai", false));
    dispatch(setDataGlobalWatchCourse("watchCourseLampiranSelesai", false));
    dispatch(setDataGlobalWatchCourse("watchCourseLampiranDownloaded", false));
    dispatch(setDataGlobalWatchCourse("watchCourseGamesSelesai", false));
    dispatch(
      setDataGlobalWatchCourse("watchCourseUnjukKeterampilanSelesai", false)
    );
    dispatch(setDataGlobalWatchCourse("watchCourseKuesionerSelesai", false));
  });

  return resetAll;
};

export default useReset;
