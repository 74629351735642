import axios from "axios";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { fetchCourseMicroservices } from "store/actions/course";
import useToken from "utils/useToken";
import useCekDalamLayar from "./useCekDalamLayar";

/**
 * @deprecated gunakan useFetchUmum
 *
 * fetch pada saat refKomponen berada di dalam layar, jika refKomponen undefined/kosong, akan langsung fetch
 * @param {string} link
 * @param {number | null} idKelas
 * @param {import("react").Ref<HTMLElement>} [refKomponen]
 * @returns {[any, boolean, eror]}
 */
export default function useFetchCourseMicroservicesDalamLayarV2(
  idKelas,
  link,
  refKomponen
) {
  const dalamLayar = useCekDalamLayar(refKomponen);
  const [dataJSON, setDataJSON] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token] = useToken();
  const [eror, setEror] = useState({
    eror: false,
    message: "",
  });
  const reqSedangGetSebelumnya = useRef(null);
  const linkSebelumnya = useRef(null);

  useEffect(() => {
    if (idKelas === null || idKelas === undefined) return;
    const benarDalamLayar = refKomponen === undefined || dalamLayar;
    const isDetail = link === "detail" || link === "";
    const linkSama = linkSebelumnya.current === link;
    const dataKosong = !dataJSON;
    const idKelasSama =
      dataJSON?.data?.[0]?.course?.id === Number(idKelas) || !isDetail;
    if (
      !benarDalamLayar ||
      idKelas === null ||
      (!dataKosong && idKelasSama && linkSama)
    )
      return;
    const reqMicroservices = axios.CancelToken.source();

    reqSedangGetSebelumnya.current?.cancel();
    let cancelFetch = false;
    const fetch = async () => {
      setLoading(true);
      reqSedangGetSebelumnya.current = reqMicroservices;
      const hasilFetch = await fetchCourseMicroservices(
        idKelas,
        link,
        token,
        reqMicroservices
      );
      if (cancelFetch) return;
      if (hasilFetch.success) {
        setDataJSON(hasilFetch.data);
      } else {
        setEror({
          eror: true,
          message: hasilFetch.message,
        });
        setDataJSON(null);
      }
      linkSebelumnya.current = link;
      setLoading(false);
    };
    fetch();
    return () => {
      reqMicroservices.cancel();
      cancelFetch = true;
    };
  }, [JSON.stringify(idKelas), link, dalamLayar]);

  return [dataJSON, loading, eror];
}
