import axios from "axios";
import store from "store";

import {
  DELETE_HISTORY_SEARCH,
  FETCH_LIST_SEARCH,
  FETCH_SEARCH,
  SET_HISTORY_SEARCH,
  SET_SEARCH_TERM,
} from "../types";

export const api_course_url = process.env.REACT_APP_API_BACKEND_COURSE;

export const fetchSearch = (search) => (dispatch) => {
  // return axios
  //   .get(`${api_course_url}/api/v1/course/list_title`)
  //   .then((response) => {
  //     // console.log(response);
  //     dispatch({
  //       type: FETCH_SEARCH,
  //       payload: {
  //         [search]: response?.data?.data,
  //       },
  //     });
  //   })
  //   .catch((e) => {
  //     console.log(e);
  //   });
};
export const fetchListAll = (setSearchKelas, search) => {
  return axios
    .get(`${api_course_url}/api/v1/prakerja/course/list_title`, {
      // mode: "cors",
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   "Content-Type": "application/json",
      // },
    })
    .then((response) => {
      store.dispatch({
        type: FETCH_LIST_SEARCH,
        payload: { [search]: response.data.data },
      });
      setSearchKelas(response.data.data || []);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const fetchMentor = (setSearchMentor, mentor) => {
  return axios
    .get(`${api_course_url}/api/v1/prakerja/course/lembaga`, {
      // mode: "cors",
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   "Content-Type": "application/json",
      // },
    })
    .then((response) => {
      console.log(response);
      store.dispatch({
        type: FETCH_LIST_SEARCH,
        payload: { [mentor]: response.data.data },
      });
      setSearchMentor(response.data.data);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const fetchListSearch = (setSearchKelas, search) => {
  return axios
    .get(`${api_course_url}/api/v1/prakerja/course/list_title`, {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      store.dispatch({
        type: FETCH_LIST_SEARCH,
        payload: { [search]: response.data.data },
      });
      setSearchKelas(response.data.data);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const fetchResultSearch = (resultSearch, term) => {
  // console.log("redux:", term);
  return axios
    .get(`${api_course_url}/api/v1/prakerja/course/search?keyword=${term}`)
    .then((response) => {
      // console.log("data", response.data);
      store.dispatch({
        type: SET_SEARCH_TERM,
        payload: { [resultSearch]: response.data.data },
      });
      return response;
    })
    .catch((e) => console.log(e));
};

export const addLastSearch = (resultSearch, value, dataHistoryOld) => {
  dataHistoryOld.push(value);
  const dataHistoryNew = [...new Set(dataHistoryOld)];
  localStorage.setItem("historySearch", JSON.stringify(dataHistoryNew));
  return store.dispatch({
    type: SET_HISTORY_SEARCH,
    payload: { [resultSearch]: dataHistoryNew },
  });
};

export const deleteHistorySearch = (resultSearch, value, dataHistoryOld) => {
  const historyAfterDelete = dataHistoryOld?.filter((i) => {
    return i !== value;
  });
  const newHistorySearch = [...new Set(historyAfterDelete)];
  localStorage.setItem("historySearch", JSON.stringify(newHistorySearch));
  return store.dispatch({
    type: DELETE_HISTORY_SEARCH,
    payload: { [resultSearch]: newHistorySearch },
  });
};
