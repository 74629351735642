import "./Certificate.scss";
import { FC } from "react";
import ImgCetificate from "./img-certificate.svg";
import { dapatkanSRCSertifikat } from "parts/CourseDetailPage/CoursePageReguler/bagian/Fungsi/dapatkanHTML";

const Certificate: FC<ICertificate> = ({ infoKelas }) => {
  const sbrSert = dapatkanSRCSertifikat(infoKelas?.deskripsiHTML);
  return (
    <div className="coursepage-certificate-wrapper">
      <h3 className="title">E-Sertifikat</h3>
      <div className="isi-certificate d-flex">
        <div className="card-image-certificate">
          {sbrSert != null && (
            <img src={sbrSert} alt="" className="img-certificate" />
          )}
        </div>
        <div className="card-information">
          <p className="title-card">Informasi E-Sertifikat</p>
          <ul className="list-info d-flex">
            <li className="item-info">
              Peserta yang menyelesaikan seluruh materi pelatihan, melampaui
              nilai minimal kelulusan kuis, dan menyelesaikan post-test akan
              mendapatkan sertifikat penyelesaian berupa sertifikat elektronik.
              peserta.
            </li>
            <li className="item-info">
              Klik tombol "Lihat Sertifikat" untuk menampilkan sertifikat di
              halaman profilmu.
            </li>
            <li className="item-info">
              Sertifikat Prakerja akan dikirimkan kepada PMO melalui marketplace
              1x24 jam hari kerja untuk ditampilkan pada Dashboard Prakerja
              peserta.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

interface ICertificate {
  infoKelas: any;
}

export default Certificate;
