import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginGoogle } from "store/actions/auth";
import { setGlobalData } from "store/actions/global";
import { useUser } from "./useUser"

const { useEffect } = require("react")


const useInitGoogle = () => {
  const user = useUser();

  const dispatch = useDispatch()

  useEffect(() => {
    window.onload = function () {
      window?.google?.accounts.id.initialize({
        client_id: "265001851813-k0fgkfv635681ncrbgmrohcj99jhddm6.apps.googleusercontent.com",
        callback: handleCredentialResponse
      });
      dispatch && dispatch(setGlobalData("googleInitialized", true))
      if (!user) {
        window?.google?.accounts.id.prompt();
      }
    }
  }, [user])

  async function handleCredentialResponse(response) {
    dispatch && dispatch(setGlobalData("loadingGoogle", true))
    console.log(response);
    await loginGoogle(response?.credential);
    await loginGoogle(response?.credential);
  }
}

export default useInitGoogle