import "./GabungKelas.scss";
import { FC, useEffect, useRef, useState } from "react";
import IconZoom from "./icon-zoom.svg";
import IconJadwal from "./icon-jadwal.svg";
import IconPlay from "./icon-play.png";
import IconInstructor from "./icon-instructor.svg";
import useScrollUpOrDown from "utils/useScrollUpOrDown";
import TabelGabungKelas from "./TabelGabungKelas";
import { getFullDate, getFullDateNoYear } from "utils/formatDateWebinar";
import { dapatkanHTMLVideo } from "parts/CourseDetailPage/CoursePageReguler/bagian/Fungsi/dapatkanHTML";
import Skeleton from "react-loading-skeleton";
import usePopup from "elements/PopupGeneral/PopupGeneral";
import PopupVideo from "parts/CourseDetailPage/CoursePageReguler/bagian/PopupVideo/PopupVideo";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import SekeletonGabungKelas from "./SkeletonGabungKelas";
import { tambahKeKeranjang } from "store/actions/payment";
import { useSetDataWatchCourse } from "utils/useStoreWatchCourse";
import { useGetJadwalWebinar } from "utils/useGetJadwalWebinar";
import Popup from "../Popup";

const GabungKelas: FC<IGabungKelas> = ({
  dataWebinar,
  infoKelas,
  dataDetail,
  loading,
  lebarLayar,
  heightHero,
  heightBodyCoursePage,
}) => {
  const { openPopup, closePopup } = usePopup();
  const refHeightThumnail = useRef<any>(null);
  // const { startDate, endDate } = useGetJadwalWebinar(infoKelas?.idKelas);
  const [heightThumnail, setHeightThumnail] = useState<number>(0);
  const scroll = useScrollUpOrDown();
  const [showOption, setShowOption] = useState<boolean>(false);
  const [sesiTerpilih, setSesiTerpilih] = useState("");
  const [errorSesiNull, setErrorSesiNull] = useState(false);
  const [dataCheckout, setDataCheckout] = useState<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(
    (state: any) => state?.authorization?.isLoggedIn
  );

  const dataJSON: any = {
    codeVoucher: "",
    codeRedeem: null,
    sesi: sesiTerpilih,
    gelombang: dataWebinar?.gelombang,
    tanggalMulai: dataWebinar?.tanggal_mulai,
    tanggalSelesai: dataWebinar?.tanggal_selesai,
    pembicara: dataWebinar?.pembicara,
    product_limit: dataWebinar?.product_limit,
  };
  useSetDataWatchCourse("dataCheckout", dataCheckout, true);

  useEffect(() => {
    if (showOption) {
      setErrorSesiNull(false);
    }
  }, [showOption]);

  const handlePilihSesi = (e: string) => {
    setSesiTerpilih(e);
    setShowOption(false);
  };

  const tblGabung =
    infoKelas.status === "TAKADA"
      ? "IKUTI PELATIHAN"
      : infoKelas.status === "MULAI"
      ? "MULAI PELATIHAN"
      : infoKelas.status === "SELESAI"
      ? "PELATIHAN SELESAI"
      : "LANJUTKAN";

  const handleKeCheckout = async (tipe: string) => {
    const idProduk = infoKelas?.idKelas;
    tambahKeKeranjang(idProduk, dispatch);
    // setDataCheckout(dataJSON);
    await setDataCheckout({ tipeUser: tipe, ...dataJSON });
    closePopup();
    navigate(`/checkout`);
  };

  const openPopupGabungKelas = () =>
    openPopup(
      <Popup
        closePopup={closePopup}
        handleKeCheckout={handleKeCheckout}
        dataDetail={dataDetail?.data[0]?.course}
      />
    );

  const handleBtnGabungKelas = () => {
    if (infoKelas?.idKelas) {
      if (userData === false) {
        navigate("/masuk");
      } else if (infoKelas.status !== "TAKADA") {
        // setErrorSesiNull(false);
        const idKelas = infoKelas.idKelas;
        const spl = infoKelas?.webinar == "2";
        infoKelas.status === "MULAI" &&
          navigate(`/${spl ? "course-spl" : "lihat-course"}/${idKelas}`);
        infoKelas.status === "LANJUTKAN" &&
          navigate(`/${spl ? "course-spl" : "lihat-course"}/${idKelas}`);
        infoKelas.status === "EVALUASI" &&
          navigate(`/${spl ? "course-spl" : "lihat-course"}/${idKelas}`);
        infoKelas.status === "SELESAI" &&
          navigate(`/${spl ? "course-spl" : "lihat-course"}/${idKelas}`);
      } else if (infoKelas.status === "TAKADA") {
        setErrorSesiNull(false);
        openPopupGabungKelas();
        // navigate(`/checkout`);
        // if (sesiTerpilih != "") {
        // } else {
        //   setErrorSesiNull(true);
        // }
      }
    }
  };

  const openPopupVideo = () =>
    openPopup(
      <PopupVideo
        deskripsiHTMLVideo={dapatkanHTMLVideo(infoKelas?.deskripsiHTML)}
      />
    );

  useEffect(() => {
    setHeightThumnail(refHeightThumnail?.current?.clientHeight);
  });

  const namaPembicara = new Set();

  return (
    <>
      {loading ? (
        <SekeletonGabungKelas scroll={scroll} lebarLayar={lebarLayar} />
      ) : (
        <>
          <div
            className="coursepage-gabung-kelas-wrapper ssssd"
            style={{ height: lebarLayar > 1000 ? heightBodyCoursePage : "" }}
          >
            <div
              className="card-gabung-kelas"
              style={
                scroll == "down"
                  ? { top: 64 }
                  : {
                      top:
                        lebarLayar > 1000
                          ? 64 + heightHero - heightThumnail
                          : "",
                    }
              }
            >
              <div
                className="kontainer-video-gabung-kelas-courses"
                id="wb-prakerja-play-video"
              >
                {loading ? (
                  <Skeleton
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                ) : (
                  <>
                    <img
                      className="thumbnail-gabung-kelas-course"
                      src={infoKelas?.gbrKelas}
                      ref={refHeightThumnail}
                    />
                    {dapatkanHTMLVideo(infoKelas?.deskripsiHTML) !==
                      "KOSONG" && (
                      <img
                        className="video-player-button"
                        id="wb-prakerja-play-video"
                        src={IconPlay}
                        onClick={openPopupVideo}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="body-gabung-kelas">
                {lebarLayar > 1000 && (
                  <TabelGabungKelas
                    dataWebinar={dataWebinar}
                    infoKelas={infoKelas}
                    sesiTerpilih={sesiTerpilih}
                    showOption={showOption}
                    setShowOption={setShowOption}
                    handlePilihSesi={handlePilihSesi}
                    tblGabung={tblGabung}
                    handleBtnGabungKelas={handleBtnGabungKelas}
                    // errorSesiNull={errorSesiNull}
                  />
                )}
                {errorSesiNull && (
                  <p className="info-error-sesi-null">*Wajib pilih sesi</p>
                )}
                <div className="section-information">
                  <h4 className="title">Informasi Webinar</h4>
                  <ul className="list-information d-flex">
                    <li className="item-information d-flex">
                      <img src={IconZoom} alt="" className="icon-information" />
                      <p className="text">{dataWebinar?.webinar_platform}</p>
                    </li>
                    <li className="item-information d-flex">
                      <img
                        src={IconJadwal}
                        alt=""
                        className="icon-information"
                      />
                      <p className="text">
                        {`${getFullDateNoYear(
                          dataWebinar?.tanggal_mulai
                        )} - ${getFullDate(dataWebinar?.tanggal_selesai)}`}
                      </p>
                    </li>
                    {dataWebinar?.pembicara?.map((item: any, indeks: any) => {
                      if (namaPembicara.has(item?.nama)) {
                        return null;
                      }
                      namaPembicara.add(item?.nama);
                      return (
                        <li className="item-information d-flex" key={indeks}>
                          <img
                            src={IconInstructor}
                            alt=""
                            className="icon-information"
                          />
                          <p className="text">
                            Pembicara {indeks + 1}: {item?.nama}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {(dataDetail?.data?.[0]?.course?.mp_links?.length || 0 > 0) && (
                  <div className="section-marketplace">
                    <h4 className="title">Pelatihan ini tersedia di</h4>
                    <ul className="list-btn-markertplace d-flex">
                      {dataDetail?.data?.[0]?.course?.mp_links?.map(
                        (item: any, indeks: any) => {
                          if (item?.href !== null) {
                            return (
                              <li
                                className="item-btn-marketplace d-flex"
                                key={indeks}
                              >
                                <img
                                  src={item?.mp_logo}
                                  alt=""
                                  className="logo-marketplace"
                                />
                                <button
                                  className="btn-cta-marketplace"
                                  onClick={() =>
                                    window.open(
                                      `${item?.mp_produk_link}`,
                                      "_blank"
                                    )
                                  }
                                />
                              </li>
                            );
                          }
                        }
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="coursepage-gabung-kelas-wrapper-mobile">
            {lebarLayar <= 1000 && (
              <TabelGabungKelas
                dataWebinar={dataWebinar}
                infoKelas={infoKelas}
                sesiTerpilih={sesiTerpilih}
                showOption={showOption}
                setShowOption={setShowOption}
                handlePilihSesi={handlePilihSesi}
                tblGabung={tblGabung}
                handleBtnGabungKelas={handleBtnGabungKelas}
                // errorSesiNull={errorSesiNull}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

interface IGabungKelas {
  dataWebinar: any;
  infoKelas: any;
  dataDetail: any;
  loading: boolean;
  lebarLayar: number;
  heightHero: number;
  heightBodyCoursePage: number;
}

interface IDataJSON {
  codeVoucher: any;
  sesi: any;
}

export default GabungKelas;
