import { FC } from "react"
import GeneratePart from "parts/GeneratePage/GeneratePart"

const GeneratePage: FC<IGeneratePage> = ({ }) => {

  return (
    <GeneratePart />
  )
}

interface IGeneratePage {

}

export default GeneratePage