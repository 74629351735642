import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";

/**dapatkan param dari url */
const useMatchParamsCategory = (kategoriKelas) => {
  const [kategoriTerpilih, setKategoriTerpilih] = useState(-1);
  const { idKategori } = useParams()
  useEffect(() => {
    for (let i = 0; i < kategoriKelas.length; i++) {
      if (kategoriKelas[i].slug === idKategori) {
        setKategoriTerpilih(i);
      }
    }
  }, [kategoriKelas, idKategori]);

  return kategoriTerpilih;
};

export default useMatchParamsCategory;
