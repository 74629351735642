import "./GabungKelas.scss";
import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import TabelGabungKelas from "./TabelGabungKelas";

const SkeletonGabungKelas: FC<ISkeletonGabungKelas> = ({
  scroll,
  lebarLayar,
}) => {
  return (
    <>
      <div className="coursepage-gabung-kelas-wrapper">
        <div
          className="card-gabung-kelas"
          style={scroll == "down" ? { top: 64 } : { top: 152 }}
        >
          <div
            className="kontainer-video-gabung-kelas-courses"
            id="wb-prakerja-play-video"
          >
            <Skeleton
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
            />
          </div>

          <div className="body-gabung-kelas">
            {lebarLayar > 1000 && (
              <div className="section-tbl-action">
                <div className="info-course d-flex">
                  <div className="form-dropdown-sesi">
                    <button
                      className="btn-cta-sesi"
                      style={{
                        padding: 0,
                        background: "transparent",
                        border: "none",
                      }}
                    >
                      <Skeleton height={35} width={"100%"} />
                    </button>
                  </div>
                </div>
                <button
                  className="btn-cta-course"
                  style={{ padding: 0, background: "transparent" }}
                >
                  <Skeleton height={46} width={"100%"} />
                </button>
              </div>
            )}

            <div className="section-information">
              <h4 className="title">
                <Skeleton height={"100%"} width={"50%"} />
              </h4>
              <ul className="list-information d-flex">
                <li className="item-information ">
                  <Skeleton
                    height={14}
                    width={14}
                    circle
                    style={{ marginRight: "10px" }}
                  />
                  <Skeleton height={"100%"} width={"80%"} />
                </li>
                <li className="item-information ">
                  <Skeleton
                    height={14}
                    width={14}
                    circle
                    style={{ marginRight: "10px" }}
                  />
                  <Skeleton height={"100%"} width={"80%"} />
                </li>
                <li className="item-information">
                  <Skeleton
                    height={14}
                    width={14}
                    circle
                    style={{ marginRight: "10px" }}
                  />
                  <Skeleton height={"100%"} width={"80%"} />
                </li>
                <li className="item-information">
                  <Skeleton
                    height={14}
                    width={14}
                    circle
                    style={{ marginRight: "10px" }}
                  />
                  <Skeleton height={"100%"} width={"80%"} />
                </li>
                <li className="item-information">
                  <Skeleton
                    height={14}
                    width={14}
                    circle
                    style={{ marginRight: "10px" }}
                  />
                  <Skeleton height={"100%"} width={"80%"} />
                </li>
              </ul>
            </div>
            <div className="section-marketplace">
              <h4 className="title">
                <Skeleton height={"100%"} width={"50%"} />
              </h4>
              <ul className="list-btn-markertplace d-flex">
                <li
                  className="item-btn-marketplace"
                  style={{ marginTop: "-10px", border: "none" }}
                >
                  <Skeleton height={"100%"} width={"100%"} />
                </li>
                <li
                  className="item-btn-marketplace"
                  style={{ marginTop: "-10px", border: "none" }}
                >
                  <Skeleton height={"100%"} width={"100%"} />
                </li>
                <li
                  className="item-btn-marketplace"
                  style={{ marginTop: "-10px", border: "none" }}
                >
                  <Skeleton height={"100%"} width={"100%"} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="coursepage-gabung-kelas-wrapper-mobile">
        {lebarLayar <= 1000 && (
          <div className="section-tbl-action">
            <div className="info-course d-flex">
              <h3 className="price-course">
                <Skeleton height={"100%"} width={90} />
              </h3>
              <div className="form-dropdown-sesi">
                <button
                  className="btn-cta-sesi"
                  style={{
                    padding: 0,
                    background: "transparent",
                    border: "none",
                  }}
                >
                  <Skeleton height={32} width={"100%"} />
                </button>
              </div>
            </div>
            <button
              className="btn-cta-course"
              style={{ padding: 0, background: "transparent" }}
            >
              <Skeleton height={42} width={"100%"} />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

interface ISkeletonGabungKelas {
  scroll: string;
  lebarLayar: number;
}

export default SkeletonGabungKelas;
