import "./Hero.scss";
import "../GabungKelas/GabungKelas.scss";
import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import SkeletonGabungKelas from "../GabungKelas/SkeletonGabungKelas";

const HeroSkeleton: FC<IHeroSkeleton> = ({ lebarLayar }) => {
  return (
    <div className="coursepage-hero-wrapper">
      <div className="isi-hero">
        <div className="breadcrumb-wrapper">
          <Skeleton height={"100%"} width={"70%"} />
        </div>
        <div className="badge-category">
          <Skeleton height={"100%"} width={100} />
        </div>
        <h2 className="title-course">
          <Skeleton height={"100%"} width={"100%"} />
        </h2>
        <p className="description">
          <Skeleton height={"100%"} width={"100%"} />
        </p>
        <div className="info-course d-flex align-items-center">
          <div className="info-rating item-info d-flex align-items-center">
            <Skeleton height={14} width={14} circle />
            {lebarLayar > 1000 ? (
              <Skeleton height={"100%"} width={100} />
            ) : (
              <Skeleton height={"100%"} width={50} />
            )}
          </div>
          <div className="info-user item-info d-flex align-items-center">
            <Skeleton height={14} width={14} circle />
            {lebarLayar > 1000 ? (
              <Skeleton height={"100%"} width={100} />
            ) : (
              <Skeleton height={"100%"} width={50} />
            )}
          </div>
          <div className="info-rating item-info d-flex align-items-center">
            <Skeleton height={14} width={14} circle />
            {lebarLayar > 1000 ? (
              <Skeleton height={"100%"} width={100} />
            ) : (
              <Skeleton height={"100%"} width={50} />
            )}
          </div>
        </div>
        {lebarLayar <= 1000 && (
          <SkeletonGabungKelas scroll={""} lebarLayar={lebarLayar} />
        )}
      </div>
    </div>
  );
};

interface IHeroSkeleton {
  lebarLayar: number;
}

export default HeroSkeleton;
