import { useEffect } from "react";


const useInitFacebook = () => {
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '330417854097358',
        cookie: true,
        xfbml: true,
        version: 'v6.0'
      });

      window.FB.getLoginStatus(function (response) {

        window.FB.Event.subscribe('auth.statusChange', function (response) {
          console.log("subscribe", response)
          if (response.authResponse) {
            window.FB?.api('/me', { fields: 'name, email' }, (respAPI) => {
              console.log(respAPI);
              console.log(respAPI.email);
            });
          }
        });
      });
    };

  }, [])
}


export default useInitFacebook