export const getFullDateWithDays = (timestamp) => {
  const days = [
    "Minggu",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jum'at",
    "Sabtu",
  ];
  if (timestamp != null && timestamp != undefined) {
    const unixEpochTimeMS = timestamp * 1000;
    const dayNum = new Date(unixEpochTimeMS).getDay();
    const dayName = days[dayNum];
    const dateWebinar = new Intl.DateTimeFormat("id-ID", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(unixEpochTimeMS);
    return `${dayName}, ${dateWebinar}`;
  }
};

export const getFullDate = (timestamp) => {
  if (timestamp != null && timestamp != undefined) {
    const unixEpochTimeMS = timestamp * 1000;
    const dateWebinar = new Intl.DateTimeFormat("id-ID", {
      day: "numeric",
      month: "long",
      year: "numeric",
      timeZone: "Asia/Jakarta",
    }).format(unixEpochTimeMS);
    return dateWebinar;
  }
};

export const getTime = (timestamp) => {
  if (timestamp != null && timestamp != undefined) {
    const unixEpochTimeMS = timestamp * 1000;
    const timeWebinar = new Intl.DateTimeFormat("id-ID", {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Asia/Jakarta",
    }).format(unixEpochTimeMS);
    return timeWebinar;
  }
};

export const getFullDateNoYear = (timestamp) => {
  if (timestamp != null && timestamp != undefined) {
    const unixEpochTimeMS = timestamp * 1000;
    const dateWebinar = new Intl.DateTimeFormat("id-ID", {
      day: "numeric",
      month: "short",
      timeZone: "Asia/Jakarta",
    }).format(unixEpochTimeMS);
    return dateWebinar;
  }
};

export const getFullDateNoYearShort = (timestamp) => {
  if (timestamp != null && timestamp != undefined) {
    const unixEpochTimeMS = timestamp * 1000;
    const dateWebinar = new Intl.DateTimeFormat("id-ID", {
      day: "numeric",
      month: "short",
    }).format(unixEpochTimeMS);
    return dateWebinar;
  }
};

export const getFullDateShort = (timestamp) => {
  if (timestamp != null && timestamp != undefined) {
    const unixEpochTimeMS = timestamp * 1000;
    const dateWebinar = new Intl.DateTimeFormat("id-ID", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(unixEpochTimeMS);
    return dateWebinar;
  }
};

export const getDayOnlyNumeric = (timestamp) => {
  if (timestamp != null && timestamp != undefined) {
    const unixEpochTimeMS = timestamp * 1000;
    const dateWebinar = new Intl.DateTimeFormat("id-ID", {
      day: "numeric",
    }).format(unixEpochTimeMS);
    return dateWebinar;
  }
};

export const getDateTime = (timestamp) => {
  if (timestamp != null && timestamp != undefined) {
    const unixEpochTimeMS = new Date(timestamp * 1000);
    const dateTime = unixEpochTimeMS
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    return dateTime;
  }
};

export const getDateTimeYTZ = (timestamp) => {
  if (timestamp != null && timestamp != undefined) {
    const date = new Date(timestamp * 1000);
    const formattedDate = date
      .toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-");

    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  }
};

export const formatDateV1 = (e) => {
  const date = new Date(e);
  const dateFormated = date.toLocaleString("id-ID", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  return dateFormated;
};

export const formatDateV2 = (e) => {
  const date = new Date(e);
  const dateFormated = date.toLocaleString("id-ID", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  return dateFormated;
};
