import useKlikLuar from "elements/useKlikLuar";
import { FC, MutableRefObject, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalData } from "store/actions/global";
import "./PopupGeneral.scss";

export const PopupGeneralV2: FC<IPopupGeneralV2> = () => {
  const isiPopup = useSelector(
    (state) => (state as any)?.global?.isiPopupArkademiV2 || null
  );
  const ref = useRef<HTMLDivElement>(null);
  if (isiPopup === null) return <></>;
  return (
    <div className="kontainer-popup-general-apl-arkademi">
      <div className="kontainer-isi-popup" ref={ref}>
        {isiPopup}
      </div>
    </div>
  );
};

/**
 * cara penggunaan:
 *
 * const {openPopup, closePopup} = usePopup()
 *
 * <KomponenTrigger onClick={() => openPopup( \<IsiPopup /> )}/>
 *
 */
const usePopup = () => {
  const dispatch = useDispatch();
  const openPopup = (isiPopup: JSX.Element) => {
    dispatch(setGlobalData("isiPopupArkademiV2", isiPopup));
    document.body.style.overflow = "hidden";
  };
  const closePopup = () => {
    dispatch(setGlobalData("isiPopupArkademiV2", null));
    document.body.style.overflow = "unset";
  };

  return { openPopup, closePopup };
};

interface IPopupGeneralV2 {}

export default usePopup;
