import { combineReducers } from "redux";
import page from "./page";
import authorization from "./auth";
import globalWatchCourse from "./globalWatchCourse";
import dashboard from "./dashboard";
import payment from "./payment";
import coupon from "./coupon";
import courseSpesial from "./courseSpesial";
import banner from "./banner";
import prakerja from "./prakerja";
import wishlist from "./wishlist";
import course from "./course";
import search from "./search";
import global from "./global";
import cache from "./cache";
import globalCache from "./globalCache";

export default combineReducers({
  page,
  authorization,
  globalWatchCourse,
  dashboard,
  payment,
  coupon,
  courseSpesial,
  banner,
  prakerja,
  wishlist,
  global,
  course,
  search,
  cache,
  globalCache
});
