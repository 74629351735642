import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "store/index";
import { setDataGlobalWatchCourse } from "store/actions/globalWatchCourse";
import { markAsComplete } from "store/actions/page";
import useGantiKelas from "../../fungsi/useGantiKelas";
import useToken from "utils/useToken";
import { useFetchTrigger, usePostUmumV2 } from "utils/useFetchUmum";
import { useGetDataWatchCourse } from "utils/useStoreWatchCourse";

const useMarkAsComplete = () => {
  const messageKurikulum = useSelector((state) =>
    state.globalWatchCourse
      ? state.globalWatchCourse["watchCourseKurikulum"]
      : null
  );
  const watchCourseTab = useSelector((state) =>
    state.globalWatchCourse ? state.globalWatchCourse["watchCourseTab"] : null
  );
  const courseId = useGetDataWatchCourse("watchCourseIdKelas") || null;
  const isPass = useGetDataWatchCourse("watchCourseIsPass");

  const [token] = useToken();
  const [handelGantiCourse] = useGantiKelas();
  const dispatch = useDispatch();
  const [postReminder] = usePostUmumV2(
    "apimember",
    `/api/v1/user/email/reminder`
  );
  const [setNextSesiTime] = usePostUmumV2(
    "apicourse",
    `/api/v1/prakerja/user/${courseId}/set-next-sesi-time`
  );

  const [getKurikulumNew] = useFetchTrigger(
    "apicourse",
    `/api/v1/prakerja/user/coursestatus-spl/${courseId}`
  );

  const handelMarkAsComplete = useCallback(
    /**
     * input id unit untuk didone secara lokal maupun di server
     * @param {"VIDEO" | "KUIS" | "INFO"} jenis
     * @param {number} idUnit
     * @param {boolean} autoNext
     */
    async (idKelas, idUnit, autoNext = true, jenis = "VIDEO") => {
      let indeksKurDapat = -1;
      let indeksIsiKelas = -1;
      let respons = null;
      const kurikulumDapat = messageKurikulum;
      await Promise.all(
        kurikulumDapat.map(async (nilai, indeksKur) => {
          await Promise.all(
            nilai.isiKelas.map(async (nilaiKelas, indeksKelas) => {
              if (nilaiKelas.idItem === idUnit) {
                indeksKurDapat = indeksKur;
                indeksIsiKelas = indeksKelas;
              }
              return indeksKelas;
            })
          );
          return indeksKur;
        })
      );
      if (
        indeksKurDapat >= 0 &&
        indeksIsiKelas >= 0 &&
        kurikulumDapat[indeksKurDapat] &&
        kurikulumDapat[indeksKurDapat].isiKelas &&
        kurikulumDapat[indeksKurDapat].isiKelas[indeksIsiKelas]
      ) {
        const sukses = await markAsComplete(idUnit, token, idKelas);
        respons = sukses;
        if (
          sukses &&
          !watchCourseTab?.selesai &&
          watchCourseTab?.lastUnitSesi &&
          isPass != "1"
        ) {
          // set reminder for next squence
          const bodyPostReminder = { course_id: idKelas, unit_id: idUnit };
          await postReminder(bodyPostReminder);

          // set time to open firts unit in next squence
          const respSetTime = await setNextSesiTime({
            sesi: watchCourseTab?.spl?.sesi,
          });
          if (respSetTime?.success) {
            const respKurikulumNew = await getKurikulumNew();
            const getTime = respKurikulumNew?.data?.courseitems?.find(
              (item) => item?.spl?.sesi === watchCourseTab?.spl?.sesi + 1
            )?.spl?.open_at;
            if (getTime != null) {
              const body = {
                sesi: watchCourseTab?.spl?.sesi,
                open_at: getTime,
              };
              dispatch(setDataGlobalWatchCourse("watchCourseTimeOpenAt", body));
            }
          }
        }
        if (sukses || jenis === "KUIS") {
          kurikulumDapat[indeksKurDapat].isiKelas[
            indeksIsiKelas
          ].selesai = true;
          const kurikulumBaru = [...kurikulumDapat];
          dispatch(
            setDataGlobalWatchCourse("watchCourseKurikulum", kurikulumBaru)
          );
        }
        if (sukses && autoNext) {
          handelGantiCourse("SETELAHNYA", true);
        }
        return respons;
      }
    },
    [messageKurikulum, token, handelGantiCourse]
  );

  return [handelMarkAsComplete];
};

export default useMarkAsComplete;
