import { useEffect, useState } from "react";
import "./Hover.scss";
/**
 *
 * @param {object} p
 * @param {JSX.Element} p.onHover
 * @param {number | undefined} p.topHover jarak top (biasanya setebal komponen yang dihover)
 * @param {number | undefined} p.kiriHover jarak relatif isi hover
 * @param {number | undefined} p.kananHover jarak relatif isi hover
 * @returns
 */
const Hover = ({
  onHover,
  topHover,
  children,
  kiriHover,
  kananHover,
  mobile,
  toggleMenuMobile = "close",
}) => {
  const [showNavbarResponsive, setshowNavbarResponsive] = useState(false);
  const handleToggleMenu = (e) => {
    if (mobile) {
      if (e == "open") {
        setshowNavbarResponsive(true);
      } else {
        setshowNavbarResponsive(false);
      }
    }
  };
  useEffect(() => {
    if (toggleMenuMobile === "close") {
      handleToggleMenu("close");
    } else {
      handleToggleMenu("open");
    }
  }, [toggleMenuMobile]);

  return (
    <div
      className="hover-app-arkademi-top-level"
      onClick={() => handleToggleMenu(toggleMenuMobile)}
    >
      <div className="children-komponen">{children}</div>
      <div
        className={`hover-komponen ${
          showNavbarResponsive && mobile ? "show" : ""
        }`}
        style={{
          top: topHover || 0,
          left: kiriHover == null ? "auto" : kiriHover,
          right: kananHover == null ? "auto" : kananHover,
        }}
      >
        {onHover}
      </div>
    </div>
  );
};

export default Hover;
