import { useState, useEffect, MutableRefObject, useRef } from "react"



/**
 * cek apakah suatu ref dalam layar
 * @param {MutableRefObject<HTMLElement> | undefined | null} ref 
 * @returns {boolean}
 */
export default function useCekDalamLayar(ref) {

  const [adaInterseksi, setAdaInterrseksi] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setAdaInterrseksi(entry.isIntersecting)
  )

  useEffect(() => {
    if (ref !== undefined && ref?.current != null) {
      observer.observe(ref.current)
      return () => { observer.disconnect() }
    }
  }, [ref])

  return adaInterseksi
}

/**
 * cek apakah suatu ref dalam layar tanpa perlu redeklarasi ref
 * @returns {[(MutableRefObject<HTMLElement> | undefined | null), boolean]}
 */
export function useCekDalamLayarLangsung() {

  const ref = useRef()
  const [adaInterseksi, setAdaInterrseksi] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setAdaInterrseksi(entry.isIntersecting)
  )

  useEffect(() => {
    if (ref !== undefined && ref?.current != null) {
      observer.observe(ref.current)
      return () => { observer.disconnect() }
    }
  }, [ref])

  return [ref, adaInterseksi]
}

/**
 * cek apakah suatu ref PERNAH ada dalam layar
 * @returns {[(MutableRefObject<HTMLElement> | undefined | null), boolean]}
 */
export function useCekPernahDalamLayarLangsung() {

  const ref = useRef()
  const [adaInterseksi, setAdaInterrseksi] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => {
      entry.isIntersecting && setAdaInterrseksi(entry.isIntersecting)
    }
  )

  useEffect(() => {
    if (ref !== undefined && ref?.current != null) {
      observer.observe(ref.current)
      return () => { observer.disconnect() }
    }
  }, [ref])

  return [ref, adaInterseksi]
}