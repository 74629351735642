import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAvatar } from "store/actions/dashboard";

export default function useFetchAvatar() {
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState([]);
  const [loading, setLoading] = useState(true);
  // console.log(avatar);

  const getAvatar = useSelector((state) => state.dashboard?.avatar || null);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await fetchAvatar("avatar", dispatch);
      setLoading(false);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (getAvatar != null) {
      setAvatar(getAvatar.data?.url);
    }
  }, [getAvatar]);

  return [avatar, loading];
}
