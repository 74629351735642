import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Button from "elements/Button";
import Flickity from "react-flickity-component";
import "elements/Slider/index.scss";
import NumberFormat from "react-number-format";
import { IconUser, IconStar, IconAward, ImageDefault } from "assets";
import CardSkeleton from "elements/Skeleton/CardFeature";

export default function FeatureBestSeller({ data }) {
  const navigate = useNavigate();

  return (
    <section>
      <div className="container">
        <div className="row title-feature">
          <h2 className="mb-ml-4 pl-3 font-weight-bold">
            <img src={IconAward} alt="icon sale" className="mr-2" /> Best Seller
          </h2>
        </div>
        <Flickity
          className="carousel"
          options={{
            groupCells: true,
            cellAlign: "right",
            wrapAround: false,
            pageDots: false,
            freeScroll: true,
            contain: true,
          }}
        >
          {data == null ? (
            <CardSkeleton />
          ) : (
            data.map((item, index) => {
              const price = item.regular_price - item.sale_price;
              return (
                <div key={`terbaru-${index}`} className="mt-4">
                  <div className="card card-featured">
                    <figure className="img-wrapper">
                      <img
                        className="img-fluid"
                        src={
                          item.image == item.image ? item.image : ImageDefault
                        }
                        alt={item.title}
                      />
                    </figure>
                    <div className="meta-wrapper">
                      <h4>{item.title}</h4>
                      <div className="meta-wrapper-user d-flex align-item-center">
                        <span>
                          <img
                            src={IconStar}
                            alt="icon image"
                            className="icon"
                          />{" "}
                          {item.average_rating}
                        </span>
                        <span>
                          <img
                            src={IconUser}
                            alt="icon image"
                            className="icon"
                          />{" "}
                          {item.students_count}
                        </span>
                      </div>
                      <div className="vendor d-flex align-item-center">
                        <img
                          src={item.instructure.avatar}
                          alt="icon image"
                          className="icon-vendor"
                        />{" "}
                        {item.instructure.name}
                      </div>
                      <div className="salary">
                        <div className="salary-class">
                          <p className="salary-trough">
                            <NumberFormat
                              value={item.regular_price}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp."}
                            />
                          </p>
                          <p className="salary-cut">
                            Hemat{" "}
                            <NumberFormat
                              value={item.sale_price}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp."}
                            />
                          </p>
                        </div>
                        <p className="salary-fix">
                          <NumberFormat
                            value={price}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp."}
                          />
                        </p>
                      </div>
                      <div className="row justify-content-center">
                        <Link to={'/course/' + item.ID}>
                          <Button
                            className="btn btn-sm px-4"
                            isSecondary
                            onClick={() =>
                              navigate(`/course-page/${item._id}`)
                            }
                          >
                            Ambil Kelas
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </Flickity>
      </div>
    </section>
  );
}
