import "./CoursePageWebinar.scss";
import { FC, useEffect, useRef, useState } from "react";
import Hero from "./Hero";
import { useFetchUmumDummy } from "utils/useFetchUmum";
import Submenu from "./Submenu";
import Informasi from "./Informasi";
import FasilitatorInstructor from "./FasilitatorInstructor";
import Fitur from "./Fitur";
import Certificate from "./Certificate";
import Review from "./Review";
import GabungKelas from "./GabungKelas";
import Sesi from "./Sesi";
import useDimensiLayar from "utils/useDimensiLayar";
import { useSelector } from "react-redux";
import useFetchCourseMicroservicesDalamLayarV2 from "utils/useFetchCourseMicroservicesDalamLayarV2";
import useScrapeCurriculum from "utils/useScrapeCurriculum";
import useScrapeInstruktur from "../CoursePageReguler/bagian/Instruktur/useScrapeInstruktur";
import useScrapeRating from "../CoursePageReguler/bagian/Fungsi/useScrapeRating";
import useScrapeReview from "../CoursePageReguler/bagian/Fungsi/useFetchReview";
import useScrapeInfoKelas from "../CoursePageReguler/bagian/Fungsi/useScrapeInfoKelas";
import Curriculum from "./Curriculum";

const CoursePageWebinar: FC<ICoursePageWebinar> = ({
  slugCourse,
  dataDetail,
  loadingDetail,
}) => {
  const [lebarLayar, tinggiLayar] = useDimensiLayar();
  const refCoursePage = useRef<any>(null);
  const refPilihSesi = useRef(null);
  const refSummaryReview = useRef(null);
  const refIkhtisar = useRef(null);
  const refKurikulum = useRef(null);
  const refFasilitator = useRef(null);
  const refReviewKomentar = useRef(null);
  const refInformasi = useRef(null);
  const refInstruktur = useRef(null);
  const refHeightHero = useRef(null);
  // const [idCourseFS, setIdCourseFS] = useState(null);
  const [heightHero, setHeightHero] = useState(0);
  const [heightBodyCoursePage, setHeightBodyCoursePage] = useState<number>(0);

  const reviewPage = useSelector(
    (state: any) => state?.course?.reviewPage || 1
  );
  // const flashSaleId = useSelector(
  //   (state) => state?.global?.idCourseFlahSale || null
  // );
  const [dataWebinars, loadingDataWebinars, errorDataWebinars] =
    useFetchCourseMicroservicesDalamLayarV2(slugCourse, "info-webinar");
  const [userStatusJSON, loadingUserStatusJSON, errorUserStatus] =
    useFetchCourseMicroservicesDalamLayarV2(slugCourse, "user_status");
  const infoKelasNew = useScrapeInfoKelas(dataDetail, userStatusJSON);

  const [curriculumJSON, loadingCurriculumJSON, errorCurriculumJSON] =
    useFetchCourseMicroservicesDalamLayarV2(
      infoKelasNew.idKelas,
      "curriculums"
    );
  const [instrukturJSON, loadingInstrukturJSON, errorInstrukturJSON] =
    useFetchCourseMicroservicesDalamLayarV2(
      infoKelasNew.idKelas,
      "instructures"
    );
  const [courseRatingJSON, loadingCourseRatingJSON, erorCourseRating] =
    useFetchCourseMicroservicesDalamLayarV2(
      infoKelasNew.idKelas,
      "ratings"
      // refSummaryReview
    );
  const [komentarJSON, loadingKomentarJSON, erorKomentar] =
    useFetchCourseMicroservicesDalamLayarV2(
      infoKelasNew.idKelas,
      `reviews?page=${reviewPage || 1}`
      // refReviewKomentar
    );

  const kurikulumDL = useScrapeCurriculum(curriculumJSON);
  const instrukturDL = useScrapeInstruktur(instrukturJSON);
  const summaryReviewDL = useScrapeRating(courseRatingJSON);
  const reviewKomentarDL = useScrapeReview(komentarJSON);

  // useEffect(() => {
  //   if (flashSaleId != null) {
  //     setIdCourseFS(flashSaleId);
  //   }
  // }, [flashSaleId]);

  useEffect(() => {
    setHeightBodyCoursePage(refCoursePage?.current?.clientHeight);
  });

  // const [scrollPosition, setScrollPosition] = useState(0);
  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setScrollPosition((position / heightBodyCoursePage) * 100);
  // };
  // useEffect(() => {
  //   if (heightBodyCoursePage > 0) {
  //     window.addEventListener("scroll", handleScroll, { passive: true });
  //     return () => {
  //       window.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, [heightBodyCoursePage]);

  return (
    <div className="coursepage-webinar-wrapper" ref={refCoursePage}>
      <Hero
        infoKelas={infoKelasNew}
        dataDetail={dataDetail}
        dataWebinar={dataWebinars?.data}
        loading={loadingDetail}
        lebarLayar={lebarLayar}
        refHeightHero={refHeightHero}
        heightHero={heightHero}
        setHeightHero={setHeightHero}
        heightBodyCoursePage={heightBodyCoursePage}
        // loading={loadingWebinar}
      />
      {lebarLayar > 1000 && (
        <GabungKelas
          dataWebinar={dataWebinars?.data}
          infoKelas={infoKelasNew}
          dataDetail={dataDetail}
          loading={loadingDetail}
          lebarLayar={lebarLayar}
          heightHero={heightHero}
          heightBodyCoursePage={heightBodyCoursePage}
        />
      )}
      <Submenu
        refPilihSesi={refPilihSesi}
        refInformasi={refInformasi}
        refKurikulum={refKurikulum}
        refReview={refSummaryReview}
      />
      <div className="coursepage-body-wrapper">
        <Sesi
          dataWebinar={dataWebinars?.data}
          loading={loadingDataWebinars}
          refPilihSesi={refPilihSesi}
          error={false}
          sesiPenuh={false}
        />
        <Informasi
          dataWebinar={dataWebinars?.data}
          infoKelas={infoKelasNew}
          dataDetail={dataDetail}
          loading={loadingDetail}
          refInformasi={refInformasi}
        />
        <Curriculum
          kurikulum={kurikulumDL}
          loading={loadingCurriculumJSON}
          refKurikulum={refKurikulum}
          infoKelas={infoKelasNew}
        />
        <FasilitatorInstructor
          dataWebinar={dataWebinars?.data}
          loading={loadingDataWebinars}
        />
        <Fitur />
        <Certificate infoKelas={infoKelasNew} />
        <Review
          summaryReview={summaryReviewDL}
          refReview={refSummaryReview}
          ReviewKomentar={reviewKomentarDL}
          loadingKomentar={loadingCourseRatingJSON}
          refReviewKomentar={refReviewKomentar}
        />
      </div>
    </div>
  );
};

interface ICoursePageWebinar {
  slugCourse: number;
  dataDetail: any;
  loadingDetail: boolean;
}

export default CoursePageWebinar;
