import React, { Component } from "react";

export default class FeatureVideo extends Component {
  render() {
    return (
      <>
        {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2E3SrDqYWF9J_TDWNzjZkwJOAGxkLJVZPvUly7SqUL9WqLqEw" /> */}
        <div
          className="embed-responsive  
                embed-responsive-21by9"
        >
          <iframe
            className="embed-responsive-item"
            src="https://www.youtube-nocookie.com/embed/HRpDHVhNHKY"
            // allowfullscreen
          ></iframe>
        </div>
      </>
    );
  }
}
