import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import store from "store";
import { setDataGlobalWatchCourse } from "store/actions/globalWatchCourse";
import useReset from "./useReset";
import { useGetDataWatchCourse } from "utils/useStoreWatchCourse";

/**
 * untuk mengganti kelas
 */
const useGantiKelas = () => {
  const globalWatchCourseTab = useSelector((state) =>
    state.globalWatchCourse ? state.globalWatchCourse.watchCourseTab : null
  );
  const resetAll = useReset();
  const [searchParams, setSearchParams] = useSearchParams();
  const idItem = searchParams.get("loc");
  const dataWatchCourseQuiz = useGetDataWatchCourse("watchCourseQuiz");

  const handelGantiKelas = useCallback(
    /**
     *
     * @param {"SEBELUMNYA" | "SETELAHNYA"} jenis
     * @param {boolean} paksa
     */
    (jenis, paksa = false) => {
      resetAll();
      if (jenis === "SEBELUMNYA" && globalWatchCourseTab) {
        // console.log("klik sebelumnya", globalWatchCourseTab.idSebelumnya);
        store.dispatch(
          setDataGlobalWatchCourse(
            "watchCourseItemTerpilih",
            globalWatchCourseTab.idSebelumnya
          )
        );
        store.dispatch(
          setDataGlobalWatchCourse("watchCourseQuiz", {
            ...dataWatchCourseQuiz,
            nilaiKuis: -1,
            nilaiMinimal: 0,
          })
        );
      } else if (
        (globalWatchCourseTab && globalWatchCourseTab.selesai) ||
        paksa
      ) {
        // console.log("klik selanjutnya", globalWatchCourseTab.idSelanjutnya);
        store.dispatch(
          setDataGlobalWatchCourse(
            "watchCourseItemTerpilih",
            globalWatchCourseTab.idSelanjutnya
          )
        );
        store.dispatch(
          setDataGlobalWatchCourse("watchCourseQuiz", {
            ...dataWatchCourseQuiz,
            nilaiKuis: -1,
            nilaiMinimal: 0,
          })
        );
      }
    },
    [globalWatchCourseTab]
  );

  return [handelGantiKelas];
};

export default useGantiKelas;
