import React from "react";

import bukalapak from "./bukalapak.webp";
import pijarMahir from "./pijar-mahir.webp";
import pintaria from "./pintaria.png";
import sekolahmu from "./sekolahmu.webp";
import tokopedia from "./tokopedia.webp";

import fb from "./social-media/facebook.png";
import ig from "./social-media/instagram.png";
import li from "./social-media/linkedin.png";
import yt from "./social-media/youtube.png";

import play from "./icon-play.webp";
import app from "./icon-app.webp";

import "./footers.scss";

export default function Footer() {
  return (
    <>
      <section>
        <div className="footer-second">
          <div className="container-custom">
            <div className="second-body">
              <div className="second-body-service">
                <p>LAYANAN PELANGGAN</p>
                <a href="https://arkademi.com/pages/kategori/prakerja/">
                  Panduan Prakerja
                </a>
                <a href="/panduan?tab=faq">FAQ</a>
                <a href="https://arkademi.com/pages/ketentuan-layanan">
                  Ketentuan Layanan
                </a>
                <a href="https://arkademi.com/pages/kontak">Hubungi Kami</a>
              </div>
              <div className="second-body-info">
                <p>TENTANG KAMI</p>
                <a href="/tentang">Tentang Arkademi</a>
                <a href="/panduan?tab=informasi-prakerja">
                  Tentang Prakerja Arkademi
                </a>
                <a href="https://arkademi.com/pages/privacy-policy">
                  Kebijakan Privasi
                </a>
              </div>
              <div className="second-body-payment">
                <p>MITRA KAMI</p>
                <span>
                  <img
                    src={bukalapak}
                    alt="image"
                    className="icon-img-footer-payment-mid"
                    loading="lazy"
                  />
                  <img
                    src={sekolahmu}
                    alt="image"
                    className="icon-img-footer-payment-mandiri"
                    loading="lazy"
                  />
                  <img
                    src={tokopedia}
                    alt="image"
                    className="icon-img-footer-payment-bca"
                    loading="lazy"
                  />
                  <img
                    src={pintaria}
                    alt="image"
                    className="icon-img-footer-payment-gopay"
                    loading="lazy"
                  />
                  <img
                    src={pijarMahir}
                    alt="image"
                    className="icon-img-footer-payment"
                    loading="lazy"
                  />
                </span>
              </div>
              <div className="second-body-follow">
                {/* <h4>IKUTI KAMI</h4>
                <span>
                  <a
                    href="https://www.facebook.com/arkademiedu/"
                    target="_blank"
                  >
                    <img
                      src={fb}
                      alt="image"
                      className="icon-img-footer-media"
                      loading="lazy"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCU-bN5RVcBy2dUJ1ApDr9Sg"
                    target="_blank"
                  >
                    <img
                      src={yt}
                      alt="image"
                      className="icon-img-footer-media"
                      loading="lazy"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/arkademiedu/"
                    target="_blank"
                  >
                    <img
                      src={ig}
                      alt="image"
                      className="icon-img-footer-media"
                      loading="lazy"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/arkademi"
                    target="_blank"
                  >
                    <img
                      src={li}
                      alt="image"
                      className="icon-img-footer-media"
                      loading="lazy"
                    />
                  </a>
                  <a href="" target="_blank">
                    <img
                      src={google}
                      alt="image"
                      className="icon-img-footer-media"
                    />
                  </a>
                </span> */}
                <p>ARKADEMI MOBILE APP</p>
                <span>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.arkademi.app"
                    target="_blank"
                  >
                    <img
                      src={play}
                      alt="image"
                      className="icon-img-footer"
                      loading="lazy"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/id/app/arkademi/id1612381892?l=id"
                    target="_blank"
                  >
                    <img
                      src={app}
                      alt="image"
                      className="icon-img-footer"
                      loading="lazy"
                    />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-custom">
          <hr />
          <div className="footer-last">
            <p className="company-footer">© 2022 PT Arkademi Daya Indonesia</p>
            <div className="footer-last-left">
              <a href="https://arkademi.com/pages/ketentuan-layanan/">
                Ketentuan Layanan
              </a>
              <a href="https://arkademi.com/pages/kontak">Kontak</a>
            </div>
          </div>
          {/* <p className="alamat-footer">
            LEEON, Rukan De’ Kahfi, Jalan M. Kahfi II No. 23 O Kel. Cipedak Kec.
            Jagakarsa Kota Adm. Jakarta Selatan Prov. DKI Jakarta
          </p> */}
        </div>
      </section>
    </>
  );
}
