import "../alert.scss";
import { useNavigate } from "react-router";
import { IconAlertCoins } from "assets";
import { MdClose } from "react-icons/md";
import { COINS_REGISTER } from "store/types";
import store from "store";
import { useSelector } from "react-redux";

export default function AlertCoinRegister({ isOpen }) {
  const navigate = useNavigate();
  const coin = useSelector(
    (state) => state.authorization?.arkacoins?.data?.value
  );
  return (
    <>
      <section className="kontainer-alert-card-coins">
        <div className="alert-card-coins d-flex flex-column justify-content-center bg-white">
          <button
            onClick={() => {
              store.dispatch({
                type: COINS_REGISTER,
                payload: {
                  data: null,
                },
              });
              isOpen(false);
            }}
            className="btn-close-alert"
          >
            <MdClose className="icon-close" />
          </button>
          <div className="icon-card">
            <img src={IconAlertCoins} alt="" />
          </div>
          <h5 className="title-card">+{coin} Arkademi Koin</h5>
          <p className="desc-card">
            Sebagai pendaftar akun baru Arkademi.
            <br />
            Cek profil Anda untuk melihat jumlah koin yang Anda miliki.
          </p>
          <button
            className="button-card"
            onClick={() => {
              store.dispatch({
                type: COINS_REGISTER,
                payload: {
                  data: null,
                },
              });
              navigate("/dashboard/profile");
            }}
          >
            Lihat Profile
          </button>
        </div>
      </section>
    </>
  );
}
