import { CLEAR_GLOBAL_CACHE_DATA, SET_GLOBAL_DATA } from "store/types";
import axios from "axios";

//taruh data di sini untuk data sederhana yang digunakan secara global
export const clearGlobalData = () => ({
  type: CLEAR_GLOBAL_CACHE_DATA,
});

/**
 *
 * @param {string} dataName
 * @param {any} data
 * @param {number} expired
 * @returns
 */
// export const setGlobalCacheData = (dataName, data, expired) => {
//     localStorage.setItem(`GlobalCacheArkademi_${dataName}`, JSON.stringify(data));
//     return ({
//         type: SET_GLOBAL_DATA,
//         payload: {
//             [dataName]: { cache: data, expired: expired, token: token }
//         }
//     })
// }

/**
 * @return {Promise<import("type/Fetch").IResponseFetch>}
 */
export const fetchUmum = async (
  apiTerpilih,
  link,
  denganToken,
  token,
  reqCancelToken
) => {
  try {
    const response = await axios.get(`${apiTerpilih}${link}`, {
      headers:
        denganToken && token
          ? {
              Authorization: token,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            }
          : {},
      cancelToken: reqCancelToken.token,
    });
    return {
      success: true,
      message: null,
      data: response.data,
      responseCode: response.status,
    };
  } catch (e) {
    return {
      success: false,
      message: JSON.stringify(e?.response?.data),
      data: e?.response?.data || null,
      responseCode: e?.response?.status || 400,
    };
  }
};

/**
 * @return {Promise<import("type/Fetch").IResponsePost>}
 */
export const postUmum = async (
  apiTerpilih,
  postedData,
  link,
  denganToken,
  token,
  reqCancelToken
) => {
  try {
    const response = await axios.post(`${apiTerpilih}${link}`, postedData, {
      headers:
        denganToken && token
          ? {
              Authorization: token,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            }
          : {},
      cancelToken: reqCancelToken.token,
    });
    return {
      success: true,
      message: null,
      data: response.data,
      postedData: postedData,
      responseCode: response.status,
    };
  } catch (e) {
    return {
      success: false,
      message: JSON.stringify(e),
      data: e?.response?.data || null,
      postedData: postedData,
      responseCode: e?.response?.status || 400,
    };
  }
};

function delay(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

/**
 * @param {any} response
 * @return {any}
 */
export const fetchDummy = async (
  apiTerpilih,
  response,
  denganToken,
  token,
  reqCancelToken
) => {
  try {
    await delay(1000);
    if (response.responseCode >= 400 || response.success === false) {
      throw {
        response: response,
      };
    }
    return response;
  } catch (e) {
    return {
      success: false,
      message: JSON.stringify(e?.response?.data),
      data: e?.response?.data || null,
      responseCode: e?.response?.status || 400,
    };
  }
};
