import axios from "axios";
import store from "../index";
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_MESSAGE,
  COINS_REGISTER,
} from "store/types";
const api_member = process.env.REACT_APP_API_BACKEND_MEMBER;
const api_prod = process.env.REACT_APP_API_BACKEND_PRODUCTION;
const api_firebase = process.env.REACT_APP_API_FIREBASE;

export const loginUser = async (username = "", password = "") => {
  try {
    const response = await axios.post(api_member + "/api/v1/prakerja/auth", {
      username: username,
      password: password,
    });
    // console.log(response);
    if (response?.data?.status) {
      localStorage.setItem("user", JSON.stringify(response.data));
      store.dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data,
      });
      return { success: true, data: response?.data };
    } else if (
      response?.data?.success === false ||
      response?.data?.success == null
    ) {
      store.dispatch({
        type: LOGIN_FAIL,
      });
      return { success: false, data: response?.data };
    } else {
      return { success: false, data: null };
    }
  } catch (err) {
    return { success: false, data: err?.response?.data };
  }
};

export const loginFB = async (fbToken) => {
  try {
    const response = await axios.get(
      api_member + "/api/v1/prakerja/auth/facebook",
      {
        headers: {
          Authorization: fbToken,
        },
      }
    );
    if (response?.data?.status) {
      localStorage.setItem("user", JSON.stringify(response.data));
      store.dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data,
      });
      return { success: true, data: response?.data };
    } else if (
      response?.data?.success === false ||
      response?.data?.success == null
    ) {
      store.dispatch({
        type: LOGIN_FAIL,
      });
      return { success: false, data: response?.data };
    } else {
      return { success: false, data: null };
    }
  } catch (err) {
    // console.error("eror login facebook: ", err);
    return { success: false, data: err?.response?.data };
  }
};

export const loginGoogle = async (googleToken) => {
  try {
    const response = await axios.get(
      api_member + "/api/v1/prakerja/auth/google",
      {
        headers: {
          Authorization: googleToken,
        },
      }
    );
    if (response?.data?.status) {
      localStorage.setItem("user", JSON.stringify(response.data));
      store.dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data,
      });
      return { success: true, data: response?.data };
    } else if (
      response?.data?.success === false ||
      response?.data?.success == null
    ) {
      store.dispatch({
        type: LOGIN_FAIL,
      });
      return { success: false, data: response?.data };
    } else {
      return { success: false, data: null };
    }
  } catch (err) {
    // console.error("eror login google: ", err);
    return { success: false, data: err?.response?.data };
  }
};

export const arkaCoins = async (user_id) => {
  const coins = await axios.post(
    api_firebase + "/v2/registration-arkademi-coins",
    { user_id: user_id }
  );
  store.dispatch({
    type: COINS_REGISTER,
    payload: {
      data: coins.data,
    },
  });
  return null;
};

/**
 * @returns {Promise<string | null>}
 */
export const daftarUser = async (
  namaPengguna = "",
  email = "",
  password = "",
  pekerjaan = "",
  noHP = -1,
  client_id = ""
) => {
  try {
    if (namaPengguna === "") return "nama pengguna kosong";
    if (adaSpecialChar(namaPengguna) || adaNumber(namaPengguna))
      return "Nama tidak boleh mengandung selain huruf";
    if (email === "") return "e-mail kosong";
    if (password.length < 6) return "password harus lebih dari 6 huruf";
    if (pekerjaan === "" || pekerjaan === "---") return "pekerjaan koosng";
    if (noHP < 0) return "no hp kosong";
    const resp = await axios.post(api_member + "/api/v1/prakerja/register", {
      username: namaPengguna,
      password: password,
      email: email,
      client_id: "WF9PnyccBmbrEW3TKt4drFB",
      state: ")6cYyNr0",
      device: "",
      platform: "React-web",
      model: "v2",
    });
    if (resp.data?.status) {
      // const user_id = resp.data?.tokenPrakerja?.user_id;
      const token = resp.data?.tokenPrakerja?.access_token;
      const respEdit = await gantiFields(token, namaPengguna, noHP, pekerjaan);
      localStorage.setItem("user", JSON.stringify(resp.data));
      store.dispatch({
        type: REGISTER_SUCCESS,
        payload: {
          data: resp.data,
          fullname: namaPengguna,
        },
      });
      // const coins = await arkaCoins(user_id);
      return null;
    } else {
      store.dispatch({
        type: REGISTER_FAIL,
        payload: {
          message: resp.data.message,
        },
      });
      return resp.data.message;
    }
  } catch (e) {
    store.dispatch({
      type: REGISTER_FAIL,
      payload: {
        message: e.response?.data?.message,
      },
    });
    return e.response?.data?.message || "error tidak diketahui";
  }
};

const gantiFields = async (token, nama, noHP, pekerjaan) => {
  try {
    const resp = await axios.put(
      `${api_member}/api/v1/prakerja/auth/update/profile`,
      {
        nama_lengkap: nama,
        profesi: pekerjaan,
        no_hp: noHP,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { success: true, data: resp, error: null };
  } catch (e) {
    return {
      success: false,
      data: null,
      error:
        e.response?.data?.message || "Eror tidak diketahui untuk mengubah nama",
    };
  }
};

export const adaSpecialChar = (nama) => {
  const regexSpecial = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  return regexSpecial.test(nama.replace(/\s/g, ""));
};

function adaNumber(myString) {
  return /\d/.test(myString);
}

export const getRoleUser = async (token) => {
  try {
    const response = await axios.get(
      api_member + "/api/v1/prakerja/auth/check-user-live-access",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response?.data?.success) {
      return response?.data?.success;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const autoLogin = async (accessToken) => {
  try {
    const response = await axios.post(
      api_member + `/api/v1/prakerja/auto_login`,
      {
        access_token: accessToken,
      }
    );
    if (response?.data?.status) {
      localStorage.setItem("user", JSON.stringify(response.data));
      store.dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data,
      });
      return { success: true, data: response?.data };
    } else if (
      response?.data?.success === false ||
      response?.data?.success == null
    ) {
      store.dispatch({
        type: LOGIN_FAIL,
      });
      return { success: false, data: response?.data };
    } else {
      return { success: false, data: null };
    }
  } catch (err) {
    return { success: false, data: err?.response?.data };
  }
};
