export class Pembayaran {
  customer_id = 0;
  status = "on-hold";
  billing = new Billing();
  payment_method = "bacs";
  payment_method_title = "Transfer ke Mandiri (web)";
  line_items = [];
  fee_lines = [];
  coupon_lines = [];
  schedule_start_end = "";
}

export class Billing {
  first_name = "";
  email = "";
  phone = "";
}

export class LineItems {
  product_id = 0;
  quantity = 1;
}

export class LineFee {
  name = "Diskon";
  tax_status = "taxable";
  amount = 0;
  total = 0;
}

export class LineFeeFlashSale {
  name = "Flash Sale";
  tax_status = "taxable";
  amount = 0;
  total = 0;
}

export class LineFeeKoin {
  name = "Coins";
  tax_status = "taxable";
  amount = 0;
  total = 0;
}

export class LineKupon {
  code = "kupondilo100persen";
  discount = "139300";
}

const skeletonPayment = {};
