import "./Hero.scss";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IconArrowRight from "./icon-arrow-right.svg";
import IconUser from "./icon-user.svg";
import IconBintang from "./icon-bintang.svg";
import GabungKelas from "../GabungKelas";
import Skeleton from "react-loading-skeleton";
import LogoAvatar from "./logo-avatar-user.png";
import SkeletonHero from "./SkeletonHero";
import Bintang from "elements/Bintang";

const Hero: FC<IHero> = ({
  infoKelas,
  dataDetail,
  dataWebinar,
  loading,
  lebarLayar,
  refHeightHero,
  heightHero,
  setHeightHero,
  heightBodyCoursePage,
}) => {
  const fasilitatorJSON = dataWebinar?.fasilitator[0];
  const [peta, setPeta] = useState({
    diubah: false,
    isi: [
      { teks: "Home", href: "/" },
      { teks: "Webinar", href: "/kategori-course/webinar" },
    ],
  });

  const [dataCourse, setDataCourse] = useState<any>({
    kategori: [],
    judulCourse: "",
    nilaiKelas: 2.5,
    jmlOrang: 0,
  });

  useEffect(() => {
    //breadcrumb dari lokasi
    if (
      dataDetail &&
      dataDetail["data"] &&
      dataDetail["data"][0] &&
      dataDetail["data"][0]["course"]
    ) {
      const dataDapat = dataDetail["data"][0];
      const dataCourseDapat = dataDapat["course"];
      const kategoriCourse = dataCourseDapat["categories"];
      if (!peta?.diubah) {
        peta.isi.push({
          teks: dataCourseDapat?.name,
          href: `/course/${dataCourseDapat?.slug}`,
        });
        peta.diubah = true;
        setPeta(peta);
      }
      if (dataCourseDapat) {
        for (let i = 0; i < kategoriCourse?.length; i++) {
          dataCourse.kategori.push(kategoriCourse[i]["name"]);
        }

        // dataCourse.kategori = kategoriCourse;
        dataCourse.judulCourse = dataCourseDapat["name"];
        dataCourse.nilaiKelas = dataCourseDapat["average_rating"];
        dataCourse.jmlOrang = dataCourseDapat["total_students"];

        setDataCourse(dataCourse);
      }
    }
  }, [dataDetail]);

  useEffect(() => {
    setHeightHero(refHeightHero?.current?.clientHeight);
  });

  return (
    <>
      {loading ? (
        <SkeletonHero lebarLayar={lebarLayar} />
      ) : (
        <div className="coursepage-hero-wrapper" ref={refHeightHero}>
          <div className="isi-hero">
            <div className="breadcrumb-wrapper d-flex">
              {loading ? (
                <Skeleton height={"100%"} width={"80%"} />
              ) : (
                peta.isi.map((nilai: any, indeks: any) => {
                  return (
                    <Link style={{ textDecoration: "none" }} to={nilai.href}>
                      <div className="peta-satuan d-flex">
                        <div className="nilai">{nilai?.teks}</div>
                        {indeks < peta.isi.length - 1 && (
                          <img
                            className="chevron-kanan"
                            src={IconArrowRight}
                            alt=""
                            loading="lazy"
                          />
                        )}
                      </div>
                    </Link>
                  );
                })
              )}
            </div>
            <div className="badge-category d-flex">
              <div className="item-badge prakerja">
                {dataCourse?.kategori?.filter((item: any) =>
                  item?.includes("Prakerja")
                )}
              </div>
              <div className="item-badge more-category">
                {dataCourse?.kategori
                  ?.filter((item: any) => item != "Prakerja")
                  .join(", ")}
              </div>
            </div>
            <h2 className="title-course">{dataCourse?.judulCourse}</h2>
            <p className="description">{dataWebinar?.sub_judul}</p>
            <div className="info-course d-flex align-items-center">
              <div className="info-rating item-info d-flex align-items-center">
                {/* <img src={IconBintang} alt="" className="icon-rating" /> */}
                <Bintang
                  tinggiBintang={14}
                  nilai={
                    dataCourse.jmlOrang > 0
                      ? Number(
                          dataCourse.nilaiKelas == 0
                            ? 4.9
                            : dataCourse.nilaiKelas
                        )
                      : 0
                  }
                />
                <p className="count-rating">
                  {dataCourse.jmlOrang > 0
                    ? Number(
                        dataCourse.nilaiKelas == 0 ? 4.9 : dataCourse.nilaiKelas
                      )
                    : 0}
                </p>
              </div>
              <div className="info-user item-info d-flex align-items-center">
                <img src={IconUser} alt="" className="icon-user" />

                <p className="count-user">{dataCourse?.jmlOrang} siswa</p>
              </div>
              <div className="info-rating item-info d-flex align-items-center">
                <img
                  src={
                    fasilitatorJSON?.avatar != ""
                      ? fasilitatorJSON?.avatar
                      : LogoAvatar
                  }
                  alt=""
                  className="icon-instructor"
                />
                <p className="count-rating">{fasilitatorJSON?.name}</p>
              </div>
            </div>
            {lebarLayar <= 1000 && (
              <GabungKelas
                dataWebinar={dataWebinar}
                infoKelas={infoKelas}
                dataDetail={dataDetail}
                loading={loading}
                lebarLayar={lebarLayar}
                heightHero={heightHero}
                heightBodyCoursePage={heightBodyCoursePage}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

interface IHero {
  infoKelas: any;
  dataDetail: any;
  dataWebinar: any;
  loading: boolean;
  lebarLayar: number;
  refHeightHero: any;
  heightHero: number;
  setHeightHero: (e: number) => void;
  heightBodyCoursePage: number;
}

export default Hero;
