import "./Popup.scss";
import { FC } from "react";
import IconAlert from "./icon-alert.svg";
import IconExit from "./icon-exit.svg";
import { useNavigate } from "react-router";

const Popup: FC<IPopup> = ({ closePopup, handleKeCheckout, dataDetail }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="popup-gabung-kelas-wrapper d-flex">
        <img src={IconExit} alt="" className="btn-close" onClick={closePopup} />
        <h2 className="title">Ikuti Pelatihan Prakerja</h2>
        {dataDetail?.mp_links != null && (
          <div className="button-group-marketplace">
            <table className="table">
              {dataDetail?.mp_links?.map((item: any, index: number) => {
                if (item?.href !== null) {
                  return (
                    <tr
                      key={index}
                      onClick={() =>
                        window.open(`${item?.mp_produk_link}`, "_blank")
                      }
                    >
                      <td>
                        <img src={item.mp_logo} alt="" loading="lazy" />
                      </td>
                    </tr>
                  );
                }
              })}
            </table>
          </div>
        )}
        <div className="cek-voucer-blox">
          <p className="info-cek-voucher">Atau sudah punya voucher?</p>
          <button
            className="btn-cta-voucher"
            onClick={() => handleKeCheckout("prakerja")}
          >
            TUKARKAN VOUCHER PRAKERJA
          </button>
        </div>
        <hr className="garis" />
        <div className="gabung-kelas-blox">
          <p className="info-gabung-kelas">
            Bukan peserta Prakerja?{" "}
            <span onClick={() => handleKeCheckout("reguler")}>
              Beli pelatihan di sini
            </span>
          </p>
          <div className="alert-gabung-kelas d-flex">
            <img src={IconAlert} alt="" className="icon-alert" />
            <p className="text">
              <b>Jika Anda merupakan peserta Prakerja</b>, namun memilih ini,
              <b> Anda tidak akan mendapatkan insentif</b>.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

interface IPopup {
  closePopup: () => void;
  handleKeCheckout: (i: string) => void;
  dataDetail: any;
}

export default Popup;
