export const FETCH_BANNER = "FETCH_BANNER";
// home page
export const FETCH_LOGO_MITRA = "FETCH_LOGO_MITRA";
export const FETCH_CATEGORY_TOP = "FETCH_CATEGORY_TOP";
export const FETCH_ON_SALE = "FETCH_ON_SALE";
export const FETCH_NEW_COURSE = "FETCH_NEW_COURSE";
export const FETCH_BEST_SELLER = "FETCH_BEST_SELLER";
export const FETCH_COURSE_CERTIFIED = "FETCH_COURSE_CERTIFIED";
export const FETCH_COURSE_BUSINESS = "FETCH_COURSE_BUSINESS";
export const FETCH_COURSE_FINANCIAL = "FETCH_COURSE_FINANCIAL";
export const FETCH_CATEGORY_BOTTOM = "FETCH_CATEGORY_BOTTOM";
export const FETCH_COURSE_CATEGORY = "FETCH_COURSE_CATEGORY";
export const FETCH_COURSE_CATEGORY_ARRAY = "FETCH_COURSE_CATEGORY_ARRAY";
export const FETCH_COURSE_COINS = "FETCH_COURSE_COINS";

// detail course class
export const FETCH_DETAIL_COURSE = "FETCH_DETAIL_COURSE";
export const FETCH_RELATED_COURSE = "FETCH_RELATED_COURSE";
export const SAVE_COURSE_DATA = "SAVE_COURSE_DATA";
export const FETCH_CERTIFICATE_JOB_READY = "FETCH_CERTIFICATE_JOB_READY";

// login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

// REGISTER
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

//payment
export const ADD_CART = "ADD_CART";
export const DELETE_CART = "DELETE_CART";
export const SET_DATA_PAYMENT = "SET_DATA_PAYMENT";
export const PAYMENT_PROCESSED = "PAYMENT_PROCESSED";
export const CHECKOUT = "CHECKOUT";

// LOGOUT
export const LOGOUT = "LOGOUT";

// message
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// dashboard user
export const FETCH_PROFILE_USER = "FETCH_PROFILE_USER";
export const FETCH_COURSES_USER = "FETCH_COURSES_USER";
export const FETCH_CERTIFICATE_USER = "FETCH_CERTIFICATE_USER";
export const FETCH_NOTIFICATION_NEW = "FETCH_NOTIFICATION_NEW";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const RESET_PASSWORD_EMAIL = "RESET_PASSWORD_EMAIL";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_EDIT_COIN = "UPDATE_EDIT_COIN";
export const FETCH_AVATAR = "FETCH_AVATAR";
export const UPLOAD_AVATAR = "UPLOAD_AVATAR";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const FETCH_VERIFY_EMAIL = "FETCH_VERIFY_EMAIL";

export const SET_DATA = "SET_DATA";

//cek kupon
export const FETCH_CHECK_COUPON = "FETCH_CHECK_COUPON";

export const FETCH_COURSE_SPESIAL = "FETCH_COURSE_SPESIAL";

//api wp new homepage
export const FETCH_HOME_FLASSHALE = "FETCH_HOME_FLASSHALE";
export const FETCH_HOME_TRENDING = "FETCH_HOME_TRENDING";
export const FETCH_HOME_BISNIS = "FETCH_HOME_BISNIS";
export const FETCH_HOME_KARIR = "FETCH_HOME_KARIR";

// Homepage Prakerja
export const FETCH_PRAKERJA_MARKETPLACE = "FETCH_PRAKERJA_MARKETPLACE";
export const FETCH_PRAKERJA_PUPULAR = "FETCH_PRAKERJA_PUPULAR";
export const FETCH_CHECK_CERTIFICATE = "FETCH_PRAKERJA_PUPULAR";

// wishlist
export const FETCH_WISHLIST = "FETCH_WISHLIST";
export const ADD_WISHLIST = "ADD_WISHLIST";
export const ADD_WISHLIST_FAIL = "ADD_WISHLIST_FAIL";
export const DELETE_WISHLIST = "DELETE_WISHLIST";
export const DELETE_WISHLIST_DATA = "DELETE_WISHLIST_DATA";
export const DELETE_WISHLIST_FAIL = "DELETE_WISHLIST_FAIL";

//search
export const FETCH_SEARCH = "FETCH_SEARCH";
export const SET_HISTORY_SEARCH = "SET_HISTORY_SEARCH";
export const DELETE_HISTORY_SEARCH = "DELETE_HISTORY_SEARCH";

export const FETCH_LIST_SEARCH = "FETCH_LIST_SEARCH";

export const SET_SEARCH_TERM = "SET_SEARCH_TERM";

// Arkademi Coins
export const COINS_REGISTER = "COINS_REGISTER";

export const FETCH_ICON_CATEGORY = "FETCH_ICON_CATEGORY";

//mentor
export const FETCH_MENTOR = "FETCH_MENTOR";

//global data
export const CLEAR_GLOBAL_DATA = "CLEAR_GLOBAL_DATA";
export const SET_GLOBAL_DATA = "SET_GLOBAL_DATA";
export const CLEAR_GLOBAL_CACHE_DATA = "SET_GLOBAL_CACHE_DATA";
export const SET_GLOBAL_CACHE_DATA = "SET_GLOBAL_CACHE_DATA";

//cache data
export const CLEAR_CACHE_DATA = "CLEAR_CACHE_DATA";
export const SET_CACHE_DATA = "SET_CACHE_DATA";
export const FETCH_CACHE_DATA = "FETCH_CACHE_DATA";
