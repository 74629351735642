import React, { FC } from "react";
import "./KontenEvaluasiDoneSpl.scss";

const KontenEvaluasiDoneSpl: FC<IKontenEvaluasiDoneSpl> = ({
  dataEvaluasiSpl,
}) => {
  return (
    <div className="kontent-evaluasi-done-spl">
      <h2 className="title">Umpan Balik Unjuk Keterampilan</h2>
      {/* <KontenFeedbackNull /> */}
      <table className="table-evaluasi">
        <tr>
          <th>Nilai</th>
          <td>{dataEvaluasiSpl != null ? dataEvaluasiSpl?.nilai : "-"}</td>
        </tr>
        {dataEvaluasiSpl?.nilai >= 80 && (
          <tr>
            <th>Predikat</th>
            <td>Sangat Baik</td>
          </tr>
        )}
        <tr>
          <th style={{ fontStyle: "italic" }}>FeedBack</th>
          <td className={dataEvaluasiSpl != null ? "" : "tidak-ada"}>
            {dataEvaluasiSpl != null
              ? dataEvaluasiSpl?.feedback
              : "Belum ada feedback"}
          </td>
        </tr>
      </table>
    </div>
  );
};

const KontenFeedbackNull = () => {
  return (
    <div className="konten-feedback-null">
      <p className="subtitle">Informasi</p>
      <ul className="ul-informasi">
        <li>
          Informasi Hasil penilaian Tugas Unjuk Keterampilan akan keluar 7x24
          jam setelah Unjuk Keterampilan dikumpulkan.
        </li>
        <li>
          Muat ulang dan cek secara berkala halaman ini untuk menampilkan hasil
          Umpan Balik Unjuk Keterampilan.
        </li>
        <li>
          Peserta akan mendapatkan sertifikat setelah feedback muncul di halaman
          ini dan mengklik tombol 'Selesaikan Pelatihan'
        </li>
      </ul>
      <button
        className="btn-refresh"
        onClick={() => {
          window.location.reload();
        }}
      >
        Muat Ulang
      </button>
    </div>
  );
};

interface IKontenEvaluasiDoneSpl {
  dataEvaluasiSpl: any;
}

export default KontenEvaluasiDoneSpl;
