import axios from "axios";
import { SAVE_COURSE_DATA } from "store/types";

export const api_course_url = process.env.REACT_APP_API_BACKEND_COURSE;
export const api_wp = process.env.REACT_APP_API_BACKEND_PRODUCTION;

/**
 * @param {string} dataName
 * @param {string} data
 * @returns
 */
export const setCourseData = (dataName, data) => {
  return {
    type: SAVE_COURSE_DATA,
    payload: {
      [dataName]: data,
    },
  };
};

/**
 * load api from api_course_url "/api/v1/course/{{idKelas}}/{{link_microservices}}"
 * @returns
 */
export const fetchCourseMicroservices = async (
  link,
  idKelas,
  token,
  reqMicroservices,
  denganToken
) => {
  try {
    const response = await axios.get(
      `${api_course_url}/api/v1/prakerja/course/${link}/${idKelas}`,
      {
        headers: token
          ? {
              Authorization: token,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            }
          : {},
        cancelToken: reqMicroservices.token,
      }
    );
    if (response.status === 200) {
      return {
        success: true,
        message: null,
        data: response.data,
      };
    } else {
      return {
        success: false,
        message: response.data.message || "gagal tidak diketahui",
        data: null,
      };
    }
  } catch (e) {
    return {
      success: false,
      message: e.message,
      data: null,
    };
  }
};

export const fetchCourseId = async (slug) => {
  if (slug !== null && slug !== undefined && slug !== "") {
    const response = await axios.get(
      `${api_course_url}/api/v1/prakerja/course/detail/${slug}`,
      {
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    );
    if (!response?.data?.data) {
      return null;
    } else {
      return response.data.data;
    }
  } else {
    return null;
  }
};

/**
 *
 * @param {number} idKelas
 * @param {string} link
 * @returns
 */
export const fetchCourseWP = async (idKelas, reqCourseWP) => {
  try {
    const response = await axios.get(
      api_wp + `/wp-json/wplms/v1/course/${idKelas}`,
      {
        cancelToken: reqCourseWP.cancelToken,
      }
    );
    if (response.status === 200) {
      return {
        success: true,
        message: null,
        data: response.data,
      };
    } else {
      return {
        success: false,
        message: "gagal tidak diketahui",
        data: null,
      };
    }
  } catch (e) {
    console.error(e);
    return {
      success: false,
      message: e.message,
      data: null,
    };
  }
};

export const fetchCourseMicroservicesId = async (
  idKelas,
  link,
  token,
  reqMicroservices,
  denganToken
) => {
  try {
    const response = await axios.get(
      `${api_course_url}/api/v1/prakerja/course/${idKelas}/${link}`,
      {
        headers: token
          ? {
              Authorization: token,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            }
          : {},
        cancelToken: reqMicroservices.token,
      }
    );
    if (response.status === 200) {
      return {
        success: true,
        message: null,
        data: response.data,
      };
    } else {
      return {
        success: false,
        message: response.data.message || "gagal tidak diketahui",
        data: null,
      };
    }
  } catch (e) {
    return {
      success: false,
      message: e.message,
      data: null,
    };
  }
};

export const fetchuserStatusCourse = async (
  idKelas,
  token,
  reqMicroservices,
  denganToken
) => {
  try {
    const response = await axios.get(
      `${api_course_url}/api/v1/prakerja/course/user_status/${idKelas}`,
      {
        headers: token
          ? {
              Authorization: token,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            }
          : {},
        cancelToken: reqMicroservices.token,
      }
    );
    if (response.status === 200) {
      return {
        success: true,
        message: null,
        data: response.data,
      };
    } else {
      return {
        success: false,
        message: response.data.message || "gagal tidak diketahui",
        data: null,
      };
    }
  } catch (e) {
    return {
      success: false,
      message: e.message,
      data: null,
    };
  }
};