import "./Curriculum.scss";
import ChevronAtas from "./gbr/chevron-atas.svg";
import ChevronBawah from "./gbr/chevron-bawah.svg";
import IkonVideo from "./gbr/video-black.svg";
import IkonTes from "./gbr/tes-black.svg";
import IkonInfo from "./gbr/info-black.svg";
import { FC, useEffect, useState } from "react";
import SkeletonCurriculum from "./SkeletonCurriculum";
import Skeleton from "react-loading-skeleton";

const Curriculum: FC<ICurriculum> = ({
  kurikulum,
  loading,
  refKurikulum,
  infoKelas,
}) => {
  const [angka, setAngka] = useState<number>(0);
  const [kurikulumAkt, setKurikulumAkt] = useState<any>([]);

  useEffect(() => {
    const arBaru = isiArray(false, kurikulum.length);
    setKurikulumAkt(arBaru);
  }, [kurikulum.toString()]);

  const handelKlikCourse = (indeks: any, aktif: any) => {
    const arBaru = kurikulumAkt;
    arBaru[indeks] = !aktif;
    setKurikulumAkt([...arBaru]);
  };

  return (
    <div className="coursepage-curriculum-wrapper" ref={refKurikulum}>
      {loading ? (
        <SkeletonCurriculum />
      ) : (
        <>
          <h3 className="title">Kurikulum</h3>
          <ul className="isi-kurikulum-course" id="kurikulum-jrc">
            {loading ? (
              <li key={"loading"}>
                <Skeleton width={"100%"} height={100} />
              </li>
            ) : (
              kurikulum.map((nilai: any, indeks: any) => {
                return (
                  <IsiKurikulum
                    kurikulum={nilai}
                    aktif={kurikulumAkt[indeks]}
                    indeks={indeks}
                    handelKlikCourse={handelKlikCourse}
                    angka={angka}
                    key={indeks}
                  />
                );
              })
            )}
          </ul>
        </>
      )}
    </div>
  );
};

const IsiKurikulum: FC<IsiKurikulum> = ({
  kurikulum,
  aktif,
  indeks,
  handelKlikCourse,
  angka,
  key,
}) => {
  return (
    <>
      <li
        className="kontainer-list-kurikulum-course"
        id="kurikulum-jrc"
        key={indeks}
      >
        <div
          className="list-kurikulum-course"
          id="kurikulum-jrc"
          onClick={() => {
            handelKlikCourse(indeks, aktif);
          }}
        >
          <div
            className="isi-list"
            id="kurikulum-jrc"
            dangerouslySetInnerHTML={{ __html: kurikulum.bagian }}
          />
          <p className="isi-kurikulum" id="kurikulum-jrc">
            {kurikulum.menit.toFixed(0) + " Menit"}
          </p>
          <div
            className="placeholder-chevron-list-kurikulum"
            id="kurikulum-jrc"
          >
            <img
              className="chevron-list-kurikulum-course"
              id="kurikulum-jrc"
              src={aktif ? ChevronAtas : ChevronBawah}
            />
          </div>
        </div>
        {aktif && (
          <ul className="isi-anak-kurikulum-course" id="kurikulum-jrc">
            {kurikulum.isiKelas.map((nilai: any, indeks: any) => {
              return (
                <AnakKurikulum
                  isi={nilai.kelas}
                  jenis={nilai.jenis}
                  menit={nilai.menit}
                  aktif={nilai.aktif}
                  gbr={TentukanGbr(nilai.jenis)}
                  indeks={indeks}
                  key={indeks}
                />
              );
            })}
          </ul>
        )}
      </li>
    </>
  );
};

const AnakKurikulum: FC<IAnakKurikulum> = ({
  isi,
  jenis,
  menit,
  aktif,
  gbr,
  indeks,
  key,
}) => {
  return (
    <>
      <li className="anak-kurikulum-course" id="kurikulum-jrc" key={indeks}>
        <img
          className="gbr-anak-kurikulum-course"
          id="kurikulum-jrc"
          src={gbr}
        />
        <div
          className="isi"
          id="kurikulum-jrc"
          dangerouslySetInnerHTML={{ __html: isi }}
        />
        {jenis && (
          <h6 id="kurikulum-jrc">
            {!cekHidenTime(isi) &&
              (menit > 0
                ? TentukanStr(jenis) + " : " + menit.toFixed(0) + " Menit"
                : "")}
          </h6>
        )}
      </li>
    </>
  );
};

export const cekHidenTime = (title: any) => {
  const kata1a = title.toLowerCase().split(" ")[0] == "tugas";
  const kata2a = title.toLowerCase().split(" ")[1] == "praktik";
  const kata3a = title.toLowerCase().split(" ")[2] == "mandiri";
  const kata1b = title.toLowerCase().split(" ")[0] == "instruksi";
  const kata2b = title.toLowerCase().split(" ")[1] == "unjuk";
  const kata3b = title.toLowerCase().split(" ")[2] == "keterampilan";
  if (
    ((kata1a as boolean) && (kata2a as boolean) && (kata3a as boolean)) ||
    ((kata1b as boolean) && (kata2b as boolean) && (kata3b as boolean))
  ) {
    return true;
  } else {
    return false;
  }
};

function isiArray(nilai: any, panjang: any) {
  var ar = [];
  for (var i = 0; i < panjang; i++) {
    ar.push(nilai);
  }
  return ar;
}

function TentukanStr(str: string) {
  if (str === "VIDEO") {
    return "Video";
  } else if (str === "KUIS") {
    return "Kuis";
  } else {
    return "Info";
  }
}

function TentukanGbr(str: string) {
  if (str === "VIDEO") {
    return IkonVideo;
  } else if (str === "KUIS") {
    return IkonTes;
  } else {
    return IkonInfo;
  }
}

interface ICurriculum {
  kurikulum: any;
  loading: boolean;
  refKurikulum: any;
  infoKelas: any;
}

interface IsiKurikulum {
  kurikulum: any;
  aktif: any;
  indeks: any;
  handelKlikCourse: (indeks: any, aktif: any) => void;
  angka: any;
  key: any;
}

interface IAnakKurikulum {
  isi: any;
  jenis: any;
  menit: any;
  aktif: any;
  gbr: any;
  indeks: any;
  key: any;
}

export default Curriculum;
