import "./KontenEvaluasiDone.scss";
import { FC } from "react";
import GbrBintang from "./gbr-bintang.svg";
import { useNavigate } from "react-router";

const KontenEvaluasiDoneNew: FC<IKontenEvaluasiDoneNew> = ({
  dataEvaluasi,
  dataEvaluasiUK,
  dataFeedbackTPM,
}) => {
  const navigate = useNavigate();
  return (
    <div className="konten-evaluasi-done">
      <div className="hasil-kelulusan-pelatihan">
        <h2 className="judul-unjuk-keterampilan">Hasil Unjuk Keterampilan</h2>
        <p className="prompt-hasil">
          Berikut adalah hasil nilai Unjuk Keterampilan yang kamu dapatkan
        </p>
        <div className="pernyataan-lulus">
          <img className="gbr-lulus" src={GbrBintang} />
          <p className="teks-dinyatakan">Kamu dinyatakan</p>
          <p className="teks-lulus">LULUS</p>
          <p className="nilai-yang-diperoleh">
            Nilai yang diperoleh: {dataEvaluasi?.nilai}
          </p>
        </div>
      </div>
      <table className="table table-bordered table-feedback-tpm-uk">
        <thead>
          <tr>
            <th>Unit</th>
            <th>Nilai</th>
            <th>Feedback</th>
          </tr>
        </thead>
        <tbody>
          {dataFeedbackTPM?.data?.length > 0 ? (
            dataFeedbackTPM?.data
              ?.sort((a: any, b: any) => a.sesi - b.sesi)
              ?.map((item: any) => {
                return (
                  <tr>
                    <td className="cal-unit">TPM {item?.sesi}</td>
                    <td className="cal-nilai">{item?.nilai}</td>
                    <td className="cal-feedback">{item?.feedback}</td>
                  </tr>
                );
              })
          ) : (
            <tr>
              <td className="cal-unit">TPM</td>
              <td className="cal-nilai">-</td>
              <td className="cal-feedback">Belum ada feedback</td>
            </tr>
          )}
          {dataEvaluasiUK?.data ? (
            <tr>
              <td className="cal-unit">UK</td>
              <td className="cal-nilai">{dataEvaluasiUK?.data?.nilai}</td>
              <td className="cal-feedback">{dataEvaluasiUK?.data?.feedback}</td>
            </tr>
          ) : (
            <tr>
              <td className="cal-unit">UK</td>
              <td className="cal-nilai">-</td>
              <td className="cal-feedback">Belum ada feedback</td>
            </tr>
          )}
        </tbody>
      </table>
      <h2 className="judul-sertifikat">Sertifikat Penyelesaian</h2>
      <p className="teks-sertifikat">
        Sertifikat Penyelesaian berhasil kamu dapatkan karena sudah
        menyelesaikan seluruh rangkaian pelatihan ini. Untuk{" "}
        <b>mengunduh sertifikat</b>, kamu dapat masuk ke{" "}
        <b>menu profile {">"} sertifikat</b>.
      </p>

      <img className="gbr-sertifikat" src={dataEvaluasi?.certi_path} />

      <button
        className="tbl-sertifikat"
        onClick={() => navigate("/dashboard/sertifikat")}
      >
        Lihat Sertifikat
      </button>
    </div>
  );
};

interface IKontenEvaluasiDoneNew {
  dataEvaluasi: any;
  dataEvaluasiUK: any;
  dataFeedbackTPM: any;
}

export default KontenEvaluasiDoneNew;
