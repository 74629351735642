import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "store/actions/dashboard";

export default function useFetchProfile() {
  const dispatch = useDispatch();
  const [dataProfile, setDataProfile] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDataProfile = useSelector(
    (state) => state.dashboard.profile || null
  );

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await fetchProfile("profile", dispatch);
      setLoading(false);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (getDataProfile != null) {
      setDataProfile(getDataProfile);
    }
  }, [getDataProfile]);

  return [dataProfile, loading];
}
