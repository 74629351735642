import "./GabungKelas.scss";
import { FC, useEffect, useState } from "react";
import IconArrowDown from "./icon-arrow-down.svg";
import angkaDenganTitik from "utils/angkaDenganTitik";

const TabelGabungKelas: FC<ITabelGabungKelas> = ({
  dataWebinar,
  infoKelas,
  sesiTerpilih,
  showOption,
  setShowOption,
  handlePilihSesi,
  tblGabung,
  handleBtnGabungKelas,
  // errorSesiNull,
}) => {
  const [sesiKelas, setSesiKelas] = useState<string>("");

  const cekWaktu = (sesi: string) => {
    if (sesi == "0") {
      return "Sesi Pagi: 10.00 - 12.00 WIB";
    } else if (sesi == "1") {
      return "Sesi Siang: 14.00 - 16.00 WIB";
    } else if (sesi == "2") {
      return "Sesi Malam: 19.00 - 21.00 WIB";
    }
  };

  const cekSesiPenuh = (kuota: number) => {
    if (kuota > 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (sesiTerpilih == "0") {
      setSesiKelas("pagi");
    } else if (sesiTerpilih == "1") {
      setSesiKelas("siang");
    } else if (sesiTerpilih == "2") {
      setSesiKelas("malam");
    }
  }, [sesiTerpilih]);

  return (
    <div className="section-tbl-action">
      <div className="info-course d-flex">
        <h3 className="price-course">
          Rp {angkaDenganTitik(infoKelas?.harga)}
        </h3>
        {/* <div className="form-dropdown-sesi">
          <button
            className={`btn-cta-sesi d-flex ${errorSesiNull ? "error" : ""} ${
              infoKelas?.status != "TAKADA" ? "disabled" : ""
            }`}
            onClick={() => setShowOption(!showOption)}
            disabled={infoKelas?.status != "TAKADA"}
          >
            {sesiTerpilih == ""
              ? "Pilih Sesi"
              : `Sesi ${sesiKelas[0]?.toUpperCase() + sesiKelas?.substring(1)}`}
            <img src={IconArrowDown} alt="" className="icon-dropdown" />
          </button>
          {showOption && (
            <div className="option-sesi d-flex">
              {dataWebinar?.product_limit?.map((item: any, indeks: number) => {
                return (
                  <button
                    className="btn-cta-option-sesi"
                    id="wb-prakerja-dropdown-pagi"
                    onClick={() => handlePilihSesi(item?.sesi)}
                    disabled={cekSesiPenuh(
                      item?.order_limit - item?.order_count
                    )}
                  >
                    {cekWaktu(item?.sesi)}
                  </button>
                );
              })}
            </div>
          )}
        </div> */}
      </div>
      <button
        className="btn-cta-course"
        id="wb-prakerja-click-CTA"
        onClick={() => handleBtnGabungKelas()}
      >
        {tblGabung}
      </button>
    </div>
  );
};

interface ITabelGabungKelas {
  dataWebinar: any;
  infoKelas: any;
  sesiTerpilih: string;
  showOption: boolean;
  setShowOption: (e: boolean) => void;
  handlePilihSesi: (e: string) => void;
  tblGabung: string;
  handleBtnGabungKelas: () => void;
  // errorSesiNull: boolean;
}

export default TabelGabungKelas;
