import "./confirmExit.scss";
import { useNavigate } from "react-router";
import store from "store/index";
import { LOGOUT } from "store/types";

export default function ConfirmExit({ isOpen }) {
  const navigate = useNavigate();

  return (
    <>
      <section className="kontainer-modal-confirm-exit">
        <div className="modal-confirm-exit d-flex flex-column justify-content-center bg-white ">
          <h5 className="title-card">Yakin Keluar?</h5>
          <div className="button-group">
            <button
              class="button-back"
              onClick={() => {
                isOpen(false);
              }}
            >
              Tidak
            </button>
            <button
              class="button-submit"
              onClick={(e) => {
                e.preventDefault();
                isOpen(false);
                // localStorage.setItem("user", null);
                // localStorage.setItem("keranjang", null);
                // localStorage.setItem("pembayaranProses", null);
                localStorage.clear();
                navigate(`/`);
                store.dispatch({
                  type: LOGOUT,
                  payload: null,
                });
              }}
            >
              Ya
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
