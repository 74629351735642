import { useState, useEffect } from "react";
import MyRobbin from "../../gbr/gbrDummy/my-robbin.png";
import { IInfoKelas, TStatusCourse } from "type/Course";

export default function useScrapeInfoKelas(
  courseJSON: any,
  userStatusJSON: any | null
) {
  const [infoKelas, setInfoKelas] = useState<IInfoKelas>(defaultInfoKelas);
  // }data info kelas

  useEffect(() => {
    infoKelas.status = cekStatusKelas(userStatusJSON);
    if (
      courseJSON &&
      courseJSON["data"] &&
      courseJSON["data"][0] &&
      courseJSON["data"][0]["course"]
    ) {
      const dataDapat = courseJSON["data"][0];
      const dataCourseDapat = dataDapat["course"];
      // console.log("dataCOURSeDATAP", dataCourseDapat);

      const hargaTerjual = dataCourseDapat?.price || 0;
      const hargaSebenarnya =
        dataCourseDapat?.regular_price === hargaTerjual
          ? null
          : dataCourseDapat?.regular_price;

      infoKelas.idKelas = dataCourseDapat["id"];
      infoKelas.harga = Number(hargaTerjual);
      infoKelas.hargaNonDiskon = hargaSebenarnya;
      infoKelas.diskon =
        hargaSebenarnya != null
          ? ((Number(hargaSebenarnya) - infoKelas.harga) /
              Number(hargaSebenarnya)) *
            100
          : null;
      infoKelas.hemat = hargaSebenarnya
        ? hargaSebenarnya - infoKelas.harga
        : null;
      infoKelas.gbrKelas = dataCourseDapat["featured_image"];
      infoKelas.hariAkses = Number(dataCourseDapat["course_duration_time"]);
      infoKelas.judul = dataCourseDapat["name"];
      infoKelas.rating = dataCourseDapat["average_rating"];
      infoKelas.deskripsiHTML = dataCourseDapat["description"];
      infoKelas.jmlOrang = dataCourseDapat["total_students"];
      infoKelas.idProduk = dataCourseDapat["product_id"];

      const lowongan = dataCourseDapat["lowongan"];
      if (lowongan) {
        const tglAwal = new Date(
          lowongan.startdate_lowongan?.replace(/-/g, "/")
        ).getMilliseconds();
        const tglAkhir = new Date(
          lowongan.enddate_lowongan?.replace(/-/g, "/")
        ).getMilliseconds();

        infoKelas.lowongan = lowongan.jumlah_lowongan;
        infoKelas.sbrLowongan = lowongan.reference;
        infoKelas.gajiRendah = !isNaN(Number(lowongan.gaji_min))
          ? lowongan.gaji_min
          : null;
        infoKelas.gajiTinggi = !isNaN(Number(lowongan.gaji_max))
          ? lowongan.gaji_max
          : null;
        infoKelas.lowonganHari = Math.abs(tglAkhir - tglAwal) / 86400000;
      }
      const koinJSON = dataCourseDapat["coin"];

      if (koinJSON) {
        const available = true;
        infoKelas.coinAvailable = available;
        if (available) {
          infoKelas.valueCoin = (infoKelas.harga / 100) * 5;
        }
      }

      infoKelas.slug = dataCourseDapat["slug"];
      infoKelas.denganWA = dataCourseDapat?.["course_flag"]?.whatsapp === "1";
      infoKelas.prakerja = dataCourseDapat?.["course_flag"]?.prakerja === "1";
      infoKelas.webinar = dataCourseDapat?.["course_flag"]?.webinar;

      infoKelas.publishStatus = dataCourseDapat["status"];

      setInfoKelas({ ...infoKelas });
    }
  }, [courseJSON, userStatusJSON]);

  return infoKelas;
}

const cekStatusKelas = (userStatusJSON: any) => {
  let statusFinal: TStatusCourse = "TAKADA";
  if (userStatusJSON) {
    const userExpiry = Number(userStatusJSON["user_expiry"]);
    const status = userStatusJSON["user_status"];
    const remaining = formatRemaining(userExpiry);

    if (!Number.isNaN(userExpiry)) {
      if (remaining < 0) {
        statusFinal = "TAKADA";
      } else {
        if (status === "1") {
          statusFinal = "MULAI";
        } else if (status === "2") {
          statusFinal = "LANJUTKAN";
        } else if (status === "3") {
          statusFinal = "EVALUASI";
        } else if (status === "4") {
          statusFinal = "SELESAI";
        } else if (status === "") {
          statusFinal = "TAKADA";
        }
      }
    } else {
      statusFinal = "TAKADA";
    }
  } else {
    //userStatus null
    statusFinal = "TAKADA";
  }
  return statusFinal;
};

const formatRemaining = (e: number) => {
  const unixEpochTimeMS = e * 1000;
  const countDownDate = new Date(unixEpochTimeMS).getTime();

  const now = new Date().getTime();
  const distance = countDownDate - now;
  return distance;
};

const defaultInfoKelas: IInfoKelas = {
  idKelas: null,
  idProduk: null,
  gbrKelas: null,
  deskripsiHTML: null,
  harga: 0,
  hargaNonDiskon: null,
  diskon: null,
  hemat: null,
  judul: "",
  rating: 0,
  jmlOrang: 0,
  penyelesaian: 0,
  hariAkses: 0,
  kelasKursus: 0,
  videoAjar: 0,
  kuis: 0,
  pdf: true,
  sertifikat: true,
  lencana: true,
  namaLowongan: "",
  lowongan: null,
  sbrLowongan: null,
  gajiRendah: null,
  gajiTinggi: null,
  lowonganHari: null,
  status: "EVALUASI", //"LANJUTKAN", "EVALUASI", "SELESAI",
  publishStatus: "draft",
  kadaluarsa: 0,
  coinAvailable: false,
  valueCoin: 0,
  gbrLowongan: MyRobbin,
  denganWA: false,
  prakerja: false,
  webinar: null,
  slug: "",
};
