import React, { Component } from "react";
import Button from "elements/Button";
import "./index.scss";

export default class KuponPrakerja extends Component {
  render() {
    return (
      <section>
        <div className="container">
          <div className="check-kupon-prakerja">
            <p>
              Punya kupon <span>Pelatihan Prakerja?</span> Pakai kuponnya atau
              cek sertifikat Prakerja anda.
            </p>
            <span>
              <Button className="btn px-5" isPrimary isLarge isRadius>
                Gunakan Kupon
              </Button>
              <Button
                className="btn btn-outline-primary px-5 ml-2"
                isLight
                isLarge
                isRadius
              >
                Cek sertifikat
              </Button>
            </span>
          </div>
        </div>
      </section>
    );
  }
}
