import { useState } from "react";
import { useEffect } from "react";

export default function useScrapeReview(reviewJSON) {
  const [review, setReview] = useState({
    totalLaman: 0,
    isi: [],
  });

  useEffect(() => {
    if (
      reviewJSON &&
      reviewJSON.data &&
      reviewJSON.data.data &&
      reviewJSON.data.data.length > 0
    ) {
      const komenDapat = reviewJSON.data.data;
      komenDapat?.sort((a, b) => {
        return Date.parse(b["comment_date"]) - Date.parse(a["comment_date"]);
      });
      review.isi = [];
      for (let i = 0; i < komenDapat?.length; i++) {
        const komenSekarang = komenDapat[i];
        let rating = 1;
        if (komenSekarang) {
          rating = Number(komenSekarang["review_rating"]);
          review.isi.push({
            namaPengguna: komenSekarang.member.name || null,
            fotoProfil: komenSekarang.member.avatar || null,
            waktuPos: Date.parse(komenSekarang["date"]),
            rating: rating,
            review: komenSekarang["content"],
          });
        }
      }
      review.totalLaman = reviewJSON.data?.last_page || 0;
      const newReview = { ...review };
      setReview(newReview);
    }
  }, [reviewJSON]);

  return review;
}
