import "./SertifikatGenerator.scss"
import { FC, useEffect } from "react"
import { useRef, useState } from "react"
import SertifikatPenyelesaian from "./sertifikat-penyelesaian-coba.jpeg"
import html2canvas from "html2canvas"
import { IDataSert } from "type/Sertifikat"
import { useSearchParams } from "react-router-dom"
import { IRespUmum } from "type/Fetch"
import { useSetDataWatchCourse } from "utils/useStoreWatchCourse"



const SertifikatGenerator: FC<ISertifikatGenerator> = ({ sertifikat }) => {
  const refKanvas = useRef<HTMLDivElement | null>(null)
  const [gbr, setGbr] = useState<null | string>(null)
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("user-id")
  const courseId = searchParams.get("course-id")
  const link = `/api/v1/certificate/prakerja/gen_certi?user_id=${userId}&course_id=${courseId}`
  const [sertifikatJSON, setSertifikatJSON] = useState<IDataSert | null>(null)
  const setCertificateGenerated = useSetDataWatchCourse("certificateGenerated")
  useEffect(() => {
    const generate = async () => {
      mountStyles(sertifikat?.data?.custom_css || "")
      setSertifikatJSON(sertifikat?.data || null)
    }
    generate()
  }, [userId, courseId])
  const onLoad = async () => {
    const canvas = await html2canvas(refKanvas.current as HTMLElement, {
      allowTaint: true,
      useCORS: true
    })
    const dataUrl = canvas.toDataURL("image/png")
    setGbr(dataUrl)
    console.log("certificateGenerated:::", dataUrl)
    setCertificateGenerated(dataUrl)
  }

  return (
    <div className="container-page-canvas">
      <div className={`canvas-generate ${sertifikatJSON?.custom_class}`} style={{ width: Number(sertifikatJSON?.width) || "20px", height: Number(sertifikatJSON?.height) || "20px" }} ref={refKanvas}
        dangerouslySetInnerHTML={{ __html: modifHTML(sertifikatJSON, onLoad) }}>
      </div>
      {gbr && <img src={gbr} />}
    </div>
  )
}

const modifHTML = (sertifikatJSON: IDataSert | null, callback: () => void) => {
  let html = sertifikatJSON?.content || null
  if (html) {

    //edit string dinamis
    const regexDalamKurung = /\[(.*)\]/mg
    const listString = html.match(regexDalamKurung) || []
    listString.forEach((stringGanti) => {
      const stringGantiTanpaKurung = stringGanti.replace(/\[/mg, "").replace(/\]/mg, "")
      let dataDinamis = (sertifikatJSON as any)?.[stringGantiTanpaKurung] || ""
      if (stringGantiTanpaKurung === "certificate_course") {
        dataDinamis = sertifikatJSON?.title || ""
      } else if (stringGantiTanpaKurung === "course_completion_date") {
        const tglPenyelesaian = new Date(sertifikatJSON?.course_completion_date || (new Date()).toISOString())
        html = html?.replace(RegExp('\\[' + "course_completion_date" + '\\]', "g"), `${tglPenyelesaian.getFullYear()}-${tglPenyelesaian.getMonth() + 1}-${tglPenyelesaian.getDate()}`) || null
      }
      html = html?.replace(RegExp('\\[' + stringGantiTanpaKurung + '\\]', "g"), dataDinamis) || null
    })

    //tambahkan fungsi callback pada gambar pada saat onload
    let div = document.createElement("div")
    div.innerHTML = html
    const htmlGbr = div.getElementsByTagName("img")[0]
    if (htmlGbr.parentNode) {
      htmlGbr.onload = callback
    }

    //done
    return div?.innerHTML ? div?.innerHTML : ""
  } else {
    return ""
  }
}

function mountStyles(stylesStr: string) {
  const styleTag = document.createElement('style');
  styleTag.textContent = stylesStr;
  document.head.appendChild(styleTag);
}


interface ISertifikatGenerator {
  sertifikat: IRespUmum<IDataSert>
}


export default SertifikatGenerator
