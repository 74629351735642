import "./GeneratePart.scss"
import { FC, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";
import { useFetchTrigger } from "utils/useFetchUmum";
import { IRespUmum } from "type/Fetch";
import { IDataSert } from "type/Sertifikat";
import SertifikatGenerator from "elements/CobaCanvas";
import Loading from "elements/Loading/Loading";

const GeneratePart: FC<IGeneratePart> = ({ }) => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("user-id")
  const courseId = searchParams.get("course-id")
  const [sertifikatGeneratedJSON, setSertifikatGenerated] = useState<IRespUmum<IDataSert> | null>(null)
  const [getGenerate] = useFetchTrigger<IRespUmum<IDataSert>>("apicourse", `/api/v1/prakerja/certificate/gen_certi?user_id=${userId}&course_id=${courseId}`)
  useEffect(() => {
    if (userId === null || courseId === null) return
    const generateGet = async () => {
      const generated = await getGenerate()
      console.log("generated", generated)
      setSertifikatGenerated(generated)
    }
    generateGet()
  }, [userId, courseId])
  return (
    <div>
      {sertifikatGeneratedJSON ?
        <SertifikatGenerator sertifikat={sertifikatGeneratedJSON} />
        :
        <Loading />
      }
    </div>
  )
}

interface IGeneratePart {

}

export default GeneratePart