import "./Informasi.scss";
import { FC } from "react";
import IconCentang from "./icon-centang.svg";
import Intro from "parts/CourseDetailPage/CoursePageReguler/bagian/Intro/Intro";

const Informasi: FC<IInformasi> = ({
  dataWebinar,
  infoKelas,
  dataDetail,
  loading,
  refInformasi,
}) => {
  const dataDetailJSON = dataDetail?.data[0]?.course;
  return (
    <div className="coursepage-informasi-wrapper" ref={refInformasi}>
      {/* <div className="section-sasaran">
        <h3 className="title">Cocok untuk kamu</h3>
        <ul className="list-item-sasaran d-flex">
          {dataWebinar?.cocok_untuk?.map((item: any, indeks: any) => {
            return (
              <li className="sasaran-item d-flex">
                <img src={IconCentang} alt="" className="icon-item" />
                <p className="text">{item}</p>
              </li>
            );
          })}
        </ul>
      </div> */}
      <div className="section-materi">
        <h3 className="title">Yang akan dipelajari</h3>
        <ul className="list-item-materi d-flex">
          {dataDetailJSON?.yg_akan_dipelajari?.map((item: any, indeks: any) => {
            return (
              <li className="materi-item d-flex">
                <img src={IconCentang} alt="" className="icon-item" />
                <p className="text">{item}</p>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="section-skill">
        <h3 className="title">Skill yang akan diperoleh</h3>
        <ul className="list-item-skill d-flex">
          {dataDetailJSON?.skill_yg_akan_diperoleh?.map(
            (item: any, indeks: any) => {
              return <li className="skill-item d-flex">{item}</li>;
            }
          )}
        </ul>
      </div>
      <div className="section-karier">
        <h3 className="title">Peluang Karier</h3>
        <ul className="list-item-karier d-flex">
          {dataWebinar?.peluang_karir?.map((item: any, indeks: any) => {
            return <li className="karier-item d-flex">{item}</li>;
          })}
        </ul>
      </div>
      <div className="section-ikhtisar">
        <h3 className="title">Ikhtisar</h3>
        <div className="deskripsi-kelas">
          <Intro introHTML={infoKelas.deskripsiHTML} infoKelas={infoKelas} />
        </div>
      </div>
    </div>
  );
};

interface IInformasi {
  dataWebinar: any;
  infoKelas: any;
  dataDetail: any;
  loading: boolean;
  refInformasi: any;
}

export default Informasi;
