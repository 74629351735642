import "./ButtonMenu.scss";

const ButtonMenu = ({
  name,
  nameClass,
  iconMenu,
  classActive,
  value,
  handleClick,
  handleEnter,
  handleLeave,
}) => {
  return (
    <>
      <li className={`btn-menu-dashboard ${classActive}`}>
        <img src={require(`${iconMenu}`).default} />
        <span>{name}</span>
        <button
          type="button"
          className={`button button-${nameClass}`}
          value={value}
          onClick={handleClick}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
        ></button>
      </li>
    </>
  );
};

export default ButtonMenu;
