export function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.tokenPrakerja && user.tokenPrakerja.access_token) {
    return { Authorization: user.tokenPrakerja.access_token };
    // return { Authorization: "Bearer " + user.token.access_token };
  } else {
    return {};
  }
  //   if (user && user.accessToken) {
  //     // for Node.js Express back-end
  //     return { "x-access-token": user.accessToken };
  //   } else {
  //     return {};
  //   }
}

export function authHeaderFile() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.tokenPrakerja && user.tokenPrakerja.access_token) {
    return {
      Authorization: user.tokenPrakerja.access_token,
      "Content-Type": "multipart/form-data"
    };
  } else {
    return {};
  }
}
