import { useSelector } from "react-redux";

/**@returns {[string] | [null]} */
export default function useToken() {
  const token = useSelector((state) =>
    state.authorization &&
    state.authorization.user &&
    state.authorization.user.tokenPrakerja &&
    state.authorization.user.tokenPrakerja.access_token
      ? state.authorization.user.tokenPrakerja.access_token
      : null
  );

  return [token];
}
