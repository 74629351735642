import "./Curriculum.scss";
import { FC } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonCurriculum: FC<ISkeletonCurriculum> = ({}) => {
  return (
    <>
      <Skeleton height={400} width="100%" className="mb-4" />
    </>
  );
};

interface ISkeletonCurriculum {}

export default SkeletonCurriculum;
