// input:
// format bisa terserah bentuknya contoh: yyyy-MM-dd return 2004-03-25; yyyy-MMMM-dd return 2004-Maret-25
// format default dd/MM/yyyy tapi bisa diatur
// default timestamp dalam sekon, bisa diatur dalamMili true atau false opsional,
// contoh: formatWaktu(1630911109, "dd\MM\yyyy") return 09\06\2021

export function formatWaktu(timestamp = 0, format = "dd/MM/yyyy", dalamMili = false) {
  if (timestamp != null) {
    var kalender = new Date(dalamMili ? timestamp * 1000 : timestamp);
    var arBulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei'
      , 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

    var tanggal = kalender.getDate();
    var bulan = kalender.getMonth();
    var bulanString = arBulan[kalender.getMonth()]
    var tahun = kalender.getFullYear();

    var waktuFormat = format.replace("dd", tanggal).replace("MMMM", bulanString).replace("MM", bulan + 1).replace("yyyy", tahun)

    return waktuFormat
  } else { return "" }
}

// waktu sejak tanggal saat ini
// input tanggal Mili
export function waktuSejak(tanggalMili = 0) {

  if (tanggalMili !== null && tanggalMili !== undefined) {
    var detik = Math.floor((new Date() - tanggalMili) / 1000);

    var interval = detik / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " tahun lalu";
    }
    interval = detik / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " bulan lalu";
    }
    interval = detik / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " hari lalu";
    }
    interval = detik / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " jam lalu";
    }
    interval = detik / 60;
    if (interval > 1) {
      return Math.floor(interval) + " menit lalu";
    }
    return Math.floor(detik) + " detik lalu";
  } else {
    return ""
  }
}

// dari string ISO untuk menjadi tanggal Date javascript
export function parseStringISO(s = "2021-06-28T04:13:54.000000Z") {
  var b = s.split(/\D+/);
  return (s === null || s === undefined) ? new Date() : new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}