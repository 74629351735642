import "assets/scss/style_custome.scss";
import { useLocation } from "react-router-dom";
import { Route, Routes } from "react-router";
import React, { Suspense, lazy, useEffect } from "react";
import useTakPerluNavbar from "elements/useTakPerluNavbar/useTakPerluNavbar";
import { useSelector } from "react-redux";
import GATracker from "utils/GATracker";
import useInitFacebook from "utils/useInitFacebook";
import useInitGoogle from "utils/useInitGoogle";
import useAlertKoinRegister from "utils/useAlertKoin";
import AlertCoinRegister from "elements/Alert/AlertCoinRegister/AlertCoinRegister";
import store from "store";
import { SET_GLOBAL_DATA } from "store/types";
import Header from "elements/NavbarBaru/Header/Header";
import LoadingLogin from "elements/Loading/LoadingLogin";

import PrakerjaPage from "pages/PrakerjaPage";
import "./App.scss";
import { PopupGeneral } from "elements/PopupGeneral/PopupGeneral";
import KontenEvaluasi from "parts/WatchCoursePage/WatchCourseKonten/komponen/KontenEvaluasi";
import KontenEvaluasiDone from "parts/WatchCoursePage/WatchCourseKonten/komponen/KontenEvaluasiDone";
import GeneratePage from "pages/GeneratePage";
import CoursePageWebinar from "parts/CourseDetailPage/CoursePageWebinar";
import { PopupGeneralV2 } from "elements/PopupGeneral/PopupGeneralV2";
const ClassPage = lazy(() => import("pages/ClassPage"));
const CoursePage = lazy(() => import("pages/CourseDetailPage"));
const CheckoutDonePage = lazy(() => import("pages/CheckoutDonePage"));
const PrakerjaPageNew = lazy(() => import("pages/PrakerjaNewPage"));
const CheckoutPage = lazy(() => import("pages/CheckoutPage"));
// SPL temp route redirect & callback
const RedirToCourse = lazy(() => import("pages/RedirToCourse"));
const RedirToPrakerja = lazy(() => import("pages/RedirToPrakerja"));
// end of SPL temp route redirect & callback
const RedeemPulsaPage = lazy(() => import("pages/RedeemPulsaPage"));
const WatchCoursePage = lazy(() => import("pages/WatchCoursePage"));
const WatchCourseSPLPage = lazy(() => import("pages/WatchCourseSPLPage"));
const FAQPage = lazy(() => import("pages/FAQPage"));
const CourseCategory = lazy(() => import("pages/CourseCategoryPage"));
const OnBoardingPrakerjaPage = lazy(() =>
  import("pages/OnBoardingPrakerjaPage")
);
const MyClassPage = lazy(() => import("pages/MyClassPage"));
const MyProfilePage = lazy(() => import("pages/MyProfilePage"));
const MyCertificatePage = lazy(() => import("pages/MyCertificatePage"));
const MyTransactionPage = lazy(() => import("pages/MyTransactionPage"));
const CertificatePage = lazy(() => import("pages/CertificatePage"));
const MyDashboardPage = lazy(() => import("pages/MyDashboardPage"));
const CourseDetailSpesial = lazy(() =>
  import("parts/SpesialisasiPage/DetailSpesialisasiPage/CourseDetailSpesial")
);
const PelatihanSpesialisasi = lazy(() =>
  import("pages/PelatihanSpesialiasaiPage")
);
const MarketplaceCoursePage = lazy(() => import("pages/MarketplaceCoursePage"));
const ResetPasswordPage = lazy(() => import("pages/ResetPasswordPage"));
const CreateNewPasswordPage = lazy(() => import("pages/CreateNewPasswordPage"));
const DetailSearch = lazy(() => import("pages/DetailSearchPage"));
const CertificateCheckPage = lazy(() => import("pages/CertificateCheckPage"));
const VoucherCheckPage = lazy(() => import("pages/VoucherCheckPage"));
const VerifiedVoucherPage = lazy(() =>
  import("parts/VoucherCheckPrakerjaPage/VerifiedVoucherPage/VerifiedVoucher")
);
const ChangeEmailPage = lazy(() => import("pages/ChangeEmailPage"));
const UlasanPage = lazy(() => import("pages/UlasanPage"));
const PaymentPage = lazy(() => import("pages/PaymentPage"));
const OrderReceivedPage = lazy(() => import("pages/OrderReceivedPage"));
const TentangPrakerja = lazy(() => import("pages/TentangPrakerjaPage"));
const TentangArkademiPage = lazy(() => import("pages/TentangArkademiPage"));
const ModalLogin = lazy(() => import("parts/ModalLog/ModalLogin/ModalLogin"));
const ModalDaftar = lazy(() =>
  import("parts/ModalLog/ModalDaftar/ModalDaftar")
);
const CertificateJobReadyCoursePage = lazy(() =>
  import("pages/CertificateJobReadyCoursePage")
);
const PenilaianPage = lazy(() => import("pages/PenilaianPage"));
const PenilaianSPLPage = lazy(() => import("pages/PenilaianSPLPage"));
const DashboardAdmin = lazy(() => import("pages/DashboardAdminPage"));
const CobaCanvas = lazy(() => import("parts/CobaCanvas/CobaCanvas"));
const BulkPage = lazy(() => import("parts/BulkPage/BulkPage"));
const CobaBulkPage = lazy(() => import("pages/CobaBulkGenSertifPage"));
const SingleGenerator = lazy(() => import("pages/SingleGenerator"));
const BulkNewPage = lazy(() => import("parts/BulkNewPage/BulkPage"));
const FAQNewPage = lazy(() => import("pages/FAQNewPage"));
const TestPage = lazy(() => import("pages/TestPage"));
const CertificateDownload = lazy(() => import("pages/CertificateDownloadPage"));
const VerifChangeEmail = lazy(() => import("pages/VerifChangeEmailPage"));
const PlayerPage = lazy(() => import("pages/PlayerPage"));
const GuidlinesPage = lazy(() => import("pages/GuidlinesPage"));
const MonitoringPage = lazy(() => import("pages/MonitoringPage"));
const PenunjangKarirPage = lazy(() => import("pages/PenunjangKarirPage"));

function App() {
  const user = useSelector((state) =>
    state.authorization ? state.authorization.user : null
  );
  const arkKoin = useSelector(
    (state) => state.authorization?.arkacoins?.data?.addedCoin
  );
  const loading = useSelector((state) => state.global?.loadingGoogle);
  const pathname = useLocation().pathname;
  // cek lokasi saat ini apakah perlu ada NAVBAR atau tidak
  const perluNavbar = useTakPerluNavbar([
    "lihat-course",
    "course-spl",
    "track-prakerja",
    "cek-prakerja-view",
    "generate-certificate",
    "verify",
    "dashboard-admin",
    "test",
    "monitoring-prakerja",
  ]);
  useInitFacebook();
  useInitGoogle();
  const coins = useAlertKoinRegister();
  return (
    <div className="App">
      {perluNavbar && <Header user={user} />}
      {arkKoin && <AlertCoinRegister isOpen={coins} />}
      <PopupGeneral />
      <PopupGeneralV2 />
      {loading && pathname !== "/masuk" && pathname !== "/daftar" ? (
        user !== null ? (
          store.dispatch({
            type: SET_GLOBAL_DATA,
            payload: { loadingGoogle: false },
          })
        ) : (
          <LoadingLogin />
        )
      ) : (
        ""
      )}
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route
            exact
            path="/"
            element={<GATracker WrappedComponent={PrakerjaPageNew} />}
          />
          <Route
            path="/kelas"
            element={<GATracker WrappedComponent={ClassPage} />}
          />
          <Route
            path="/course/:idCourse"
            element={<GATracker WrappedComponent={CoursePage} />}
          />
          <Route
            path="/webinar/:idCourse"
            element={<GATracker WrappedComponent={CoursePage} />}
          />
          <Route
            path="/kelas-prakerja/:nameMarketplace"
            element={<GATracker WrappedComponent={MarketplaceCoursePage} />}
          />
          {/* <Route
            path="/kelas-prakerja"
            element={<GATracker WrappedComponent={MarketplaceCoursePage} />}
          /> */}
          <Route
            path="/checkout"
            element={<GATracker WrappedComponent={CheckoutPage} />}
          />
          {/* SPL temp route redirect & callback */}
          <Route
            path="/callback"
            element={<GATracker WrappedComponent={RedirToCourse} />}
          />
          <Route
            path="/redir-prakerja/:slug"
            element={<GATracker WrappedComponent={RedirToPrakerja} />}
          />
          {/* end of SPL temp route redirect & callback */}
          <Route
            path="/track-prakerja"
            element={<GATracker WrappedComponent={CertificateCheckPage} />}
          />
          <Route
            path="/cek-prakerja-view/:codeVoucher"
            element={<GATracker WrappedComponent={CertificatePage} />}
          />
          <Route
            path="/cek-voucher"
            element={<GATracker WrappedComponent={VoucherCheckPage} />}
          />
          <Route
            path="/voucher-terverifikasi"
            element={<GATracker WrappedComponent={VerifiedVoucherPage} />}
          />
          <Route
            path="/checkout-selesai"
            element={<GATracker WrappedComponent={CheckoutDonePage} />}
          />
          <Route
            path="/payment/:idCourse/:idOrder/:methodPayment"
            element={<GATracker WrappedComponent={PaymentPage} />}
          />

          <Route
            path="/order-received"
            element={<GATracker WrappedComponent={OrderReceivedPage} />}
          />
          <Route
            path="/redeem-pulsa"
            element={<GATracker WrappedComponent={RedeemPulsaPage} />}
          />
          <Route
            path="/lihat-course/:idCourse"
            element={<GATracker WrappedComponent={WatchCoursePage} />}
          />
          <Route
            path="/course-spl/:idCourse"
            element={<GATracker WrappedComponent={WatchCourseSPLPage} />}
          />
          <Route
            path="/FAQ"
            element={<GATracker WrappedComponent={FAQNewPage} />}
          />
          <Route
            path="/kategori-course"
            exact
            element={<GATracker WrappedComponent={CourseCategory} />}
          />
          <Route
            path="/kategori-course/:idKategori"
            element={<GATracker WrappedComponent={CourseCategory} />}
          />
          <Route
            path="/cek-kupon"
            element={<GATracker WrappedComponent={OnBoardingPrakerjaPage} />}
          />

          <Route
            path="/dashboard/saya"
            element={<GATracker WrappedComponent={MyDashboardPage} />}
          />
          <Route
            path="/dashboard/profile"
            element={<GATracker WrappedComponent={MyProfilePage} />}
          />
          <Route
            path="/dashboard/pelatihan"
            element={<GATracker WrappedComponent={MyClassPage} />}
          />
          <Route
            path="/dashboard/sertifikat"
            element={<GATracker WrappedComponent={MyCertificatePage} />}
          />
          <Route
            path="/dashboard/transaksi"
            exact
            element={<GATracker WrappedComponent={MyTransactionPage} />}
          />
          {/* <Route
            path="/dashboard/transaksi/:idTransaction"
            element={<GATracker WrappedComponent={MyTransactionDetailPage} />}
          />
          <Route
            path="/dashboard/coins"
            element={<GATracker WrappedComponent={CoinsBalancePage} />}
          /> */}
          <Route
            path="/dashboard/pengaturan"
            element={<GATracker WrappedComponent={MyProfilePage} />}
          />
          <Route
            path="/reset-password"
            element={<GATracker WrappedComponent={ResetPasswordPage} />}
          />
          <Route
            path="/ganti-password"
            element={<GATracker WrappedComponent={CreateNewPasswordPage} />}
          />
          <Route
            path="/ganti-email"
            element={<GATracker WrappedComponent={ChangeEmailPage} />}
          />
          <Route
            path="/spesialisasi/:idCourse"
            element={<GATracker WrappedComponent={CourseDetailSpesial} />}
          />

          <Route
            path="/spesialisasi"
            element={<GATracker WrappedComponent={PelatihanSpesialisasi} />}
          />
          <Route
            path="/hasil-pencarian"
            element={<GATracker WrappedComponent={DetailSearch} />}
          />

          <Route
            path="/course-ulasan/:id"
            element={<GATracker WrappedComponent={UlasanPage} />}
          />
          <Route
            path="/masuk"
            element={<GATracker WrappedComponent={ModalLogin} />}
          />
          <Route
            path="/daftar"
            element={<GATracker WrappedComponent={ModalDaftar} />}
          />
          {/* <Route
            path="/brevet"
            element={<GATracker WrappedComponent={BrevetBaruPage} />}
          />
          <Route
            path="/job-ready"
            element={<GATracker WrappedComponent={JobReadyCoursePage} />}
          />
          <Route
            path="/tts"
            element={<GATracker WrappedComponent={TtsPage} />}
          /> */}
          {/* <Route
            path="/kupon-diskon"
            element={<GATracker WrappedComponent={KuponDiskonPage} />}
          /> */}
          <Route
            path="/tentang-prakerja"
            element={<GATracker WrappedComponent={TentangPrakerja} />}
          />

          <Route
            path="/tentang"
            element={<GATracker WrappedComponent={TentangArkademiPage} />}
          />
          <Route
            path="/generate-certificate"
            element={<GATracker WrappedComponent={GeneratePage} />}
          />
          <Route
            path="/coursepage"
            element={<GATracker WrappedComponent={CoursePageWebinar} />}
          />
          <Route
            path="/verify"
            element={
              <GATracker WrappedComponent={CertificateJobReadyCoursePage} />
            }
          />
          <Route
            path="/admin-penilaian"
            element={<GATracker WrappedComponent={PenilaianPage} />}
          />
          <Route
            path="/admin-penilaian-spl"
            element={<GATracker WrappedComponent={PenilaianSPLPage} />}
          />
          <Route
            path="/coba-canvas"
            element={<GATracker WrappedComponent={CobaCanvas} />}
          />
          <Route
            path="/bulk"
            element={<GATracker WrappedComponent={BulkPage} />}
          />
          <Route
            path="/dashboard-admin"
            element={<GATracker WrappedComponent={DashboardAdmin} />}
          />
          <Route
            path="/generate-sertif"
            element={<GATracker WrappedComponent={CobaBulkPage} />}
          />
          <Route
            path="/single-generator"
            element={<GATracker WrappedComponent={SingleGenerator} />}
          />
          <Route
            path="/bulk-new"
            element={<GATracker WrappedComponent={BulkNewPage} />}
          />
          <Route
            path="/faq-old"
            element={<GATracker WrappedComponent={FAQPage} />}
          />
          <Route
            path="/test"
            element={<GATracker WrappedComponent={TestPage} />}
          />
          <Route
            path="/certificate-download"
            element={<GATracker WrappedComponent={CertificateDownload} />}
          />
          <Route
            path="/verification-change-email"
            element={<GATracker WrappedComponent={VerifChangeEmail} />}
          />
          <Route
            path="/player"
            element={<GATracker WrappedComponent={PlayerPage} />}
          />
          <Route
            path="/panduan"
            element={<GATracker WrappedComponent={GuidlinesPage} />}
          />
          <Route
            path="/monitoring-prakerja"
            element={<GATracker WrappedComponent={MonitoringPage} />}
          />
          <Route
            path="/program-penunjang-karir-arkademi"
            element={<GATracker WrappedComponent={PenunjangKarirPage} />}
          />
          {/* <Route
            path="/course-brevet-terapan"
            element={<GATracker WrappedComponent={CoursePageBrevet} />}
          /> */}
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
