import "./KontenEvaluasi.scss";
import { FC, useEffect, useState } from "react";
import {
  useGetDataWatchCourse,
  useSetDataWatchCourse,
} from "utils/useStoreWatchCourse";
import { useFetchUmum, usePostUmumV2 } from "utils/useFetchUmum";
import KontenEvaluasiDoneNew from "../KontenEvaluasiDone/KontenEvaluasiDoneNew";
import useMarkAsComplete from "parts/WatchCoursePage/WatchCourseTabKurikulum/fungsi/useMarkAsComplete";
import { useSelector } from "react-redux";
import KontenEvaluasiDoneSpl from "../KontenEvaluasiDoneSpl/KontenEvaluasiDoneSpl";

const EvaluasiWrapper: FC<IEvaluasiWrapper> = ({}) => {
  const [loadingCompleteClass, setLoadingCompleteClass] = useState(false);
  const courseId = useGetDataWatchCourse("watchCourseIdKelas") || null;
  const courseTab = useGetDataWatchCourse("watchCourseTab") || null;

  const isSPL = useSelector(
    (state: any) => state?.globalWatchCourse?.watchCourseSPL
  );
  const completeUnitClass = useSelector(
    (state: any) => state?.globalWatchCourse?.watchCourseSelesaiSemua
  );

  const [dateEvaluasi] = useFetchUmum(
    "apicourse",
    `/api/v1/prakerja/user/coursestatus/${courseId}/item/${courseTab?.idItem}`
  );
  const [dataEvaluasi, loadingDataEvaluasi] = useFetchUmum(
    "apicourse",
    `/api/v1/prakerja/user/${courseId}/get-evaluasi`
  );
  const [dataEvaluasiSpl, loadingDataEvaluasiSpl] = useFetchUmum(
    "apicourse",
    `/api/v1/prakerja/course/feedback-v2/${courseId}/unjuk-keterampilan`
  );
  const [dataFeedbackTPM, loadingDataFeedbackTPM] = useFetchUmum(
    "apicourse",
    `/api/v1/prakerja/course/feedback-v2/${courseId}/tugas-praktik-mandiri/webinar`
  );
  const [postSelesaikanKelas] = usePostUmumV2(
    "apimember",
    `/api/v1/prakerja/user/postStatus/${courseId}`
  );
  const [markAsComplete] = useMarkAsComplete();
  const setDataEvaluasiSpl = useSetDataWatchCourse(
    "watchCourseDataFeedbackUkSpl"
  );

  const handleWebinar = async () => {
    setLoadingCompleteClass(true);
    if (!isSPL && dataEvaluasi?.data?.status == "Lulus") {
      await markAsComplete(courseId, courseTab?.idItem, false);
    }
    // else if (isSPL && dataEvaluasiSpl?.data != null) {
    //   await markAsComplete(courseId, courseTab?.idItem, false);
    // }
    setLoadingCompleteClass(false);
  };

  const handleCompleteClassWebinar = async () => {
    setLoadingCompleteClass(true);
    await postSelesaikanKelas({});
    setLoadingCompleteClass(false);
  };

  useEffect(() => {
    if (!isSPL && completeUnitClass) {
      handleCompleteClassWebinar();
    }
  }, [isSPL, completeUnitClass]);

  useEffect(() => {
    if (!courseTab?.selesai) {
      if (!isSPL && dataEvaluasi) {
        handleWebinar();
      } else if (isSPL && dataEvaluasiSpl) {
        handleWebinar();
      }
    }
    if (dataEvaluasiSpl?.data != null) {
      setDataEvaluasiSpl(dataEvaluasiSpl?.data);
    } else {
      setDataEvaluasiSpl(null);
    }
  }, [courseTab, dataEvaluasi, dataEvaluasiSpl, isSPL]);

  return (
    <>
      {!loadingCompleteClass && (
        <>
          {/* default */}
          {!loadingDataEvaluasi &&
            dataEvaluasi?.data?.status != "Tidak Lulus" && (
              <KontenEvaluasi dateEvaluasi={dateEvaluasi?.data} isSPL={isSPL} />
            )}

          {/* lulus */}
          {!loadingDataEvaluasi &&
            dataEvaluasi?.data?.status == "Lulus" &&
            !isSPL && (
              <KontenEvaluasiDoneNew
                dataEvaluasi={dataEvaluasi?.data}
                dataEvaluasiUK={dataEvaluasiSpl}
                dataFeedbackTPM={dataFeedbackTPM}
              />
            )}
          {/* {!loadingDataEvaluasi && dataEvaluasiSpl?.data != null && isSPL && ( */}
          {!loadingDataEvaluasi && isSPL && (
            <KontenEvaluasiDoneSpl dataEvaluasiSpl={dataEvaluasiSpl?.data} />
          )}

          {/* tidak lulus webinar */}
          {!loadingDataEvaluasi &&
            dataEvaluasi?.data?.status == "Tidak Lulus" &&
            !isSPL && <KontenEvaluasiTidakLulus />}
        </>
      )}
    </>
  );
};

const KontenEvaluasi: FC<IKontenEvaluasi> = ({ dateEvaluasi, isSPL }) => {
  return isSPL ? (
    <div className="konten-evaluasi">
      <h2 className="judul-informasi">Umpan Balik Unjuk Keterampilan</h2>
      <p className="subtitle">Informasi</p>
      <ul className="ul-informasi">
        <li>
          Informasi Hasil penilaian Tugas Unjuk Keterampilan akan keluar 7x24
          jam setelah Unjuk Keterampilan dikumpulkan.
        </li>
        <li>
          Muat ulang dan cek secara berkala halaman ini untuk menampilkan hasil
          Umpan Balik Unjuk Keterampilan.
        </li>
        <li>
          Peserta akan mendapatkan sertifikat setelah feedback muncul di halaman
          ini dan mengklik tombol 'Selesaikan Pelatihan'
        </li>
      </ul>
      <button
        className="btn-refresh"
        onClick={() => {
          window.location.reload();
        }}
      >
        Muat Ulang
      </button>
    </div>
  ) : (
    <div className="konten-evaluasi">
      <h2 className="judul-informasi">Informasi</h2>
      <ol className="ol-informasi">
        <li>
          <b> Hasil penilaian Unjuk Keterampilan</b> akan keluar pada{" "}
          <b>2 x 24 jam</b>.
        </li>
        <li>
          Muat ulang halaman ini untuk menampilkan hasil unjuk keterampilan.
        </li>
        {/* <li>
          Untuk <b>mendapatkan Sertifikat Penyelesaian</b>, kamu harus memenuhi{" "}
          <b>syarat maksimal ketidakhadiran 1 kali sesi webinar</b>.
        </li> */}
      </ol>
      <button
        className="btn-refresh"
        onClick={() => {
          window.location.reload();
        }}
      >
        Muat Ulang
      </button>
    </div>
  );
};

const KontenEvaluasiTidakLulus: FC<IKontenEvaluasiTidakLulus> = ({}) => {
  return (
    <div className="konten-evaluasi tidak-lulus">
      <h2 className="title">Mohon maaf...</h2>
      <p className="description">
        Kamu dinyatakan <b>TIDAK LULUS</b> karena telah melewati lebih dari{" "}
        <b>1 kali</b> sesi webinar
      </p>
      <p className="link">
        Punya pertanyaan? Hubungi{" "}
        <span
          onClick={() => window.open("https://wa.me/6285172280918", "_blank")}
        >
          di sini
        </span>
      </p>
    </div>
  );
};

interface IEvaluasiWrapper {}

interface IKontenEvaluasi {
  dateEvaluasi: any;
  isSPL: boolean;
}
interface IKontenEvaluasiTidakLulus {}
interface IKontenEvaluasiLulus {}

export default EvaluasiWrapper;
