import { ClipLoader } from "react-spinners";
import './Loading.scss'


export default function Loading({ full = true }) {
  return (
    <div className='loading-apl-arkademi' style={full ? {} : { width: "fit-content" }}>
      <ClipLoader color='#0977BE' />
    </div>
  )
}