import React, { useState, useEffect } from "react";

import { AiOutlineMenu } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdClose,
} from "react-icons/md";
// import LogoPrakerjaNew from "../gbr/logo-prakerja.png";
// import LogoArkademiNew from "../gbr/logo-arkademi.png";
import LogoPrakerjaNew from "./logo-prakerja-arkademi.svg";
import LogoArkademiNew from "./logo_arkademi.svg";

import { useNavigate } from "react-router";

import "./header.scss";
import DropdownProfile from "elements/Dropdown/DropdownProfile/DropdownProfile";
import ConfirmExit from "elements/Modal/ConfirmExit/ConfirmExit";
import SearchResponsive from "./Search/SearchResponsive";
import useScrollUpOrDown from "utils/useScrollUpOrDown";
import Search from "parts/Header/Search";
import { Link } from "react-router-dom";
import { useUserId } from "utils/useUser";

export default function Header({ user = null }) {
  const [bukaSearch, setBukaSearch] = useState(false);

  const [onScroll, setOnScroll] = useState(false);
  const [showNavbarResponsive, setshowNavbarResponsive] = useState(false);
  const userID = useUserId();

  const navigate = useNavigate();

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 16) {
        setOnScroll(true);
      } else {
        setOnScroll(false);
      }
    };
    window.addEventListener("scroll", changeBackground);
  });

  const handleToggleMenu = (e) => {
    if (e == "open") {
      setshowNavbarResponsive(true);
    } else {
      setshowNavbarResponsive(false);
    }
  };

  const [bukaExit, setBukaExit] = useState(false);

  const openLogout = (value) => {
    setBukaExit(value);
  };

  const openSearch = (value) => {
    setBukaSearch(value);
  };

  const direksiScroll = useScrollUpOrDown();
  const pathname = window.location.pathname;

  return (
    <>
      {bukaExit && <ConfirmExit isOpen={setBukaExit} />}
      {bukaSearch && <SearchResponsive isOpen={setBukaSearch} />}
      <div className={"navbar-wrapper"}>
        <div className="navbar-container container-custom">
          <div
            className="menu-toggle"
            onClick={(e) => handleToggleMenu("open")}
          >
            <AiOutlineMenu className="icon-menu-toggle" />
          </div>
          <div className="brand-wrapper">
            <div className="logo-brand" onClick={() => navigate("/")}>
              <img src={LogoArkademiNew} alt="arkademi" />
            </div>
            <div className="logo-prakerja" onClick={() => navigate("/")}>
              <img src={LogoPrakerjaNew} alt="prakerja" />
            </div>
          </div>
          <div className="navmenu-wrapper">
            <div className="category-and-search">
              {/* <div className="category">
                <div className="parent-item-category">
                  <p className="parent-category">Kategori</p>
                  <MdOutlineKeyboardArrowDown className="arrow arrow-down" />
                </div>
                <ul className="drop-category">
                  <Link to="/kategori-course/bisnis" className="item-category">
                    Bisnis
                  </Link>
                  <Link to="/kategori-course/bahasa" className="item-category">
                    Bahasa
                  </Link>
                  <Link
                    to="/kategori-course/teknologi"
                    className="item-category"
                  >
                    Teknologi
                  </Link>
                  <Link
                    to="/kategori-course/pengembangan-karier"
                    className="item-category"
                  >
                    Pengembangan Karier
                  </Link>
                </ul>
              </div> */}
              <div className="search-form">
                {pathname != "/admin-penilaian" && <Search />}
                {/* <input
                  type="text"
                  className="input-form"
                  placeholder="Cari pelatihan Prakerja"
                />
                <FiSearch className="icon-search" /> */}
              </div>
              <div className="search-toggle">
                <FiSearch
                  className="icon-search-toggle"
                  onClick={() => openSearch(true)}
                />
              </div>
            </div>
            <div className="menu-and-button">
              <div className="menu-list">
                <div
                  className="item-menu"
                  onClick={() => navigate("/panduan?tab=informasi-prakerja")}
                  id="wb_prakerja_informasi"
                >
                  Informasi Prakerja
                </div>
                {/* <div className="item-menu">Informasi Prakerja</div> */}
                <div
                  className="item-menu"
                  onClick={() => navigate("/panduan?tab=faq")}
                  id="wb_prakerja_faq"
                >
                  FAQ
                </div>
              </div>
              {user === null || user === undefined ? (
                <>
                  <div className="button-groub">
                    <button
                      className="btn-cta"
                      id="button-login"
                      onClick={() => navigate("/masuk")}
                    >
                      Masuk
                    </button>
                    <button
                      className="btn-cta"
                      id="button-register"
                      onClick={() => navigate("/daftar")}
                    >
                      Daftar
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <DropdownProfile user={user} openLogout={openLogout} />
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className={
            showNavbarResponsive
              ? "background-navbar-responsive show"
              : "background-navbar-responsive"
          }
        />
        <div
          className={
            showNavbarResponsive
              ? "navbar-responsive-wrapper show"
              : "navbar-responsive-wrapper"
          }
        >
          <div
            className="menu-toggle"
            onClick={(e) => handleToggleMenu("close")}
          >
            <MdClose className="icon-menu-toggle" />
          </div>
          {userID === null && (
            <div className="button-groub">
              <button
                className="btn-cta"
                id="button-login-mobile"
                onClick={() => navigate("/masuk")}
              >
                Masuk
              </button>
              <button
                className="btn-cta"
                id="button-register-mobile"
                onClick={() => navigate("/daftar")}
              >
                Daftar
              </button>
            </div>
          )}
          <div className="category">
            <h4 className="label-category">KATEGORI PELATIHAN</h4>
            <ul className="list-category">
              <Link
                to="/kategori-course/bisnis"
                className="item-category"
                onClick={(e) => handleToggleMenu("close")}
              >
                Bisnis
              </Link>
              <Link
                to="/kategori-course/bahasa"
                className="item-category"
                onClick={(e) => handleToggleMenu("close")}
              >
                Bahasa
              </Link>
              <Link
                to="/kategori-course/teknologi"
                className="item-category"
                onClick={(e) => handleToggleMenu("close")}
              >
                Teknologi
              </Link>
              <Link
                to="/kategori-course/pengembangan-karier"
                className="item-category"
                onClick={(e) => handleToggleMenu("close")}
              >
                Pengembangan Karier
              </Link>
            </ul>
          </div>
          <div className="menu">
            <ul className="list-menu">
              <li
                className="item-menu"
                onClick={(e) => {
                  navigate("/panduan?tab=informasi-prakerja");
                  handleToggleMenu("close");
                }}
              >
                Informasi Prakerja
              </li>
              <li
                className="item-menu"
                onClick={(e) => {
                  navigate("/panduan?tab=faq");
                  handleToggleMenu("close");
                }}
              >
                FAQ
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
