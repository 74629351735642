import Button from "elements/Button";
import React, { Component } from "react";

import "./index.scss";

export default class FlowCoursePrakerja extends Component {
  render() {
    return (
      <section>
        <div className="container">
          <div className="flow-course-prakerja">
            <h4>Cara Mengikuti Kursus Prakerja di Arkademi</h4>
            <div className="grid-flow-prakerja">
              <div className="grid-cards">
                <div className="number-flow-prakerja">
                  <span className="number1-flow">01</span>
                  <h4>Beli Kupon</h4>
                  <p>
                    Beli dan dapatkan kupon kursus Prakerja Arkademi di
                    Tokopedia. Kupon Arkademi selalu berawalan TP.
                  </p>
                  <span>Beli Kupon:</span>
                  <div className="partner-buy-course">
                    <img
                      src={`https://arkademi.com/wp-content/themes/wplms/halaman/about-page/images/prak.png`}
                      alt="image partner course"
                      className="img-buy-course"
                    />
                    <img
                      src={`https://arkademi.com/wp-content/themes/wplms/halaman/about-page/images/prak.png`}
                      alt="image partner course"
                      className="img-buy-course"
                    />
                    <img
                      src={`https://arkademi.com/wp-content/themes/wplms/halaman/about-page/images/prak.png`}
                      alt="image partner course"
                      className="img-buy-course"
                    />
                  </div>
                </div>
              </div>
              <div className="grid-cards">
                <div className="number-flow-prakerja">
                  <span className="number2-flow">02</span>
                  <h4>Buat Akun</h4>
                  <p>
                    Buat akun di Arkademi. Pastikan anda mengisi nama dengan
                    benar dan lengkap untuk tampil di sertifikat.
                  </p>
                  <div className="join-buy-course">
                    <Button className="btn px-5" isPrimary>
                      Daftar
                    </Button>
                  </div>
                </div>
              </div>
              <div className="grid-cards">
                <div className="number-flow-prakerja">
                  <span className="number3-flow">03</span>
                  <h4>Cari Kelasnya</h4>
                  <p>
                    Cari kursus yang anda beli kuponnya di Tokopedia pada kelas
                    kategori Prakerja di Arkademi.
                  </p>

                  <div className="join-buy-course">
                    <Button className="btn px-5" isPrimary>
                      Cari Kelas
                    </Button>
                  </div>
                </div>
              </div>
              <div className="grid-cards">
                <div className="number-flow-prakerja">
                  <span className="number2-flow">04</span>
                  <h4>Order Pakai Kupon</h4>
                  <p>
                    Lakukan order di kelas tersebut. Masukkan kode kupon.
                    Tagihan akan menjadi 0. Tekan BAYAR.
                  </p>
                </div>
              </div>
              <div className="grid-cards">
                <div className="number-flow-prakerja">
                  <span className="number3-flow">05</span>
                  <h4>Mulai Belajar</h4>
                  <p>
                    Masuk ke kelas dan mulai belajar. Saksikan video ajar sampai
                    tuntas dan kerjakan kuis.
                  </p>
                </div>
              </div>
              <div className="grid-cards">
                <div className="number-flow-prakerja">
                  <span className="number1-flow">06</span>
                  <h4>Dapat Sertifikat</h4>
                  <p>
                    Selesaikan kursus dan kuis. Dapatkan sertifikat elektronik.
                    Arkademi kirimkan ke PMO max 2 hari kerja.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
