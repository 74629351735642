import "./Bintang.scss";
import BintangIsi from "./gbr/bintang-isi.svg";
import BintangKosong from "./gbr/bintang-kosong.svg";
import PropTypes from "prop-types";
import { generateFilterWarna } from "utils/warna";

//input: tinggi bintang dalam px
//input: nilai dari 1 hingga 5
//semisal nilai 2.5 maka bintang ke 3 akan separo bintang kuning, separo bintang kosong
//semisal nilai 3.2 maka bintang ke 4 akan 1/5 bintang kuning, 4/5 bintang kosong

export default function Bintang({
  tinggiBintang = 12,
  nilai = 5,
  warnaBintang = "#FFBB18",
}) {
  const filterWarna = generateFilterWarna(warnaBintang);
  const bintang = [];
  const nilIndeks = nilai - 1;
  for (let i = 0; i <= 4; i++) {
    bintang.push(
      <IsiBintang
        desimal={nilai - i < 1 && nilai - i > 0}
        nilaiDesimal={nilai - i}
        benar={i <= nilIndeks}
        tinggiBintang={tinggiBintang}
        filterWarna={filterWarna}
        key={i}
      />
    );
  }

  return (
    <div className="kontainer-rating-bintang">
      {bintang.map((nilai) => {
        return nilai;
      })}
    </div>
  );
}

function IsiBintang({
  desimal = false,
  nilaiDesimal = 0.0,
  benar = true,
  tinggiBintang = 12,
  filterWarna,
}) {
  const tinggiString = tinggiBintang + "px";
  const widthDesimal = tinggiBintang * nilaiDesimal + "px";
  const heightDesimal = tinggiBintang * 0.94 + "px";
  const marginTop = tinggiBintang * 0.03 + "px";
  return !desimal ? (
    <img
      src={benar ? BintangIsi : BintangKosong}
      style={{
        height: tinggiString,
        width: tinggiString,
        filter: benar ? filterWarna : undefined,
      }}
      alt=""
      loading="lazy"
    />
  ) : (
    <div
      className="bintang-separo-terisi"
      style={{ height: tinggiString, width: tinggiString }}
    >
      <img
        className="bintang-kosong"
        src={BintangKosong}
        style={{
          height: heightDesimal,
          width: tinggiString,
          marginTop: marginTop,
        }}
        alt=""
        loading="lazy"
      />
      <img
        className="bintang-isi"
        src={BintangIsi}
        style={{
          height: heightDesimal,
          width: widthDesimal,
          marginTop: marginTop,
          filter: filterWarna,
        }}
        alt=""
        loading="lazy"
      />
    </div>
  );
}

Bintang.defaultProps = {
  tinggiBintang: 12,
  nilai: 5,
};

Bintang.propTypes = {
  tinggiBintang: PropTypes.number.isRequired,
  nilai: PropTypes.number,
};
