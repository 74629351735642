export const formatText = (e) => {
  if (e != null) {
    if (e.length > 25) {
      const text = e.slice(0, 5) + "..." + e.slice(-3);
      return text;
    } else {
      return e;
    }
  }
};
