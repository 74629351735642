import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { fetchListSearch } from "store/actions/search";

/**fetch kategori kelas */
const useFetchListSearch = () => {
  const searchCache = useSelector((state) =>
    state.page && state.page.searchList ? state.page.searchList : null
  );
  const [loading, setLoading] = useState(false);
  const [searchKelas, setSearchKelas] = useState([]); // useState([dummy]);

  useEffect(() => {
    if (searchCache === null) {
      setLoading(true);
      fetchListSearch(setSearchKelas, `searchList`).finally(() => {
        setLoading(false);
      });
    } else {
      setSearchKelas(searchCache);
    }
  }, [searchCache]);

  return [searchKelas, loading];
};

export default useFetchListSearch;
