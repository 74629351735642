import axios, { CancelTokenSource } from "axios";
import { MutableRefObject, Ref, useCallback, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourseMicroservices } from "store/actions/course";
import { setGlobalData } from "store/actions/global";
import { fetchDummy, fetchUmum, postUmum } from "store/actions/globalCache";
import {
  IResponseFetch,
  IResponsePost,
  TFetchTrigger,
  THasilFetch,
  THasilPost,
  TJenisAPI,
} from "type/Fetch";
import useToken from "utils/useToken";
import useCekDalamLayar from "./useCekDalamLayar";

const api_course_url = process.env.REACT_APP_API_BACKEND_COURSE;
const api_member_url = process.env.REACT_APP_API_BACKEND_MEMBER;
const api_orders_url = process.env.REACT_APP_API_BACKEND_ORDERS;
const api_firebase_url = process.env.REACT_APP_API_FIREBASE;
const api_express = process.env.REACT_APP_PRODUCTION_REACT;

/**
 * Fetch link apapun
 *  @param link - contoh: "infoKelasNew.idKelas ? \`/v2/get-kupon/${infoKelasNew.idKelas}\` : null"
 */
export function useFetchUmum<T = any>(
  jenisApi: TJenisAPI,
  link: string | null,
  refKomponen?: MutableRefObject<any>,
  denganToken = true
): THasilFetch<T> {
  const dalamLayar = useCekDalamLayar(refKomponen);
  const [dataJSON, setDataJSON] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [token] = useToken();
  const [error, setEror] = useState({
    error: false,
    message: "",
    responseCode: -1,
    data: null,
  });

  const reqSedangGetSebelumnya = useRef<CancelTokenSource | null>(null);
  const linkSebelumnya = useRef<string | null>(null);

  useEffect(() => {
    const apiTerpilih = cekAPI(jenisApi);
    const benarDalamLayar = refKomponen === undefined || dalamLayar;
    const linkKosong = link === null || link === undefined;
    const linkTidakSama = linkSebelumnya.current !== link;

    const dataKosong = linkTidakSama || dataJSON === null;
    if (!benarDalamLayar || linkKosong || !dataKosong) return;
    const reqMicroservices = axios.CancelToken.source();

    reqSedangGetSebelumnya.current?.cancel();
    let cancelFetch = false;
    const fetch = async () => {
      setLoading(true);
      reqSedangGetSebelumnya.current = reqMicroservices;

      const hasilFetch = await fetchUmum(
        apiTerpilih,
        link,
        denganToken,
        token,
        reqMicroservices
      );
      if (cancelFetch) {
        setLoading(false);
        return;
      }
      if (hasilFetch.success && hasilFetch.data) {
        setDataJSON(hasilFetch.data);
      } else {
        setEror({
          error: true,
          message: hasilFetch.message,
          data: hasilFetch.data,
          responseCode: hasilFetch.responseCode,
        });
        setDataJSON(null);
      }
      linkSebelumnya.current = link;
      setLoading(false);
    };
    fetch();
    return () => {
      reqMicroservices.cancel();
      cancelFetch = true;
    };
  }, [jenisApi, link, dalamLayar, token]);

  return [dataJSON, loading, error];
}

/**
 * dummy fetch
 *  @param response - isi dari response yang disimulasikan"
 */
export function useFetchUmumDummy<T = any>(
  jenisApi: TJenisAPI,
  response: IResponseFetch<T> | null,
  refKomponen?: MutableRefObject<any>,
  denganToken = true
): THasilFetch<T> {
  const dalamLayar = useCekDalamLayar(refKomponen);
  const [dataJSON, setDataJSON] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [token] = useToken();
  const [error, setEror] = useState({
    error: false,
    responseCode: -1,
    message: "",
    data: null,
  });

  const reqSedangGetSebelumnya = useRef<CancelTokenSource | null>(null);
  const dataSebelumnya = useRef<T | null>(null);

  useEffect(() => {
    const apiTerpilih = cekAPI(jenisApi);
    const benarDalamLayar = refKomponen === undefined || dalamLayar;
    const linkKosong = response === null || response === undefined;
    const linkTidakSama = dataSebelumnya.current !== response;

    const dataKosong = linkTidakSama || dataJSON === null;
    if (linkKosong) console.error("data masih kosong");
    if (!benarDalamLayar || linkKosong || !dataKosong) return;
    const reqMicroservices = axios.CancelToken.source();

    reqSedangGetSebelumnya.current?.cancel();
    let cancelFetch = false;
    const fetch = async () => {
      setLoading(true);
      reqSedangGetSebelumnya.current = reqMicroservices;

      const hasilFetch = await fetchDummy(
        apiTerpilih,
        response,
        denganToken,
        token,
        reqMicroservices
      );
      if (cancelFetch) {
        setLoading(false);
        return;
      }
      if (hasilFetch.success && hasilFetch.data) {
        setDataJSON(hasilFetch.data);
      } else {
        setEror({
          error: true,
          data: hasilFetch.data,
          message: hasilFetch.message,
          responseCode: hasilFetch.responseCode,
        });
        setDataJSON(null);
      }
      dataSebelumnya.current = response.data;
      setLoading(false);
    };
    fetch();
    return () => {
      reqMicroservices.cancel();
      cancelFetch = true;
    };
  }, [jenisApi, response, dalamLayar, token]);

  return [dataJSON, loading, error];
}

/**
 * Fetch link apapun
 *  @param link - contoh: infoKelasNew.idKelas ? \`/v2/get-kupon/${infoKelasNew.idKelas}\` : null
 */
export function useFetchTrigger<T = any>(
  jenisApi: TJenisAPI,
  link: string | null,
  denganToken = true
): TFetchTrigger<T> {
  const [loading, setLoading] = useState(true);
  const [token] = useToken();
  const [error, setEror] = useState({
    error: false,
    responseCode: -1,
    data: null,
    message: "",
  });

  const cancelTokenSebelumnya = useRef<CancelTokenSource | null>(null);
  const linkSebelumnya = useRef<string | null>(null);
  let cancelFetch = false;
  const cancelToken = axios.CancelToken.source();

  const fetch = async () => {
    const apiTerpilih = cekAPI(jenisApi);
    const linkKosong = link === null || link === undefined;
    if (linkKosong) return null;

    cancelTokenSebelumnya.current?.cancel();
    const fetchData = async () => {
      setLoading(true);
      cancelTokenSebelumnya.current = cancelToken;

      const hasilFetch = await fetchUmum(
        apiTerpilih,
        link,
        denganToken,
        token,
        cancelToken
      );
      if (cancelFetch) {
        setLoading(false);
        return null;
      }
      if (hasilFetch.success && hasilFetch.data) {
        return hasilFetch.data as T;
      }
      setEror({
        error: true,
        data: hasilFetch.data,
        responseCode: hasilFetch.responseCode,
        message: "",
      });
      linkSebelumnya.current = link;
      setLoading(false);
      return null;
    };
    const hasilFetch = await fetchData();
    setLoading(false);
    return hasilFetch;
  };

  return [fetch, loading, error];
}

/**
 * Fetch link apapun
 *  @param link - contoh: infoKelasNew.idKelas ? \`/v2/get-kupon/${infoKelasNew.idKelas}\` : null
 */
export function useFetchTriggerWidthToken<T = any>(
  jenisApi: TJenisAPI,
  link: string | null,
  tokenUser: string | null,
  denganToken = true
): TFetchTrigger<T> {
  const [loading, setLoading] = useState(true);
  const [error, setEror] = useState({
    error: false,
    responseCode: -1,
    data: null,
    message: "",
  });

  const cancelTokenSebelumnya = useRef<CancelTokenSource | null>(null);
  const linkSebelumnya = useRef<string | null>(null);
  let cancelFetch = false;
  const cancelToken = axios.CancelToken.source();

  const fetch = async () => {
    const apiTerpilih = cekAPI(jenisApi);
    const linkKosong = link === null || link === undefined;
    if (linkKosong) return null;

    cancelTokenSebelumnya.current?.cancel();
    const fetchData = async () => {
      setLoading(true);
      cancelTokenSebelumnya.current = cancelToken;

      const hasilFetch = await fetchUmum(
        apiTerpilih,
        link,
        denganToken,
        tokenUser,
        cancelToken
      );
      if (cancelFetch) {
        setLoading(false);
        return null;
      }
      if (hasilFetch.success && hasilFetch.data) {
        return hasilFetch.data as T;
      }
      setEror({
        error: true,
        data: hasilFetch.data,
        responseCode: hasilFetch.responseCode,
        message: "",
      });
      linkSebelumnya.current = link;
      setLoading(false);
      return null;
    };
    const hasilFetch = await fetchData();
    setLoading(false);
    return hasilFetch;
  };

  return [fetch, loading, error];
}

/**
 * Fetch link apapun dengan trigger pergantian url
 *  @param link - contoh: infoKelasNew.idKelas ? \`/v2/get-kupon/${infoKelasNew.idKelas}\` : null
 */
export function useFetchTriggerV2({
  jenisApi,
  link,
  denganToken = true,
  instant = false,
}: IUseFetchTriggerV2) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any | null>();
  const [token] = useToken();
  const [error, setEror] = useState({
    error: false,
    responseCode: -1,
    data: null,
    message: "",
  });

  const cancelTokenSebelumnya = useRef<CancelTokenSource | null>(null);
  const linkSebelumnya = useRef<string | null>(null);
  let cancelFetch = false;
  const cancelToken = axios.CancelToken.source();

  const fetch = async ({ tokenCadangan, urlCadangan }: IFetchV2) => {
    const apiTerpilih = cekAPI(jenisApi);
    const linkKosong = link === null || link === undefined;
    if (linkKosong) return null;

    cancelTokenSebelumnya.current?.cancel();
    const fetchData = async () => {
      setLoading(true);
      cancelTokenSebelumnya.current = cancelToken;

      const hasilFetch = await fetchUmum(
        apiTerpilih,
        urlCadangan ? link + urlCadangan : link,
        denganToken,
        token ?? tokenCadangan,
        cancelToken
      );

      if (cancelFetch) {
        setLoading(false);
        return null;
      }
      if (hasilFetch.success && hasilFetch.data) {
        setLoading(false);
        return hasilFetch.data;
      }
      setEror({
        error: true,
        data: hasilFetch.data,
        responseCode: hasilFetch.responseCode,
        message: "",
      });
      linkSebelumnya.current = link;
      setLoading(false);
      return null;
    };
    const hasilFetch = await fetchData();
    setData(hasilFetch);
    // return hasilFetch;
  };

  useEffect(() => {
    if (instant) {
      fetch({});
    }
  }, []);

  return { fetch, data, loading, error };
}

interface IUseFetchTriggerV2 {
  jenisApi: TJenisAPI;
  link?: string | null;
  denganToken?: boolean;
  instant?: boolean;
}

interface IFetchV2 {
  tokenCadangan?: string;
  urlCadangan?: string;
}

/**
 * @deprecated gunakan V2
 * Fetch link apapun contoh penggunaan:
 *
 * const [postApiKupon, loading] = usePostUmum("apifirebase", infoKelasNew.idKelas ? \`/v2/get-kupon/${infoKelasNew.idKelas}\` : null)
 *
 * \<Button onClick={() => postApiKupon({body: "isi body"})}/>
 *  @param link - contoh: infoKelasNew.idKelas ? \`/v2/get-kupon/${infoKelasNew.idKelas}\` : null
 */
export function usePostUmum<T = any>(
  jenisApi: TJenisAPI,
  link: string | null,
  denganToken = true
): THasilPost<T> {
  const [loading, setLoading] = useState(true);
  const [token] = useToken();

  const cancelTokenSebelumnya = useRef<CancelTokenSource | null>(null);
  const cancelToken = useRef(axios.CancelToken.source());

  const linkSebelumnya = useRef<string | null>(null);

  const post = useCallback(
    async (dataPost: any) => {
      const batalkan = {
        success: false,
        message: "link kosong",
        data: null,
        postedData: dataPost,
        responseCode: 499,
      } as IResponsePost<any>;
      const apiTerpilih = cekAPI(jenisApi);
      const linkKosong = link === null || link === undefined;
      if (linkKosong) return batalkan;
      setLoading(true);
      cancelTokenSebelumnya.current?.cancel();
      cancelTokenSebelumnya.current = cancelToken.current;
      cancelToken.current = axios.CancelToken.source();
      const hasilFetch = await postUmum(
        apiTerpilih,
        dataPost,
        link,
        denganToken,
        token,
        cancelToken.current
      );
      linkSebelumnya.current = link;
      setLoading(false);
      return hasilFetch;
    },
    [jenisApi, link, token]
  );

  return [post, loading, cancelToken.current];
}

/**
 * Fetch link apapun contoh penggunaan:
 *
 * const [postApiKupon, loading] = usePostUmum("apifirebase", infoKelasNew.idKelas ? \`/v2/get-kupon/${infoKelasNew.idKelas}\` : null)
 *
 * \<Button onClick={() => postApiKupon({body: "isi body"})}/>
 *  @param link - contoh: infoKelasNew.idKelas ? \`/v2/get-kupon/${infoKelasNew.idKelas}\` : null
 */
export function usePostUmumV2<T = any>(
  jenisApi: TJenisAPI,
  link: string | null,
  denganToken = true
): THasilPost<T> {
  const [loading, setLoading] = useState(true);
  const [token] = useToken();

  const cancelTokenSebelumnya = useRef<CancelTokenSource | null>(null);
  const cancelToken = useRef(axios.CancelToken.source());

  const linkSebelumnya = useRef<string | null>(null);

  const post = useCallback(
    async (dataPost: any) => {
      const batalkan = {
        success: false,
        message: "link kosong",
        data: null,
        postedData: dataPost,
        responseCode: 499,
      } as IResponsePost<any>;
      const apiTerpilih = cekAPI(jenisApi);
      const linkKosong = link === null || link === undefined;
      if (linkKosong) return batalkan;
      setLoading(true);
      cancelTokenSebelumnya.current?.cancel();
      cancelTokenSebelumnya.current = cancelToken.current;
      cancelToken.current = axios.CancelToken.source();
      const hasilFetch = await postUmum(
        apiTerpilih,
        dataPost,
        link,
        denganToken,
        token,
        cancelToken.current
      );
      linkSebelumnya.current = link;
      setLoading(false);
      return hasilFetch.data;
    },
    [jenisApi, link, token]
  );

  return [post, loading, cancelToken.current];
}

/**
 * Fetch link apapun contoh penggunaan:
 *
 * const [postApiKupon, loading] = usePostUmum("apifirebase", infoKelasNew.idKelas ? \`/v2/get-kupon/${infoKelasNew.idKelas}\` : null)
 *
 * \<Button onClick={() => postApiKupon({body: "isi body"})}/>
 *  @param link - contoh: infoKelasNew.idKelas ? \`/v2/get-kupon/${infoKelasNew.idKelas}\` : null
 */
export function usePostUmumWithToken<T = any>(
  jenisApi: TJenisAPI,
  link: string | null,
  tokenUser: string | null,
  denganToken = true
): THasilPost<T> {
  const [loading, setLoading] = useState(true);

  const cancelTokenSebelumnya = useRef<CancelTokenSource | null>(null);
  const cancelToken = useRef(axios.CancelToken.source());

  const linkSebelumnya = useRef<string | null>(null);

  const post = useCallback(
    async (dataPost: any) => {
      const batalkan = {
        success: false,
        message: "link kosong",
        data: null,
        postedData: dataPost,
        responseCode: 499,
      } as IResponsePost<any>;
      const apiTerpilih = cekAPI(jenisApi);
      const linkKosong = link === null || link === undefined;
      if (linkKosong) return batalkan;
      setLoading(true);
      cancelTokenSebelumnya.current?.cancel();
      cancelTokenSebelumnya.current = cancelToken.current;
      cancelToken.current = axios.CancelToken.source();
      const hasilFetch = await postUmum(
        apiTerpilih,
        dataPost,
        link,
        denganToken,
        tokenUser,
        cancelToken.current
      );
      linkSebelumnya.current = link;
      setLoading(false);
      return hasilFetch.data;
    },
    [jenisApi, link, tokenUser]
  );

  return [post, loading, cancelToken.current];
}

/**
 *
 * 2 fungsi: simpan data reaktif, atau simpan data dengan fungsi, contoh 2 penggunaan:
 *
 * const [coba, setData] = useState("")\
 * useSimpanDataWatchCourse<IData>("dataPertama", coba)\
 * <Button onClick={() => setData("data lain")}
 *
 * atau
 *
 * const simpanData = useSimpanDataWatchCourse<IData>("dataPertama")\
 * <Button onClick={() => simpanData("dataLain")}
 */
export function useSetGlobalData<T = any>(
  dataName: string,
  data?: T | null,
  simpanLocalStorage = false
) {
  const dispatch = useDispatch();
  const simpanData = (dataSimpan: T | null) => {
    dispatch(setGlobalData(dataName, dataSimpan || null));
    simpanLocalStorage &&
      localStorage.setItem(`WatchCourse_${dataName}`, JSON.stringify(data));
  };
  useEffect(() => {
    if (data === undefined) return;
    dispatch(setGlobalData(dataName, data || null));
    simpanLocalStorage &&
      localStorage.setItem(`WatchCourse_${dataName}`, JSON.stringify(data));
  }, [data, dataName]);
  return simpanData;
}

export function useSetDataGlobal<T = any>(
  dataName: TDataName,
  data?: T | null,
  simpanLocalStorage = false
) {
  const dispatch = useDispatch();
  const simpanData = (dataSimpan: T | null) => {
    dispatch(setGlobalData(dataName, dataSimpan ?? {}));
    simpanLocalStorage &&
      localStorage.setItem(`GlobalArkademi_${dataName}`, JSON.stringify(data));
  };
  useEffect(() => {
    if (data === undefined) return;
    dispatch(setGlobalData(dataName, data ?? {}));
    simpanLocalStorage &&
      localStorage.setItem(`GlobalArkademi_${dataName}`, JSON.stringify(data));
  }, [data, dataName]);
  return simpanData;
}

/**@deprecated langsung saja pakai useSetDataGlobal */
export function useSetDataGlobalTrigger(simpanLocalStorage = false) {
  const dispatch = useDispatch();
  const simpanData = (dataName: string, dataSimpan: any) => {
    dispatch(setGlobalData(dataName, dataSimpan || null));
    simpanLocalStorage &&
      localStorage.setItem(
        `GlobalArkademi_${dataName}`,
        JSON.stringify(dataSimpan)
      );
  };
  return simpanData;
}

/**
 *
 * get data redux globalWatchCourse, contoh penggunaan:
 *
 * useGetDataWatchCourse("dataPertama")
 */
export function useGetDataGlobal<T = any>(
  dataName: string,
  fromLocalStorage = false
) {
  const data = useSelector((state: any) => {
    const dataLocalStorage = JSON.parse(
      localStorage.getItem(`WatchCourse_${dataName}`) || "null"
    );
    if (fromLocalStorage) {
      return state?.global?.[dataName] || dataLocalStorage || null;
    }
    return state?.global?.[dataName] || null;
  });
  return data as T | null;
}

export function useGetDataGlobalV2<T = any>(
  dataName: TDataName,
  fromLocalStorage = false
) {
  const data = useSelector((state: any) => {
    const dataLocalStorage = JSON.parse(
      localStorage.getItem(`GlobalArkademi_${dataName}`) || "null"
    );
    if (fromLocalStorage) {
      return state?.global?.[dataName] || dataLocalStorage || null;
    }
    return state?.global?.[dataName] || null;
  });
  return data as T | null;
}

export function usePurgeData(fromLocalStorage = false) {
  const dispatch = useDispatch();
  const simpanData = (dataName: string) => {
    dispatch(setGlobalData(dataName, undefined));
    fromLocalStorage &&
      localStorage.setItem(
        `GlobalArkademi_${dataName}`,
        JSON.stringify(undefined)
      );
  };
  return simpanData;
}

const cekAPI = (jenisApi: TJenisAPI) => {
  return (
    (jenisApi === "apicourse"
      ? api_course_url
      : jenisApi === "apimember"
      ? api_member_url
      : jenisApi === "apiorders"
      ? api_orders_url
      : jenisApi === "apifirebase"
      ? api_firebase_url
      : jenisApi === "apiexpress"
      ? api_express
      : "") || "https://api-course.arkademi.com"
  );
};

type TDataName = "LiveAccess";
