/**
 * @param {string} html
 */
export const dapatkanHTMLVideo = (html) => {
  if (html) {
    let tmp = document.createElement("div")
    tmp.innerHTML = html
    const htmlVideo = tmp.getElementsByClassName("arve")
    return htmlVideo[htmlVideo.length - 1]?.innerHTML ? htmlVideo[htmlVideo.length - 1]?.innerHTML : "KOSONG"
  } else {
    return "KOSONG"
  }
}

/**
 * @param {string} html
 */
export const hapusHTMLVideo = (html) => {
  if (html) {
    let div = document.createElement("div")
    div.innerHTML = html
    const htmlVideo = div.getElementsByClassName("arve")
    if (htmlVideo[0]) {
      htmlVideo[0].parentNode.removeChild(htmlVideo[0])
    }
    return div?.innerHTML ? div?.innerHTML : "KOSONG"
  } else {
    return "KOSONG"
  }
}

/**
 * @param {string} html
 */
export const dapatkanSRCSertifikat = (html) => {
  if (html) {
    let div = document.createElement("div")
    div.innerHTML = html

    const htmlIMG = div.getElementsByTagName("img")
    return htmlIMG[htmlIMG.length - 1]?.src ? htmlIMG[htmlIMG.length - 1]?.src : null
  } else {
    return null
  }
}

/**
 * @param {string} htmlHarga
 */
export const dapatkanHTMLHargaDiskon = (htmlHarga) => {
  if (htmlHarga) {
    let div = document.createElement("div")
    div.innerHTML = htmlHarga
    const htmlDiskon = div.getElementsByClassName("woocommerce-Price-amount amount")
    if (htmlDiskon.length >= 2) {
      const hargaHasil = htmlDiskon[0]?.innerHTML.replace(/<[^>]*>?/gm, '').replace(/\D/g, "")
      return hargaHasil ? hargaHasil : null
    } else {
      return null
    }
  } else {
    return null
  }
}
