import { CLEAR_GLOBAL_DATA, SET_GLOBAL_DATA } from "store/types";

//taruh data di sini untuk data sederhana yang digunakan secara global
export const clearGlobalData = () => ({
    type: CLEAR_GLOBAL_DATA,
});

/**
 * 
 * @param {string} dataName 
 * @param {any} data
 * @returns 
 */
export const setGlobalData = (dataName, data) => ({
    type: SET_GLOBAL_DATA,
    payload: {
        [dataName]: data
    }
})