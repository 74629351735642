
import "./PopupVideo.scss"

const PopupVideo = ({deskripsiHTMLVideo}) => {

  return (
    <div className="kontainer-popup-video-course-page" >
      <div className="isi-video-player" dangerouslySetInnerHTML={{ __html: deskripsiHTMLVideo }}>

      </div>
    </div>
  )
}

export default PopupVideo