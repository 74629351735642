import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDataGlobalWatchCourse } from "store/actions/globalWatchCourse";

/**
 *
 * 2 fungsi: simpan data reaktif, atau simpan data dengan fungsi, contoh 2 penggunaan:
 *
 * const [coba, setData] = useState("")\
 * useSimpanDataWatchCourse<IData>("dataPertama", coba)\
 * <Button onClick={() => setData("data lain")}
 *
 * atau
 *
 * const simpanData = useSimpanDataWatchCourse<IData>("dataPertama")\
 * <Button onClick={() => simpanData("dataLain")}
 */
export function useSetDataWatchCourse<T = any>(
  dataName: TDataName,
  data?: T | null,
  simpanLocalStorage = false
) {
  const dispatch = useDispatch();
  const simpanData = (dataSimpan: T | null) => {
    dispatch(setDataGlobalWatchCourse(dataName, dataSimpan || null));
    simpanLocalStorage &&
      localStorage.setItem(`WatchCourse_${dataName}`, JSON.stringify(data));
  };
  useEffect(() => {
    if (data === undefined) return;
    dispatch(setDataGlobalWatchCourse(dataName, data || null));
    simpanLocalStorage &&
      localStorage.setItem(`WatchCourse_${dataName}`, JSON.stringify(data));
  }, [data, dataName]);
  return simpanData;
}

/**
 *
 * get data redux globalWatchCourse, contoh penggunaan:
 *
 * useGetDataWatchCourse("dataPertama")
 */
export function useGetDataWatchCourse<T = any>(
  dataName: TDataName,
  fromLocalStorage = false
) {
  const data = useSelector((state: any) => {
    const dataLocalStorage = JSON.parse(
      localStorage.getItem(`WatchCourse_${dataName}`) || "null"
    );
    if (fromLocalStorage) {
      return state?.globalWatchCourse?.[dataName] || dataLocalStorage || null;
    }
    return state?.globalWatchCourse?.[dataName] || null;
  });
  return data as T | null;
}

type TDataName =
  | "mencoba"
  | "dataPertama"
  | "watchCourseJawabanKuesioner"
  | "watchCourseTab"
  | "sertifikatDone"
  | "watchCourseKurikulum"
  | "fullscreenSafariIOS"
  | "watchCourseIdKelas"
  | "certificateGenerated"
  | "watchCourseUnjukKeterampilan"
  | "watchCourseTugasPraktikMandiri"
  | "watchCourseWebinar"
  | "sertifikatDone"
  | "watchCourseStatusV2"
  | "watchCourseTab"
  | "watchCourseStatusV2"
  | "courseItemQuizJSON"
  | "sesiWebinarVoucher"
  | "dataCheckout"
  | "watchCourseLampiran"
  | "watchCourseQuiz"
  | "userStatus"
  | "detailCourse"
  | "watchCourseDataWebinar"
  | "jawabanTugasMandiri"
  | "jawabanUnjukKeterampilan"
  | "watchCourseDataKuesioner"
  | "watchCourseSPL"
  | "watchCourseDataFeedbackUkSpl"
  | "watchCourseJawabanRJ"
  | "watchCourseIsPass"
  | "watchCourseSelesai";
