import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

const CardSkeleton = () => {
  const [dataSlek, setDataSlek] = useState(window.innerWidth);

  useEffect(() => {
    const handleSleck = () => {
      setDataSlek(window.innerWidth);
    };
    window.addEventListener("resize", handleSleck);
    return () => {
      window.removeEventListener("resize", handleSleck);
    };
  }, []);
  return (
    <section>
      <div className="container">
        <div
          className="row"
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          {dataSlek >= 768
            ? Array(4)
                .fill()
                .map((item, index) => (
                  <div
                    key={`terbaru-${index}`}
                    className="mt-4 d-flex align-items-center"
                  >
                    <div className="card card-featured" style={{ width: 265 }}>
                      <figure className="img-wrapper">
                        <Skeleton height={120} width={250} />
                      </figure>
                      <div className="meta-wrapper">
                        <h4>
                          <Skeleton height={10} width={220} />
                          <Skeleton height={10} width={150} />
                        </h4>
                        <div className="d-flex align-item-center">
                          <span className="mr-1">
                            <Skeleton circle={true} height={22} width={22} />
                          </span>
                          <span className="mr-4">
                            <Skeleton height={10} width={50} />
                          </span>
                          <span className="mr-1">
                            <Skeleton circle={true} height={22} width={22} />
                          </span>
                          <span>
                            <Skeleton height={10} width={50} />
                          </span>
                        </div>
                        <div className="vendor d-flex align-item-center">
                          <span className="mr-2">
                            <Skeleton circle={true} height={22} width={22} />
                          </span>
                          <span>
                            <Skeleton height={10} width={150} />
                          </span>
                        </div>
                        <div className="salary d-flex justify-content-center">
                          <div className="salary-class">
                            <span className="salary-trough mr-4">
                              <Skeleton height={10} width={80} />
                            </span>
                            <span className="salary-cut">
                              <Skeleton height={10} width={80} />
                            </span>
                          </div>
                        </div>
                        <span className="d-flex justify-content-center">
                          <Skeleton height={10} width={100} />
                        </span>
                        <div className="d-flex justify-content-center">
                          <Skeleton height={24} width={160} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            : Array(1)
                .fill()
                .map((item, index) => (
                  <div
                    key={`terbaru-${index}`}
                    className="mt-4 d-flex align-items-center"
                  >
                    <div className="card card-featured" style={{ width: 265 }}>
                      <figure className="img-wrapper">
                        <Skeleton height={120} width={250} />
                      </figure>
                      <div className="meta-wrapper">
                        <h4>
                          <Skeleton height={10} width={220} />
                          <Skeleton height={10} width={150} />
                        </h4>
                        <div className="d-flex align-item-center">
                          <span className="mr-1">
                            <Skeleton circle={true} height={22} width={22} />
                          </span>
                          <span className="mr-4">
                            <Skeleton height={10} width={50} />
                          </span>
                          <span className="mr-1">
                            <Skeleton circle={true} height={22} width={22} />
                          </span>
                          <span>
                            <Skeleton height={10} width={50} />
                          </span>
                        </div>
                        <div className="vendor d-flex align-item-center">
                          <span className="mr-2">
                            <Skeleton circle={true} height={22} width={22} />
                          </span>
                          <span>
                            <Skeleton height={10} width={150} />
                          </span>
                        </div>
                        <div className="salary d-flex justify-content-center">
                          <div className="salary-class">
                            <span className="salary-trough mr-4">
                              <Skeleton height={10} width={80} />
                            </span>
                            <span className="salary-cut">
                              <Skeleton height={10} width={80} />
                            </span>
                          </div>
                        </div>
                        <span className="d-flex justify-content-center">
                          <Skeleton height={10} width={100} />
                        </span>
                        <div className="d-flex justify-content-center">
                          <Skeleton height={24} width={160} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
        </div>
      </div>
    </section>
  );
};

export default CardSkeleton;
