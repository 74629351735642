import { useState } from "react";
import { useEffect } from "react";


export default function useScrapeRating(courseRating = {
  "Ratings": {
    total_rating: "2.5",
    count: "0",
    star: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    },
  }
}) {
  // logika mendapatkan data review dari courseRating

  const [summaryReview, setSummaryReview] = useState({
    jmlReview: 0,
    rating: 2.5,
    bintang5: 0,
    bintang4: 0,
    bintang3: 0,
    bintang2: 0,
    bintang1: 0,
  });

  useEffect(() => {
    if (courseRating) {
      const ratingDapat = courseRating["Ratings"];
      summaryReview.jmlReview = ratingDapat["count"];
      summaryReview.rating = ratingDapat?.total_rating;
      summaryReview.bintang1 = ratingDapat?.star?.[1];
      summaryReview.bintang2 = ratingDapat?.star?.[2];
      summaryReview.bintang3 = ratingDapat?.star?.[3];
      summaryReview.bintang4 = ratingDapat?.star?.[4];
      summaryReview.bintang5 = ratingDapat?.star?.[5];
      setSummaryReview({ ...summaryReview });
    }
  }, [courseRating]);

  return summaryReview
}