import "./Review.scss";
import { FC, useEffect, useRef, useState } from "react";
import { scrollIntoViewOffset } from "utils/DOMfunction";
import useDimensiLayar from "utils/useDimensiLayar";
import Bintang from "elements/Bintang";
import LogoAvatar from "./logo-avatar-user.png";
import IkonKanan from "./ikon-kanan.svg";
import IkonKiri from "./ikon-kiri.svg";
import { useDispatch } from "react-redux";
import { setCourseData } from "store/actions/course";
import { waktuSejak } from "elements/Tanggal";

const Review: FC<IReview> = ({
  summaryReview,
  refReview,
  ReviewKomentar,
  loadingKomentar,
  refReviewKomentar,
}) => {
  const refKontainer = useRef<any>(null);
  const handelScroll = () => {
    scrollIntoViewOffset(refKontainer, 150);
  };
  const [lebarLayar] = useDimensiLayar();

  return (
    <div className="review-course-wrapper" ref={refReviewKomentar}>
      {summaryReview?.jmlReview > 0 && (
        <>
          <h3 className="title" ref={refReview}>
            {!loadingKomentar && " Ulasan Alumni"}
          </h3>
          {/* {loadingKomentar ? (
        <div>loading</div>
      ) : (
        // <SkeletonReview /> */}
          <>
            <div className="body-review">
              <div className="container-rating">
                {summaryReview?.jmlReview > 0 && (
                  <div className="rating-course">
                    <div className="isi-rating">{summaryReview.rating}</div>
                    <div className="info-rating d-flex">
                      <div className="bintang-rating">
                        <Bintang
                          nilai={Number(summaryReview?.rating)}
                          tinggiBintang={lebarLayar > 1000 ? 18.32 : 16.1}
                        />
                      </div>
                      <p className="text-info-rating">rating dari alumni</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="container-review" ref={refKontainer}>
                {ReviewKomentar?.isi?.length > 0 && (
                  <>
                    <ul
                      className="seluruh-review"
                      style={loadingKomentar ? { filter: `opacity(0.3)` } : {}}
                    >
                      {ReviewKomentar?.isi?.map((nilai: any, indeks: any) => {
                        if (indeks >= 3) return <></>;
                        return (
                          <IsiReview
                            namaPengguna={nilai.namaPengguna}
                            fotoProfil={nilai.fotoProfil}
                            waktuPosString={nilai.waktuPos}
                            rating={nilai.rating}
                            review={nilai.review}
                            key={indeks}
                            indeks={indeks}
                            lebarLayar={lebarLayar}
                          />
                        );
                      })}
                    </ul>
                    {(ReviewKomentar.totalLaman || 0 > 0) && (
                      <LamanReview
                        totalLaman={ReviewKomentar.totalLaman}
                        handelScroll={handelScroll}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </>
          {/* )} */}
        </>
      )}
    </div>
  );
};

const LamanReview: FC<ILamanReview> = ({ totalLaman, handelScroll }) => {
  const [lamanTerpilih, setLamanTerpilih] = useState<any>(1);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCourseData("reviewPage", lamanTerpilih));
  }, [lamanTerpilih]);
  const handelBerubah = (e: any) => {
    if ((e?.target?.value && e.target.value <= 0) || isNaN(e?.target?.value)) {
      setLamanTerpilih(0);
    } else if (e?.target?.value && e.target.value > totalLaman) {
      setLamanTerpilih(totalLaman);
    } else {
      setLamanTerpilih(Number(e.target.value));
    }
  };
  return (
    <div className="klaster-laman">
      <input
        className="laman-ke"
        type="number"
        value={lamanTerpilih}
        onChange={handelBerubah}
      />
      <h6 className="batas-laman">{"dari " + totalLaman}</h6>
      <button
        className="tbl-arah-kiri"
        style={{ background: lamanTerpilih == "1" ? "#F1F2F4" : "" }}
        onClick={() => {
          if (!(lamanTerpilih === 1)) {
            setLamanTerpilih(lamanTerpilih - 1);
            handelScroll();
          }
        }}
      >
        <img className="gbr" src={IkonKiri} />
      </button>
      <button
        className="tbl-arah-kanan"
        style={{ background: lamanTerpilih == totalLaman ? "#F1F2F4" : "" }}
        onClick={() => {
          if (!(lamanTerpilih === totalLaman)) {
            setLamanTerpilih(lamanTerpilih + 1);
            handelScroll();
          }
        }}
      >
        <img className="gbr" src={IkonKanan} />
      </button>
    </div>
  );
};

const IsiReview: FC<IIsiReview> = ({
  namaPengguna,
  fotoProfil,
  waktuPosString,
  rating,
  review,
  indeks,
  lebarLayar,
}) => {
  // console.log("fotoProfil", fotoProfil);
  return (
    <li className="isi-review d-flex" key={indeks}>
      <img
        src={fotoProfil != "belum upload" ? fotoProfil : LogoAvatar}
        alt=""
        className="avatar-reviewer"
      />
      <div className="body-content">
        <div className="info-cepat-review">
          <h4 className="name-reviewer">{namaPengguna || "Anonim"}</h4>
          <div className="rating-pengguna">
            <Bintang
              nilai={rating}
              tinggiBintang={lebarLayar > 1000 ? 9 : 10.32}
            />
          </div>
          <p className="waktu-pos">{waktuSejak(waktuPosString)}</p>
        </div>
        <div className="desc-review m-0 p-0">
          <p className="isi-desc m-0">{review}</p>
        </div>
      </div>
    </li>
  );
};

interface IReview {
  summaryReview: any;
  refReview: any;
  ReviewKomentar: any;
  loadingKomentar: any;
  refReviewKomentar: any;
}

interface IIsiReview {
  namaPengguna: any;
  fotoProfil: any;
  waktuPosString: any;
  rating: any;
  review: any;
  indeks: any;
  lebarLayar: any;
}

interface ILamanReview {
  totalLaman: any;
  handelScroll: any;
}
export default Review;
