import { useState } from 'react';
import { useEffect } from 'react';

/**
 * scroll into view with offset pixel
 * @param {import('react').MutableRefObject<HTMLElement>} ref 
 * @param {number} offset 
 */
export function scrollIntoViewOffset(ref, offset) {
    if (ref.current === null || ref.current == undefined) {
        return
    }
    var elementPosition = ref.current.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - offset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}

/**`
 * check if ref is on screen
 * @param {import('react').MutableRefObject<HTMLElement>} ref 
 */
export function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    )

    useEffect(() => {
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [])

    return isIntersecting
}