import { setGlobalData } from "store/actions/global";
import AlertCoinRegister from "elements/Alert/AlertCoinRegister/AlertCoinRegister";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
export default function useAlertKoinRegister() {
  const arkKoin = useSelector(
    (state) => state.authorization?.arkacoins?.data?.addedCoin
  );

  const [bukaCoins, setBukaCoins] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch && dispatch(setGlobalData("dapatKoin", arkKoin));
    setBukaCoins(arkKoin);
  }, [arkKoin]);

  return setBukaCoins;
}
