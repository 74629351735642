import React from "react";
import propTypes from "prop-types";
import "./index.scss";

import { IconDuration, IconStar, IconUser } from "assets";
import "../../assets/scss/style_custome.scss";

export default function Cards(props) {
  const {
    isImage,
    isTitle,
    isType,
    isRates,
    isUsers,
    isFacilitator,
    isAvatar,
    isSale,
    isPrice,
    isCutPrice,
    href,
  } = props;

  return (
    <>
      <div className="product-card">
        <div className="badge">{isType}</div>
        <div className="product-tumb">
          <img src={isImage} alt="" />
        </div>
        <div className="product-details">
          {/* <span className="product-catagory">Women,bag</span> */}
          <h4>{isTitle}</h4>
          <div className="product-information">
            <span>
              <img src={IconStar} alt="icon" />
              {isRates}
            </span>
            <span>
              <img src={IconUser} alt="icon" />
              {isUsers}
            </span>
            <span>
              <img src={isAvatar} alt="icon" />
              <span className="fasilitator">{isFacilitator}</span>
            </span>
          </div>
          <div className="product-bottom-details">
            <small>Diskon {isSale} %</small>
            <div className="product-price">
              Rp {isPrice} <small>Rp {isCutPrice}</small>
            </div>
            <a className="btn btn-ambil-kelas" href={href}>
              Ambil Kelas
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

Cards.propTypes = {
  isImage: propTypes.string,
  isTitle: propTypes.string,
  isType: propTypes.string,
  isRates: propTypes.oneOfType([propTypes.number, propTypes.string]),
  isUsers: propTypes.oneOfType([propTypes.number, propTypes.string]),
  isFacilitator: propTypes.string,
  isAvatar: propTypes.string,
  isSale: propTypes.string,
  isCutPrice: propTypes.oneOfType([propTypes.number, propTypes.string]),
  isPrice: propTypes.number,
  href: propTypes.string,
};
