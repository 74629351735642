import Button from "elements/Button";
import React from "react";
// import Button from "./elements/Button";

import "./index.scss";

export default function Prakerja() {
  return (
    <section>
      <div className="container">
        <img
          src={`https://cdn.arkademi.com/asset/img/20210301163554/prakerja-panduan.jpg`}
          alt="image prakerja"
          className="img-hero-prakerja"
        />
      </div>
      {/* <div className="jumbotron jumbotron-fluid hero">
        <img src={ImageHero} alt="image hero" className="img-fluid" />
        <div className="container title-hero">
          <h1 className="h2 font-weight-bold line-height-1">
            Panduan Menggunakan <br />
            Kupon Prakerja di Arkademi
          </h1>

          <Button className="btn px-5 text-white" isSecondary isDanger>
            Lihat Video
          </Button>
        </div>
      </div> */}
    </section>
  );
}
