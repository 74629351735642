import "./Sesi.scss";
import { FC, useEffect, useState } from "react";
import IconTime from "./icon-time.svg";
import IconKuota from "./icon-kuota.svg";
import LogoAvatar from "./logo-avatar-user.png";
import { useGetDataGlobal, useSetGlobalData } from "utils/useFetchUmum";

const CardSesi: FC<ICardSesi> = ({
  sesi,
  orderCount,
  orderLimit,
  pembicara,
  error,
}) => {
  const [sesiKelas, setSesiKelas] = useState<string>("");
  const [waktu, setWaktu] = useState<string>("");
  const simpanData = useSetGlobalData("sesiWebinarVoucher", "-1");
  const sesiWebinar = useGetDataGlobal("sesiWebinarVoucher");

  const dataPembicara = pembicara?.filter((f: any) => f?.sesi == sesi);
  const pathVoucher = window.location.pathname;
  const waktuMulai = dataPembicara[0].waktu_mulai_sesi
    .substring(0, 5)
    .replace(/\:/g, ".");
  const waktuSelesai = dataPembicara[0].waktu_selesai_sesi
    .substring(0, 5)
    .replace(/\:/g, ".");

  useEffect(() => {
    if (sesi == "0") {
      setSesiKelas("malam A");
      setWaktu(waktuMulai + "-" + waktuSelesai + " WIB");
    } else if (sesi == "1") {
      setSesiKelas("malam B");
      setWaktu(waktuMulai + "-" + waktuSelesai + " WIB");
    } else if (sesi == "2") {
      setSesiKelas("malam C");
      setWaktu(waktuMulai + "-" + waktuSelesai + " WIB");
    } else if (sesi == "3") {
      setSesiKelas("siang A");
      setWaktu(waktuMulai + "-" + waktuSelesai + " WIB");
    } else if (sesi == "4") {
      setSesiKelas("siang B");
      setWaktu(waktuMulai + "-" + waktuSelesai + " WIB");
    } else if (sesi == "5") {
      setSesiKelas("siang C");
      setWaktu(waktuMulai + "-" + waktuSelesai + " WIB");
    }
  }, [sesi]);

  // console.log(
  //   "aaaaa",
  //   sesi,
  //   orderCount,
  //   orderLimit,
  //   pembicara,
  //   dataPembicara[0]
  // );

  const cekSesiPenuh = (kuota: number) => {
    if (kuota > 0) {
      return false;
    } else {
      return true;
    }
  };

  const klikSesi = () => {
    if (
      sesi == "0" &&
      !cekSesiPenuh(orderLimit - orderCount) &&
      pathVoucher == "/cek-voucher"
    ) {
      simpanData("0");
    } else if (
      sesi == "1" &&
      !cekSesiPenuh(orderLimit - orderCount) &&
      pathVoucher == "/cek-voucher"
    ) {
      simpanData("1");
    } else if (
      sesi == "2" &&
      !cekSesiPenuh(orderLimit - orderCount) &&
      pathVoucher == "/cek-voucher"
    ) {
      simpanData("2");
    } else if (
      sesi == "3" &&
      !cekSesiPenuh(orderLimit - orderCount) &&
      pathVoucher == "/cek-voucher"
    ) {
      simpanData("3");
    } else if (
      sesi == "4" &&
      !cekSesiPenuh(orderLimit - orderCount) &&
      pathVoucher == "/cek-voucher"
    ) {
      simpanData("4");
    } else if (
      sesi == "5" &&
      !cekSesiPenuh(orderLimit - orderCount) &&
      pathVoucher == "/cek-voucher"
    ) {
      simpanData("5");
    }
  };

  return (
    <div
      className={
        "item-card-sesi d-flex" +
        `${sesiWebinar == sesi ? " active" : ""}` +
        `${
          cekSesiPenuh(orderLimit - orderCount) && pathVoucher == "/cek-voucher"
            ? " disable"
            : ""
        }` +
        `${error && !cekSesiPenuh(orderLimit - orderCount) ? " error" : ""}`
      }
      // className={`${
      //   sesiWebinar == sesi
      //     ? `item-card-sesi d-flex active`
      //     : error
      //     ? "item-card-sesi d-flex error"
      //     : cekSesiPenuh(orderLimit - orderCount)
      //     ? "item-card-sesi d-flex disable"
      //     : "item-card-sesi d-flex"
      // }`}
      id={`wb-prakerja-card-${sesiKelas}`}
      onClick={klikSesi}
    >
      <div className="head-card d-flex" id={`wb-prakerja-card-${sesiKelas}`}>
        <p
          className={`title-card ${
            cekSesiPenuh(orderLimit - orderCount) &&
            pathVoucher == "/cek-voucher"
              ? "title-kuota-penuh"
              : ""
          }`}
          id={`wb-prakerja-card-${sesiKelas}`}
        >
          Sesi {sesiKelas[0]?.toUpperCase() + sesiKelas.substring(1)}
        </p>
        <div className="time-sesi d-flex" id={`wb-prakerja-card-${sesiKelas}`}>
          <img
            src={IconTime}
            alt=""
            className="icon-time"
            id={`wb-prakerja-card-${sesiKelas}`}
          />
          <p className="text" id={`wb-prakerja-card-${sesiKelas}`}>
            {waktu}
          </p>
        </div>
      </div>
      <div className="body-card d-flex" id={`wb-prakerja-card-${sesiKelas}`}>
        <div className="body-info-sesi">
          <div
            className="profile-singkat d-flex"
            id={`wb-prakerja-card-${sesiKelas}`}
          >
            <img
              src={dataPembicara[0]?.avatar || dataPembicara[0]?.image}
              alt=""
              className="avatar-instructor"
              id={`wb-prakerja-card-${sesiKelas}`}
            />
            <div
              className="info-singkat d-flex"
              id={`wb-prakerja-card-${sesiKelas}`}
            >
              <p className="name" id={`wb-prakerja-card-${sesiKelas}`}>
                {dataPembicara[0]?.nama}
              </p>
              <p className="pekerjaan" id={`wb-prakerja-card-${sesiKelas}`}>
                {dataPembicara[0]?.posisi}
              </p>
            </div>
          </div>
          <p className="description" id={`wb-prakerja-card-${sesiKelas}`}>
            {dataPembicara[0]?.deskripsi}
          </p>
        </div>
        <div className="info-kuota-sesi">
          <span
            className={`progress-kuota ${
              cekSesiPenuh(orderLimit - orderCount) &&
              pathVoucher == "/cek-voucher"
                ? "kuota-penuh"
                : cekSesiPenuh(orderLimit - orderCount) &&
                  pathVoucher !== "/cek-voucher"
                ? "kuota-penuh-course-page"
                : ""
            }`}
            id={`wb-prakerja-card-${sesiKelas}`}
          >
            <progress
              id={`wb-prakerja-card-${sesiKelas}`}
              value={orderCount}
              max={orderLimit}
            />
          </span>
          <div
            className="info-kuota d-flex"
            id={`wb-prakerja-card-${sesiKelas}`}
          >
            <div
              className="jumlah-kuota d-flex"
              id={`wb-prakerja-card-${sesiKelas}`}
            >
              <img
                src={IconKuota}
                alt=""
                className="icon-kuota"
                id={`wb-prakerja-card-${sesiKelas}`}
              />
              <p className="text m-0" id={`wb-prakerja-card-${sesiKelas}`}>
                Kuota: {orderLimit}
              </p>
            </div>
            <p className="total-kuota m-0" id={`wb-prakerja-card-${sesiKelas}`}>
              {!cekSesiPenuh(orderLimit - orderCount)
                ? `${orderCount} sudah terisi`
                : "Kuota penuh"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ICardSesi {
  sesi: string;
  orderCount: number;
  orderLimit: number;
  pembicara: any;
  error: boolean;
}

export default CardSesi;
