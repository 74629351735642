import { FC, MutableRefObject, useState, useEffect } from "react";
import {
  ChevronAtas,
  ChevronBawah,
} from "parts/CourseDetailPage/CoursePageReguler/assets/webp";
import "./Intro.scss";
import { hapusHTMLVideo } from "../Fungsi/dapatkanHTML";
import Skeleton from "react-loading-skeleton";

const Intro: FC<IProp> = ({ introHTML, loading, refIntro, infoKelas }) => {
  const [terbuka, setTerbuka] = useState(false);

  const [intro, setIntro] = useState("");

  useEffect(() => {
    if (introHTML) {
      setIntro(hapusHTMLVideo(introHTML));
    }
  }, [introHTML]);

  return (
    <div
      className={terbuka ? "intro-course-buka" : "intro-course"}
      ref={refIntro}
    >
      {infoKelas?.webinar != "1" && (
        <h2 className="title-ikhtisar">Ikhtisar</h2>
      )}
      <div className="teks" dangerouslySetInnerHTML={{ __html: intro }} />
      {loading && (
        <>
          <div className="teks">
            <Skeleton width={"100%"} height={20} />
          </div>
          <div className="teks">
            <Skeleton width={"100%"} height={200} />
          </div>
        </>
      )}
      <div className="fg-putih" />
      <div
        className="baca-selengkapnya"
        onClick={() => {
          setTerbuka(!terbuka);
        }}
      >
        {terbuka ? "Sembunyikan" : "Baca Selengkapnya"}
        <img
          className="chevron"
          src={terbuka ? ChevronAtas : ChevronBawah}
          alt=""
          loading="lazy"
        />
      </div>
    </div>
  );
};

interface IProp {
  introHTML: string;
  loading?: boolean;
  refIntro?: MutableRefObject<HTMLDivElement>;
  infoKelas?: any;
}

export default Intro;
