import { useSelector } from "react-redux";


/**
 * @returns {object | null} 
*/
export function useUser() {
  const pengguna = useSelector((state) =>
  (
    state.authorization && state.authorization.user ?
      state.authorization.user : null
  ));

  return pengguna
}

/**@returns {number | null} */
export function useUserId() {
  return useSelector((state) =>
    state?.authorization?.user?.userPrakerja?.id ? state.authorization.user.userPrakerja.id : null
  )
}