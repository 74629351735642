import { useLocation } from "react-router";
import { useEffect } from "react";
import { useState } from "react";

// input pertama array string, laman page mana saja yang tak perlu Navbar,
// setPerlu navbar untuk mengganti state
//
export default function useTakPerluNavbar(arString = [""]) {
  const location = useLocation();
  const [perluNavbar, setPerluNavbar] = useState(false);

  useEffect(() => {
    const pathTakPerluNavbar = arString;
    const arPerluNavbar = [];
    for (let i = 0; i < pathTakPerluNavbar.length; i++) {
      arPerluNavbar.push(!location.pathname.includes(pathTakPerluNavbar[i]));
    }
    if (setPerluNavbar) {
      setPerluNavbar(!arPerluNavbar.includes(false));
    }
  }, [location.pathname]);

  return perluNavbar;
}
