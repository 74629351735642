import React, { useEffect, useRef, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";

import useKlikLuar from "elements/useKlikLuar";
import IkonPencarian from "./gbr/ikon-pencarian.svg";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie-player";
import lottieJSON from "assets/hand-animation.json";
import { useSelector } from "react-redux";
import useFetchListSearch from "parts/DetailSearchPage/fungsi/useFetchListSearch";
import { addLastSearch, fetchResultSearch } from "store/actions/search";

import "./SearchResponsive.scss";

export default function SearchResponsive({ isOpen }) {
  const [urutkanTerbuka, setUrutkanTerbuka] = useState(false);
  const refDropDown = useRef(null);
  const refSugesList = useRef();
  const navigate = useNavigate();
  const [term, setTerm] = useState("");
  const [sugesSearch, setSugesSearch] = useState([]);
  const [searchKelas, loadingSearch] = useFetchListSearch();
  const [filterSuges, setFilterSuges] = useState([]);
  const [showSuges, setShowSuges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchClass, setSearchClass] = useState([]);
  const historySearch = JSON.parse(localStorage.getItem("historySearch")) || [];
  useKlikLuar(refDropDown, setUrutkanTerbuka, urutkanTerbuka);
  useKlikLuar(refSugesList, setShowSuges, showSuges);

  const onChange = (e) => {
    const userInput = e.target.value;
    //memfilter dari rekomendasi
    const unLiked = sugesSearch.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    setTerm(e.target.value);
    setFilterSuges(unLiked);
    setShowSuges(true);
  };

  //klik auto suges
  const onClick = (e) => {
    setFilterSuges([]);
    setTerm(e.target.innerText);
    setShowSuges(false);
    klikSearch();
  };

  const klikSearch = async () => {
    if (term !== "") {
      setLoading(true);
      // console.log("key", term);
      addLastSearch("resultHistorySearch", term, historySearch);
      const hasil = await fetchResultSearch(`hasilPencarian`, term);
      // console.log(hasil);
      if (!hasil) return;
      setSearchClass([...hasil.data.data]);
      navigate(`/hasil-pencarian?key=${term || ""}`);
      isOpen(false);
    } else if (pencarianCache == null) {
      navigate(`/hasil-pencarian?key=${term || ""}`);
      isOpen(false);
    } else if (pencarianCache !== null) {
      setSearchClass([...pencarianCache]);
    }
    setLoading(false);
  };

  const pencarianCache = useSelector((state) =>
    state.search && state.search.hasilPencarian
      ? state.search.hasilPencarian
      : null
  );

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      klikSearch();
      setShowSuges(false);
    }
    return [searchClass, loading];
  };

  useEffect(() => {
    const ha = [];

    for (let a = 0; a < searchKelas.length; a++) {
      const datanama = searchKelas[a];
      if (datanama.name && datanama.status === "publish") {
        ha.push(datanama.name);
      }
    }
    setSugesSearch(ha);
  }, [searchKelas]);

  const SugesList = () => {
    return filterSuges.length ? (
      <ul className="suggestions" ref={refSugesList}>
        {filterSuges.map((suggestion, index) => {
          let className;
          return (
            <li className={className} key={index} onClick={onClick}>
              {suggestion}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className="no-suggestions" ref={refSugesList}>
        {/* <span role="img" aria-label="tear emoji">
          😪
        </span>{" "} */}
        pelatihan tidak di temukan
      </div>
    );
  };

  return (
    <>
      <section className="kontainer-modal-search">
        <div className="modal-search">
          <AiFillCloseCircle
            className="icon-nutup"
            onClick={() => {
              isOpen(false);
            }}
          />

          <div className="search-form">
            <input
              type="text"
              className="input-form"
              placeholder="Cari pelatihan Prakerja"
              value={term}
              onChange={onChange}
              onKeyDown={handleKeyDown}
            />
            {!loading ? (
              <img
                className="icon-search"
                src={IkonPencarian}
                style={{ cursor: "pointer" }}
                onClick={klikSearch}
                alt="icon"
              />
            ) : (
              <div className="icon-search loading">
                <Lottie
                  loop
                  animationData={lottieJSON}
                  play
                  style={{ width: 15, height: 15, marginTop: -4 }}
                />
              </div>
            )}
            {showSuges && term && <SugesList />}
            {/* <FiSearch className="icon-search" /> */}
          </div>
        </div>
      </section>
    </>
  );
}
