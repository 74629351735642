import { idKelasDapat } from "store/actions/payment";
import {
  ADD_CART,
  DELETE_CART,
  PAYMENT_PROCESSED,
  CHECKOUT,
  SET_DATA_PAYMENT,
} from "store/types";

const keranjang = JSON.parse(localStorage.getItem("keranjang"));

const initialState = {
  isiCart: keranjang?.cache ? keranjang?.cache : [],
};

export default function payment(state = initialState, action) {
  switch (action.type) {
    case ADD_CART:
      return {
        ...state,
        ...action.payload,
      };
    // if (
    //   action.payload &&
    //   terdapatId(action.payload[idKelasDapat], state.isiCart)
    // ) {
    //   return state;
    // } else {
    //   localStorage.setItem(
    //     "keranjang",
    //     JSON.stringify({
    //       cache: [...state.isiCart, action.payload[idKelasDapat]],
    //     })
    //   );
    //   return {
    //     ...state,
    //     isiCart: [...state.isiCart, action.payload[idKelasDapat]],
    //   };
    // }
    case DELETE_CART:
      const dapat = state.isiCart?.filter(
        (idKelasIsi) => idKelasIsi !== action?.payload[idKelasDapat]
      );
      localStorage.setItem("keranjang", JSON.stringify({ cache: dapat }));
      return {
        ...state,
        isiCart: dapat,
        cartKelas: state.cartKelas?.filter(
          (kelas) => kelas?.course?.id !== action.payload[idKelasDapat]
        ),
      };
    case CHECKOUT:
      return {
        ...state,
        ...action.payload,
      };
    case SET_DATA_PAYMENT:
      return {
        ...state,
        ...action.payload,
      };
    case PAYMENT_PROCESSED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

function terdapatId(id, list) {
  var i;
  for (i = 0; i < list.length; i++) {
    if (list[i] === id) {
      return true;
    }
  }

  return false;
}
