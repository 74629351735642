import React from "react";
import { useNavigate } from "react-router";
// import Button from "elements/Button";
import Flickity from "react-flickity-component";
import "elements/Slider/index.scss";
// import NumberFormat from "react-number-format";
import { IconUser, IconStar, IconHotSale, ImageDefault } from "assets";
// import CardSkeleton from "elements/Skeleton/CardFeature";
import Cards from "elements/Cards";

export default function FeatureSale({ data }) {
  const navigate = useNavigate();

  return (
    <section>
      <div className="container">
        <div className="row title-feature">
          <h2 className="mb-ml-4 pl-3 font-weight-bold">
            <img src={IconHotSale} alt="icon sale" className="mr-2" /> Sedang
            Diskon
          </h2>
        </div>
        <Flickity
          options={{
            groupCells: true,
            wrapAround: false,
            contain: true,
            cellAlign: "right",
            pageDots: false,
          }}
        >
          {data &&
            data.map((item, index) => {
              const price = item.regular_price - item.sale_price;
              return (
                <div key={`terbaru-${index}`} className="mx-3 carousel-cell">
                  <Cards
                    isImage={item.image}
                    isTitle={item.title}
                    isType={"Prakerja"}
                    isRates={item.average_rating}
                    isUsers={item.students_count}
                    isFacilitator={item.instructure.name}
                    isAvatar={item.instructure.avatar}
                    isSale={item.sale_price}
                    isPrice={price}
                    isCutPrice={item.regular_price}
                    href={`/course/${item.ID}`}
                  ></Cards>
                </div>
              );
            })}
        </Flickity>
        <div className="mt-4">
          <hr />
        </div>
      </div>
    </section>
  );
}
