import {
  FETCH_LOGO_MITRA,
  FETCH_CATEGORY_TOP,
  FETCH_ON_SALE,
  FETCH_NEW_COURSE,
  FETCH_BEST_SELLER,
  FETCH_COURSE_BUSINESS,
  FETCH_COURSE_CERTIFIED,
  FETCH_COURSE_FINANCIAL,
  FETCH_CATEGORY_BOTTOM,
  FETCH_DETAIL_COURSE,
  FETCH_HOME_FLASSHALE,
  FETCH_HOME_TRENDING,
  FETCH_HOME_BISNIS,
  FETCH_HOME_KARIR,
  FETCH_COURSE_COINS,
} from "../types";

import axios from "axios";
import store from "store";
import { setDataGlobalWatchCourse } from "./globalWatchCourse";
import { authHeader } from "utils/auth-header";

const api_orders = process.env.REACT_APP_API_BACKEND_ORDERS;

const api_url = process.env.REACT_APP_API_BACKEND_OLD;
export const api_course_url = process.env.REACT_APP_API_BACKEND_COURSE;
export const api_baru = process.env.REACT_APP_API_BACKEND_NEW;
export const api_member = process.env.REACT_APP_API_BACKEND_MEMBER;
export const api_wp = process.env.REACT_APP_API_BACKEND_PRODUCTION;

export const fetchHomeFlasshale = (page, dispatch) => {
  return axios
    .get(api_course_url + `/api/v1/homepage/course_onsales_id`)
    .then((response) => {
      dispatch({
        type: FETCH_HOME_FLASSHALE,
        payload: { [page]: response.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchHomeTrending = (page) => (dispatch) => {
  return axios
    .get(api_course_url + `/api/v1/homepage/course_best_sellers_id`)
    .then((response) => {
      dispatch({
        type: FETCH_HOME_TRENDING,
        payload: { [page]: response.data?.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchHomeBisnis = (page, dispatch) => {
  return axios
    .get(
      `${api_course_url}/api/v1/course/category/${562}/coursesids?urutan=siswa-terbanyak`
    )
    .then((response) => {
      let idCourse = null;
      if (response.data?.data && Array.isArray(response.data.data)) {
        idCourse = [];
        response.data.data.map((idObj) => {
          idObj.post_status && idCourse.push(idObj.ID);
        });
      }
      dispatch({
        type: FETCH_HOME_BISNIS,
        payload: { [page]: idCourse },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchHomeFinancials = (page, dispatch) => {
  return axios
    .get(
      `${api_course_url}/api/v1/course/category/${1733}/coursesids?urutan=siswa-terbanyak`
    )
    .then((response) => {
      let idCourse = null;
      if (response.data?.data && Array.isArray(response.data.data)) {
        idCourse = [];
        response.data.data.map((idObj) => {
          idObj.post_status && idCourse.push(idObj.ID);
        });
      }
      dispatch({
        type: FETCH_HOME_KARIR,
        payload: { [page]: idCourse },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchLogoMitra = (page) => (dispatch) => {
  return axios
    .get(api_url + "/api/v1/homepage/top_logo_sliders", {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: FETCH_LOGO_MITRA,
        payload: {
          [page]: response.data.TopLogoSlider,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchCategoryTop = (page) => (dispatch) => {
  return axios
    .get(api_url + "/api/v1/homepage/top_category_sliders", {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: FETCH_CATEGORY_TOP,
        payload: {
          [page]: response.data.TopCategorySlider,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchOnSale = (page) => (dispatch) => {
  return axios
    .get(api_url + "/api/v1/homepage/course_onsales", {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: FETCH_ON_SALE,
        payload: {
          [page]: response.data.OnSaleCourse,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchNewCourse = (page, dispatch) => {
  return axios
    .get(api_course_url + "/api/v1/homepage/course_latest_id", {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: FETCH_NEW_COURSE,
        payload: {
          [page]: response.data?.data,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchBestSeller = (page) => (dispatch) => {
  return axios
    .get(api_url + "/api/v1/homepage/course_best_sellers", {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: FETCH_BEST_SELLER,
        payload: {
          [page]: response.data.BestSellerCourse,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchCourseCertified = (page) => (dispatch) => {
  return axios
    .get(api_course_url + "/api/v1/homepage/course_certified", {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: FETCH_COURSE_CERTIFIED,
        payload: {
          [page]: response.data.CertifiedCourses,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchCourseBusiness = (page) => (dispatch) => {
  return axios
    .get(api_url + "/api/v1/homepage/course_business", {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: FETCH_COURSE_BUSINESS,
        payload: {
          [page]: response.data.BusinessCourses,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchCourseFinancial = (page) => (dispatch) => {
  return axios
    .get(api_url + "/api/v1/homepage/course_financials", {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: FETCH_COURSE_FINANCIAL,
        payload: {
          [page]: response.data.FinancialCourses,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchCategoryBottom = (page) => (dispatch) => {
  return axios
    .get(api_url + "/api/v1/homepage/bottom_course_categories", {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch({
        type: FETCH_CATEGORY_BOTTOM,
        payload: {
          [page]: response.data.BottomCategory,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 *
 * @param {string} page
 * @param {number} id
 * @returns
 */
export const fetchDetailCourse = async (page, id) => {
  try {
    const resp = await axios.get(
      api_course_url + `/api/v1/prakerja/course/${id}`,
      {
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    );
    store.dispatch({
      type: FETCH_DETAIL_COURSE,
      payload: {
        [page]: resp.data.data,
      },
    });
    store.dispatch(setDataGlobalWatchCourse(page, resp.data.data));
  } catch (e) {
    console.error(e);
  }
};

export const loadFbLoginApi = () => {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: "",
      cookie: true,
      xfbml: true,
      version: "v12.0",
    });
  };

  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
};

/**
 *
 * @param {() => {}} setKurikulum
 * @param {number} idKelas
 */
export const fetchCourseCurriculum = async (setKurikulum, idKelas) => {
  await axios
    .get(`${api_course_url}/api/v1/course/${idKelas}/curriculums`, {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      setKurikulum(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
};

/**
 *
 * @param {(kurikulum: object) => {}} setKurikulum
 * @param {number} idKelas
 * @param {string} token
 */
export const fetchCourseStatusCurriculum = async (
  setKurikulum,
  idKelas,
  token,
  watchCourseSPL
) => {
  try {
    const response = await axios.get(
      watchCourseSPL
        ? `${api_course_url}/api/v1/prakerja/user/coursestatus-spl/${idKelas}`
        : `${api_course_url}/api/v1/prakerja/user/coursestatusV2/${idKelas}`,
      {
        mode: "cors",
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    );

    setKurikulum(response.data);
  } catch (e) {}
};

/**
 * @param {number} idKelas
 * @param {number} idItem
 * @param {string} token
 * @param {(data: object) => {}} setCourseItemJSON
 */
export const fetchCourseStatusItem = async (
  idKelas,
  idItem,
  token,
  setCourseItemJSON,
  setCourseItemDataJSON,
  cancelToken,
  isSpl
) => {
  try {
    const resp = await axios.get(
      isSpl
        ? `${api_course_url}/api/v1/prakerja/user/coursestatus/${idKelas}/item/${idItem}/spl`
        : `${api_course_url}/api/v1/prakerja/user/coursestatus/${idKelas}/item/${idItem}`,
      {
        mode: "cors",
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        cancelToken: cancelToken.token,
      }
    );
    if (resp.data && resp.data.message) {
      setCourseItemJSON(resp.data.message);
      setCourseItemDataJSON(resp?.data?.data);
    }
  } catch (e) {
    console.error("error fetchCourseStatusItem");
    console.error(e);
  }
};

/**
 * @param {number} idKelas
 * @param {number} idItem
 * @param {string} token
 * @param {(data: object) => {}} setCourseItemKuisJSON
 */
export const fetchCourseStatusItemKuis = async (
  idKelas,
  idItem,
  token,
  setCourseItemKuisJSON,
  cancelToken,
  isSpl
) => {
  try {
    const resp = await axios.get(
      isSpl
        ? `${api_course_url}/api/v1/prakerja/user/coursestatus/${idKelas}/item/${idItem}/spl`
        : `${api_course_url}/api/v1/prakerja/user/coursestatus/${idKelas}/item/${idItem}`,
      {
        mode: "cors",
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        cancelToken: cancelToken.token,
      }
    );
    if (resp.data && resp.data.message) {
      setCourseItemKuisJSON(resp.data.message);
    }
  } catch (e) {
    console.error("eror fetch courseStatusKurikulum");
    console.error(e);
  }
};

/**
 *
 * @param {number} idItem
 * @param {string} token
 * @param {number} idKelas
 */
export const markAsComplete = async (idItem, token, idKelas) => {
  try {
    const mark = await axios.post(
      `${api_course_url}/api/v1/prakerja/user/coursestatus/${idKelas}/item/${idItem}/mark_as_complete`,
      {},
      {
        mode: "cors",
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    );
    if (mark.data && mark.data.success) {
      // console.log(idItem);
      // console.log(idKelas);
      // console.log(mark.data);
      return true;
    } else {
      // console.log(mark.data);
      return false;
    }
  } catch (e) {
    // console.log(e.message);
    return false;
  }
};

export async function kumpulkanQuis(idItem, kuisKumpulkan, token) {
  try {
    const komplitQuis = await axios.post(
      `${api_course_url}/api/v1/prakerja/course/submit/quiz/${idItem}`,
      kuisKumpulkan,
      {
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    );

    if (komplitQuis.data && komplitQuis.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log(e.message);
  }
}

export const urlAPI = () => {
  return api_url;
};

export const apiOrders = () => {
  return api_orders;
};

export async function retakeQuis(idKelas, idItem, token) {
  try {
    const quisRetake = await axios.get(
      `/api/v1/user/coursestatus/${idKelas}/retake_quiz/${idItem}`,
      {
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    );

    if (quisRetake.data && quisRetake.data.success) {
    }
  } catch (e) {
    console.log(e.message);
  }
}

export async function generateCertificate(idItem, token, idPengguna) {
  try {
    const data = await axios.get(
      `${api_baru}/wp-json/api/arkademi/generate_certificate/${idPengguna}/${idItem}`,
      {
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    );
    if (data.data.generate_url && data.data.success) {
      return { success: true, url: data.data.generate_url };
    } else {
      return { success: false, url: null };
    }
  } catch (e) {
    return { success: false, url: null };
  }
}

export async function getUserCertificate(idItem, token, idPengguna) {
  try {
    const data = await axios.get(
      `${api_baru}/wp-json/api/arkademi/get_user_certificate/${idPengguna}/${idItem}`,
      {
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    );
    if (data.data.cert_url && data.data.success) {
      return { success: true, url: data.data.cert_url };
    } else {
      return { success: false, url: null };
    }
  } catch (e) {
    return { success: false, url: null };
  }
}

export async function finishClass(token, idCourse) {
  try {
    const data = await axios.post(
      `${api_course_url}/api/v1/prakerja/course/submit/course/${idCourse}`,
      {},
      {
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    );
    return { success: data?.data?.success || false, data: data?.data };
  } catch (e) {
    return { success: false, data: null };
  }
}

//1 mulai kelas, 2 lanjutkan, 3 sedang dievaluasi, 4 terselesaikan

export const fetchCourseCoins = (courseCoins, dispatch, id) => {
  // console.log("id", id);
  return axios
    .get(api_course_url + `/api/v1/course/coin/${id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      // console.log("response fetch coins", response.data);
      dispatch({
        type: FETCH_COURSE_COINS,
        payload: { [courseCoins]: response.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export async function fetchQuizPass(token, idCourse, idItem, inputPass) {
  try {
    const data = await axios.get(
      `${api_course_url}/api/v1/prakerja/user/quiz-password/check/${idCourse}/${idItem}?quiz_pass_input=${inputPass}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    store.dispatch(setDataGlobalWatchCourse("quizPasswordWebinar", data.data));
    return { success: data?.data?.success || false, data: data?.data };
  } catch (e) {
    store.dispatch(
      setDataGlobalWatchCourse("quizPasswordWebinar", {
        success: false,
        data: null,
      })
    );
    return { success: false, data: null };
  }
}
