import { useState } from "react";
import { useEffect } from "react";
/**
 * @property {string} name
 * @property {string} jabatan
 * @property {string} desc
 * @param {any} instrikturJSON
 * @returns {import("type/Course").IInstruktor[]}
 */

export default function useScrapeInstruktur(instrukturJSON) {
  const [instruktur, setInstruktur] = useState([]);
  useEffect(() => {
    const newInstrukturAr = [];
    for (let i in instrukturJSON) {
      const instrukturItem = instrukturJSON[i];
      const newInstruktur = {
        nama: instrukturItem.name || "",
        deskripsi: instrukturItem.desc || "",
        foto: instrukturItem.foto || null,
        posisi: instrukturItem.jabatan || "",
      };
      if (newInstruktur.nama !== "") {
        newInstrukturAr.push(newInstruktur);
      }
    }
    setInstruktur(newInstrukturAr);
  }, [instrukturJSON]);
  return instruktur;
}
