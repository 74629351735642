/**
 * 
 * @param {number | null} angka 
 * @returns {number | null}
 */
export function angkaDenganTitik(angka = 0) {
  if (!isNaN(Number(angka))) {
    return Number(Number(angka).toFixed(0))?.toLocaleString('id-ID');
  } else {
    return null
  }
}

function spliceString(str, indeks, angka, tambah) {
  if (indeks < 0) {
    indeks = str.length + indeks;
    if (indeks < 0) {
      indeks = 0;
    }
  }

  return str && str.slice(0, indeks) + (tambah || "") + str.slice(indeks + angka);
}

/**
 * @param {number} sekon
 */
export function sekonKeHMS(sekon) {
  sekon = Number(sekon);
  var h = Math.floor(sekon / 3600);
  var m = Math.floor(sekon % 3600 / 60);
  var s = Math.floor(sekon % 3600 % 60);

  var hDisplay = h > 0 ? h + ":" : "";
  var mDisplay = m > 0 ? m + ":" : "00:";
  var sDisplay = (s > 0 && s < 10) ? "0" + s : (s >= 10) ? s : "00";
  return hDisplay + mDisplay + sDisplay;
}
