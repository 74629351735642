import React, { Component } from "react";

import { Prakerja } from "example/Hero";
import FlowCoursePrakerja from "parts/FlowCourse/FlowPrakerja/FlowCoursePrakerja";
import { FeatureBestSeller, FeatureSale } from "example/Feature";
import KuponPrakerja from "parts/PrakerjaPage/Kupon/KuponPrakerja";
import Footer from "parts/Footer/Footers";

// import FeaturePrakerja from "parts/Prakerja";

export default class PrakerjaPage extends Component {
  componentDidMount() {
    document.title = "Arkademi | Prakerja";
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <Prakerja />
        <KuponPrakerja />
        <FeatureBestSeller />
        <FeatureSale />
        <FlowCoursePrakerja />
        <Prakerja />
        {/* <FeaturePrakerja /> */}
        <Footer />
      </>
    );
  }
}
