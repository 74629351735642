import axios from "axios";
import {
  Pembayaran,
  Billing,
  LineItems,
  LineFee,
  LineFeeFlashSale,
  LineKupon,
  LineFeeKoin,
} from "parts/CheckoutPage/classPembayaran";
import store from "store";
import {
  ADD_CART,
  CHECKOUT,
  DELETE_CART,
  PAYMENT_PROCESSED,
  SET_DATA_PAYMENT,
} from "store/types";
import { authHeader } from "utils/auth-header";
import { getDateTime, getDateTimeYTZ } from "utils/formatDateWebinar";
import { setGlobalData } from "./global";

export const api_course_url = process.env.REACT_APP_API_BACKEND_COURSE;
export const api_order = process.env.REACT_APP_API_BACKEND_ORDERS;
export const api_bayar_hc = process.env.REACT_APP_API_PAYMENT_HC;
export const api_redeem = process.env.REACT_APP_API_REDEEM_PRAKERJA;
export const idKelasDapat = "idKelasDapat";

/**
 * @param {number} idKelas
 */
// export const tambahKeKeranjang = (idKelas) => ({
//   type: ADD_CART,
//   payload: {
//     [idKelasDapat]: idKelas,
//   },
// });

export const tambahKeKeranjang = (idKelas, dispatch) => {
  localStorage.setItem("keranjang", JSON.stringify(idKelas));
  dispatch(setGlobalData("fromCoursePage", true));
  dispatch({
    type: ADD_CART,
    payload: { [idKelasDapat]: idKelas },
  });
};

export const tambahKeCheckout = (idKelas) => ({
  type: CHECKOUT,
  payload: {
    idKelasCheckout: idKelas,
  },
});

/**
 * @param {number} idKelas
 */
export const hapusIsiKeranjang = (idKelas) => ({
  type: DELETE_CART,
  payload: {
    [idKelasDapat]: idKelas,
  },
});

/**
 *
 * @param {string} message
 * @param {object} data
 * @returns
 */
export const setDataPayment = (message, data) => ({
  type: SET_DATA_PAYMENT,
  payload: {
    [message]: data,
  },
});

export const fetchKupon = async (kupon, token) => {
  const dateNow = Date.now() / 1000;
  // console.log('fetch kupon');
  try {
    const resp = await axios.get(
      `${api_course_url}/api/v1/prakerja/wc/coupons/checkV2?code=${kupon}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (!(resp.data?.succes || false)) {
      return {
        success: false,
        data: null,
        message: `*${resp.data?.message}`,
      };
    }
    return { success: true, data: resp.data };
  } catch (e) {
    const errorMessage = e.response.data.message;
    // e.response.data.message.includes("Kupon limit") ||
    // e.response.data.message.includes("kupon sudah dipakai!")
    //   ? "Kode voucher sudah digunakan. Kamu dapat mengecek di Pelatihan Saya untuk memastikan pelatihan telah terdaftar."
    //   : e.response.data.message;
    return {
      success: false,
      data: null,
      message: `*${errorMessage}`,
    };
  }
};

export const fetchRedeem = async (code, courseId, token) => {
  try {
    const getSignature = await axios.get(
      // `${api_redeem}/api/v1/signature/get?uri_path=status&redeem_code=${code}`,
      `${api_course_url}/api/v1/signature/get?uri_path=status&redeem_code=${code}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const signature = getSignature.data.data.signature;
    const timestamp = getSignature.data.data.timestamp;
    const getStatusRedeem = await axios.post(
      // `${api_redeem}/api/v1/integration/payment/redeem-code/status`,
      // `${api_course_url}/api/v1/prakerja/redeem-code/status`,
      `${api_course_url}/api/v1/check-status/reedem-code`,
      { redeem_code: code, course_id: courseId },
      {
        headers: {
          Authorization: token,
          // client_code: "arkad171586835374070688",
          signature: signature,
          timestamp: timestamp,
        },
      }
    );
    if (!(getStatusRedeem.data?.success || false)) {
      return { success: false, data: null };
    }
    return { success: true, data: getStatusRedeem.data.data };
  } catch (e) {
    return { success: false, data: e?.response?.data || null };
  }
};

export const commitRedeem = async (code, token) => {
  try {
    const getSignature = await axios.get(
      `${api_course_url}/api/v1/signature/get?uri_path=commit&redeem_code=${code}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const signature = getSignature.data.data.signature;
    const timestamp = getSignature.data.data.timestamp;
    const getStatusRedeem = await axios.post(
      // `${api_redeem}/api/v1/integration/payment/redeem-code/commit`,
      `${api_course_url}/api/v1/prakerja/redeem-code/commit`,
      { redeem_code: code },
      {
        headers: {
          client_code: "client-arkad171586835374070688",
          signature: signature,
          timestamp: timestamp,
        },
      }
    );
    if (!(getStatusRedeem.data?.success || false)) {
      return { success: false, data: null };
    }
    return { success: true, data: getStatusRedeem.data.data };
  } catch (e) {}
};

/**
 * @param {number[]} idKelas
 * @param {string} page
 */
export const fetchCourseCheckout = async (idKelas) => {
  try {
    const resp = await axios.get(
      `${api_course_url}/api/v1/prakerja/course/${idKelas}`,
      {
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    );
    if (resp.data.data && resp.data.data[0] && resp.data.data[0].course) {
      const course = resp.data.data[0].course;
      return { data: course, success: true };
    }
    return { data: null, success: false };
  } catch (e) {
    return { data: null, success: false };
  }
};

/**
 * @param {number[]} idKelas
 * @param {string} page
 */
export const fetchCourseCart = async (idKelas) => {
  const promiseHasil = [];
  const arHasil = [];
  for (let i = 0; i < idKelas.length; i++) {
    promiseHasil.push(
      new Promise((resolve, reject) => {
        try {
          axios
            .get(`${api_course_url}/api/v1/course/${idKelas[i]}`, {
              mode: "cors",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (
                response.data &&
                response.data.data[0] &&
                response.data.data[0].course
              ) {
                resolve(response.data.data[0]);
              }
            })
            .catch((e) => {
              resolve(e);
            });
        } catch (e) {
          resolve(e);
        }
      }).then((hasil) => {
        arHasil.push(hasil);
      })
    );
  }

  await Promise.all(promiseHasil);
  return arHasil;
};

const api_pembayaran = process.env.REACT_APP_API_BACKEND_ORDERS;

/**
 *
 * @param {number} idPengguna
 * @param {string} nama
 * @param {string} email
 * @param {string} telpon
 * @param {string} metode
 * @param {number} idProduk
 * @param {string} token
 * @param {number} diskon
 */
export async function uploadPembayaran(
  idPengguna,
  nama,
  email,
  telpon,
  metode,
  idProduk,
  kelas,
  codeCoupon,
  discountCoupon,
  redeemCode,
  dataCheckout,
  sesiTerpilih,
  schedule,
  dataCoupon
) {
  const pembayaran = new Pembayaran();
  const billing = new Billing();
  const lineItem = new LineItems();
  const lineFee = new LineFee();
  const lineFeeFlashSale = new LineFeeFlashSale();
  const lineFeeCoin = new LineFeeKoin();
  const lineKupon = new LineKupon();

  billing.first_name = nama.split(" ")[0];
  billing.last_name = nama.substring(billing.first_name.length).trim();
  billing.email = email;
  billing.phone = telpon;

  lineItem.sesi_id = sesiTerpilih;
  lineItem.product_id = idProduk;
  lineItem.quantity = 1;
  lineItem.sku = "-";
  lineItem.image_url = "-";
  lineItem.redeem_code = redeemCode;
  lineItem.course_id = kelas.idKelas;

  lineFee.name = "Diskon";
  lineFee.tax_status = "taxable";
  lineFee.amount = "0";
  lineFee.total = "0";

  lineFeeFlashSale.name = "Flash Sale";
  lineFeeFlashSale.tax_status = "taxable";
  lineFeeFlashSale.amount = "0";
  lineFeeFlashSale.total = "0";

  lineFeeCoin.name = "Coins";
  lineFeeCoin.tax_status = "taxable";
  lineFeeCoin.amount = "0";
  lineFeeCoin.total = "0";

  lineKupon.code = codeCoupon;
  lineKupon.discount = discountCoupon.toString();

  pembayaran.customer_id = idPengguna;
  pembayaran.cashback = 0;
  pembayaran.status = "pending";
  pembayaran.gelombang = dataCoupon?.gelombang;
  pembayaran.tanggal_mulai = getDateTimeYTZ(dataCoupon?.tanggal_mulai);
  pembayaran.tanggal_selesai = getDateTimeYTZ(dataCoupon?.tanggal_selesai);

  pembayaran.billing = billing;
  pembayaran.payment_method = metode;
  pembayaran.line_items = [lineItem];
  pembayaran.coupon_lines = [lineKupon];
  pembayaran.fee_lines = [lineFee, lineFeeFlashSale, lineFeeCoin];
  pembayaran.schedule_start_end = schedule;

  const pembayaranJSON = Object.assign({}, pembayaran);
  // console.log("hasil body", pembayaranJSON);
  let dataRespons = null;

  try {
    const res = await axios.post(
      // `${api_order}/api/v1/prakerja/order/create`,
      `${api_order}/api/v1/prakerja/order/createSPL`,
      pembayaranJSON,
      {
        headers: authHeader(),
      }
    );
    dataRespons = res?.data;
  } catch (e) {
    console.log(e);
    dataRespons = e.response.data;
  }

  const dataDispatch = {
    sukses: dataRespons?.success || false,
    dataPayment: dataRespons?.data || null,
    dataKelas: kelas,
    orderId: null,
  };
  dataRespons?.success &&
    localStorage.setItem("pembayaranProses", JSON.stringify(dataDispatch));
  dataRespons?.success &&
    store.dispatch({
      type: PAYMENT_PROCESSED,
      payload: {
        ["pembayaranProses"]: dataDispatch,
      },
    });
  return dataRespons;
}

/**
 *
 * @param {number} idPengguna
 * @param {string} nama
 * @param {string} email
 * @param {string} telpon
 * @param {string} metode
 * @param {number} idProduk
 * @param {string} token
 * @param {number} diskon
 */
export async function uploadPembayaranReguler(
  idPengguna,
  nama,
  email,
  telpon,
  metode,
  idProduk,
  kelas,
  codeCoupon,
  discountCoupon,
  redeemCode,
  dataCheckout,
  diskon,
  sesi,
  courseFlag,
  dataCoupon
) {
  const pembayaran = new Pembayaran();
  const billing = new Billing();
  const lineItem = new LineItems();
  const lineFee = new LineFee();
  const lineFeeFlashSale = new LineFeeFlashSale();
  const lineFeeCoin = new LineFeeKoin();
  const lineKupon = new LineKupon();

  billing.first_name = nama.split(" ")[0];
  billing.last_name = nama.substring(billing.first_name.length).trim();
  billing.email = email;
  billing.phone = telpon;

  lineItem.sesi_id = sesi;
  lineItem.product_id = idProduk;
  lineItem.quantity = 1;
  lineItem.sku = "-";
  lineItem.image_url = "-";
  lineItem.redeem_code = redeemCode;
  lineItem.course_id = kelas.idKelas;

  lineFee.name = "Diskon";
  lineFee.tax_status = "taxable";
  if (discountCoupon < 100) {
    lineFee.amount = "-" + diskon.toString();
    lineFee.total = "-" + diskon.toString();
  } else {
    lineFee.amount = "0";
    lineFee.total = "0";
  }

  lineFeeFlashSale.name = "Flash Sale";
  lineFeeFlashSale.tax_status = "taxable";
  lineFeeFlashSale.amount = "0";
  lineFeeFlashSale.total = "0";

  lineFeeCoin.name = "Coins";
  lineFeeCoin.tax_status = "taxable";
  lineFeeCoin.amount = "0";
  lineFeeCoin.total = "0";

  lineKupon.code = codeCoupon;
  lineKupon.discount = discountCoupon.toString();

  pembayaran.customer_id = idPengguna;
  pembayaran.cashback = 0;
  pembayaran.status = "pending";
  pembayaran.gelombang = dataCoupon?.gelombang;
  pembayaran.tanggal_mulai = getDateTimeYTZ(dataCoupon?.tanggal_mulai);
  pembayaran.tanggal_selesai = getDateTimeYTZ(dataCoupon?.tanggal_selesai);

  pembayaran.billing = billing;
  pembayaran.payment_method = metode;
  pembayaran.line_items = [lineItem];
  pembayaran.coupon_lines = [lineKupon];
  pembayaran.fee_lines = [lineFee, lineFeeFlashSale, lineFeeCoin];

  const pembayaranJSON = Object.assign({}, pembayaran);
  let dataRespons = null;

  try {
    const res = await axios.post(
      // courseFlag?.spl == "1"
      //   ? `${api_order}/api/v1/prakerja/order/createSPL`
      //   : `${api_order}/api/v1/prakerja/order/create`,
      `${api_order}/api/v1/prakerja/order/createSPL`,
      pembayaranJSON,
      {
        headers: authHeader(),
      }
    );
    dataRespons = res?.data;
  } catch (e) {
    console.log(e);
  }
  const dataDispatch = {
    sukses: dataRespons?.success || false,
    dataPayment: dataRespons?.data || null,
    dataKelas: kelas,
    orderId: null,
  };
  localStorage.setItem("pembayaranProses", JSON.stringify(dataDispatch));
  store.dispatch({
    type: PAYMENT_PROCESSED,
    payload: {
      ["pembayaranProses"]: dataDispatch,
    },
  });
  return dataRespons;
}

export async function checkOrder(idOrder, kelas, billing, titleMetode) {
  let data = {};
  let sukses = false;
  try {
    const res = await axios.get(
      `${api_order}/api/v1/prakerja/order/check/${idOrder}`,
      {
        headers: authHeader(),
      }
    );
    if (res?.data) {
      // console.log("response", res?.data);
      data = res?.data?.data;
      sukses = true;
    } else {
      sukses = false;
    }
  } catch (e) {
    console.log("gagal2");
    console.log(e);
  }
  // after success
  const dataDispatch = {
    sukses: sukses,
    dataPayment: data,
    dataKelas: kelas,
    dataUser: billing,
    methodTitle: titleMetode,
    orderId: data?.order_id,
  };
  localStorage.setItem("pembayaranProses", JSON.stringify(dataDispatch));
  store.dispatch({
    type: PAYMENT_PROCESSED,
    payload: {
      ["pembayaranProses"]: dataDispatch,
    },
  });
  return { sukses, data };
}

export async function checkOrderByPass(userId, productId, codeVoucher) {
  let data = {};
  let sukses = false;
  try {
    const res = await axios.get(
      `${api_order}/api/v1/prakerja/order/check?user_id=${userId}&product_id=${productId}&kupon=${codeVoucher}`,
      {
        headers: authHeader(),
      }
    );
    if (res?.data) {
      data = res?.data?.data;
      sukses = true;
    } else {
      sukses = false;
    }
  } catch (e) {
    console.log("gagal2");
    console.log(e);
  }
  // after success
  const dataDispatch = {
    sukses: sukses,
    dataPayment: data,
  };
  localStorage.setItem("pembayaranProses", JSON.stringify(dataDispatch));
  store.dispatch({
    type: PAYMENT_PROCESSED,
    payload: {
      ["pembayaranProses"]: dataDispatch,
    },
  });
  return { sukses, data };
}
