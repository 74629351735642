import "./DropdownProfile.scss";
import {
  IconExit,
  IconExitAc,
  IconUser,
  IconUserNew,
  LogoAvatar,
  UserProfile,
} from "assets";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import ListMenu from "json/ListMenu.json";

import useDimensiLayar from "utils/useDimensiLayar";
import useFetchAvatar from "utils/useFetchAvatar";
import useFetchProfile from "utils/useFetchProfile";
import { formatText } from "utils/formatText";

import Hover from "elements/Hover/Hover";
import ButtonMenuNotif from "elements/Button/ButtonMenu/ButtonMenuNotif";
import InfoSaldoNotif from "elements/Button/InfoSaldo/InfoSaldoNotif";

const DropdownProfile = ({ user, openLogout }) => {
  const [lebarLayar] = useDimensiLayar();
  const [userData, loadingUserData] = useFetchProfile();
  const [avatar, loadingAvatar] = useFetchAvatar();
  const [toggleMenu, setToggleMenu] = useState("close");

  return (
    <>
      <div className="menu-profile">
        <Hover
          mobile={true}
          toggleMenuMobile={toggleMenu}
          topHover={45}
          kananHover={0}
          onHover={
            <KomponenMenu
              userData={userData}
              openLogout={openLogout}
              avatar={avatar}
              loading={loadingAvatar}
              setToggleMenu={setToggleMenu}
            />
          }
        >
          <div
            className="icon_user"
            onClick={() => {
              console.log("click", toggleMenu);
              toggleMenu === "open"
                ? setToggleMenu("close")
                : setToggleMenu("open");
            }}
          >
            {loadingAvatar ? (
              <>
                {lebarLayar > 1000 && (
                  <Skeleton height={36} width={36} circle={true} />
                )}
                {lebarLayar < 1000 && (
                  <Skeleton height={25} width={25} circle={true} />
                )}
              </>
            ) : (
              <img alt="Notification" src={avatar ? avatar : UserProfile} />
            )}
            {/* {lebarLayar > 1000 && (
              <span>
                {loadingUserData == true ? (
                  <Skeleton height={10} width={100} />
                ) : (
                  userData?.fullname || "Anonim"
                )}
              </span>
            )} */}
          </div>
        </Hover>
      </div>
    </>
  );
};

const KomponenMenu = ({
  userData,
  openLogout,
  avatar,
  loading,
  setToggleMenu,
}) => {
  const user = useSelector((state) => state?.authorization?.user || null);
  // const [avatar, loadingAvatar] = useFetchAvatar();

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/dashboard/");

  const navigate = useNavigate();
  const [isHover, setIsHover] = useState("");
  const [lebarLayar] = useDimensiLayar();

  const handleClickMenu = (e) => {
    let nameMenu = e.target.value;
    navigate(`/dashboard/${nameMenu}`);
    setToggleMenu("close");
  };

  const handleEnterMenu = (e) => {
    let nameMenu = e.target.value;
    setIsHover(nameMenu);
  };

  return (
    <>
      <div className="card-dropdown-profile">
        <div className="card-head row m-0 pt-4 pb-4">
          <div className="col-3 left">
            {!loading && (
              <img
                src={avatar ? avatar : UserProfile}
                alt="icon profile"
                onError={(e) => {
                  e.target.setAttribute("src", UserProfile);
                  e.target.style = "object-position: 50%;";
                }}
              />
            )}
          </div>
          <div className="col-9 right d-flex flex-column justify-content-center">
            <h6 className="p-0 m-0">
              <Link to="/dashboard/profile" className="link-profile">
                {userData?.fullname || "Anonim"}
              </Link>
            </h6>
            <p className="p-0 m-0 m-0">
              {formatText(user?.userPrakerja?.email)}
            </p>
          </div>
        </div>
        {/* <div className="card-info-saldo">
        <div className="menu-list">
          <ul>
            <InfoSaldoNotif />
          </ul>
        </div>
      </div> */}
        <div className="card-body">
          <div className="align-items-center">
            <ul>
              {ListMenu &&
                ListMenu.map((i) => {
                  return (
                    <ButtonMenuNotif
                      key={i.id}
                      name={i.name}
                      nameClass={i.name + "menu-list"}
                      iconMenu={
                        splitLocation[1] == i.value || isHover == i.value
                          ? i.logo_active
                          : i.logo_name
                      }
                      classActive={
                        splitLocation[1] == `${i.value}` ? `active` : ``
                      }
                      value={i.value}
                      id={i.id}
                      handleClick={handleClickMenu}
                      handleEnter={handleEnterMenu}
                      handleLeave={() => setIsHover("")}
                    />
                  );
                })}
            </ul>
          </div>
        </div>
        {/* <div className="card-info-saldo-new">
          <div className="menu-list">
            <ul>
              <InfoSaldoNotif />
            </ul>
          </div>
        </div> */}
        <div className="card-bottom">
          <div className="btn-menu-notif-profile">
            <img src={isHover === "keluar" ? IconExitAc : IconExit} />
            <span>Keluar</span>
            <button
              type="button"
              className="button"
              onMouseEnter={() => setIsHover("keluar")}
              onMouseLeave={() => setIsHover("")}
              onClick={() => openLogout(true)}
            ></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DropdownProfile;
