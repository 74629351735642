import "./Submenu.scss";
import { FC, useEffect, useState } from "react";
import useCekDalamLayar from "utils/useCekDalamLayar";
import useScrollUpOrDown from "utils/useScrollUpOrDown";
import useDimensiLayar from "utils/useDimensiLayar";
import { scrollIntoViewOffset } from "utils/DOMfunction";

const Submenu: FC<ISubmenu> = ({
  refPilihSesi,
  refInformasi,
  refKurikulum,
  refReview,
}) => {
  const isiTab = ["Sesi", "Informasi", "Kurikulum", "Ulasan"];
  const isiRef = [refPilihSesi, refInformasi, refKurikulum, refReview];

  const [terpilih, setTerpilih] = useState<number>(5);

  const pilihSesiDalamLayar = useCekDalamLayar(refPilihSesi);
  const informasiDalamLayar = useCekDalamLayar(refInformasi);
  const kurikulumDalamLayar = useCekDalamLayar(refKurikulum);
  const reviewDalamLayar = useCekDalamLayar(refReview);

  useEffect(() => {
    if (pilihSesiDalamLayar) {
      setTerpilih(0);
    } else if (informasiDalamLayar) {
      setTerpilih(1);
    } else if (kurikulumDalamLayar) {
      setTerpilih(2);
    } else if (reviewDalamLayar) {
      setTerpilih(3);
    }
  }, [
    pilihSesiDalamLayar,
    informasiDalamLayar,
    kurikulumDalamLayar,
    reviewDalamLayar,
  ]);

  const scroll = useScrollUpOrDown();
  const [lebarLayar] = useDimensiLayar();

  return (
    <div
      className="submenu-course-wrapper"
      style={scroll === "up" ? { top: lebarLayar < 1000 ? 51 : 64 } : {}}
    >
      <div className="container-list-menu">
        <ul className="list-menu">
          {isiTab.map((nilai: any, indeks: number) => {
            return (
              <IsiTabCourse
                teks={indeks}
                aktif={terpilih === indeks}
                key={indeks}
                refScroll={isiRef[indeks]}
                isiTab={isiTab}
                setTerpilih={setTerpilih}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const IsiTabCourse: FC<IISiTabCourse> = ({
  teks,
  aktif,
  refScroll,
  isiTab,
  setTerpilih,
}) => {
  const scrolltoRef = (h: any) => {
    scrollIntoViewOffset(refScroll, h);
  };
  return (
    <>
      <li
        onClick={() => {
          setTerpilih(teks);
          scrolltoRef(40);
        }}
        className={aktif ? "menu-tab aktif" : "menu-tab"}
        id={`wb-prakerja-tab-${isiTab[teks]?.replace(/ /g, "-").toLowerCase()}`}
      >
        <p
          className="text-menu"
          id={`wb-prakerja-tab-${isiTab[teks]
            ?.replace(/ /g, "-")
            .toLowerCase()}`}
        >
          {isiTab[teks]}
        </p>
      </li>
    </>
  );
};

interface ISubmenu {
  refPilihSesi: any;
  refInformasi: any;
  refKurikulum: any;
  refReview: any;
}

interface IISiTabCourse {
  teks: any;
  aktif: any;
  refScroll: any;
  isiTab: any;
  setTerpilih: (teks: any) => void;
}

export default Submenu;
