import {
  ADD_CART,
  FETCH_AVATAR,
  UPLOAD_AVATAR,
  FETCH_CERTIFICATE_USER,
  FETCH_COURSES_USER,
  FETCH_PROFILE_USER,
  FETCH_NOTIFICATION_NEW,
  UPDATE_NOTIFICATION,
  UPDATE_PASSWORD,
  UPDATE_PROFILE,
  UPDATE_EDIT_COIN,
  RESET_PASSWORD_EMAIL,
  UPDATE_EMAIL,
  FETCH_VERIFY_EMAIL,
  CHANGE_PASSWORD,
} from "store/types";

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_AVATAR:
      return {
        ...state,
        ...action.payload,
      };
    case UPLOAD_AVATAR:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_PROFILE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_COURSES_USER:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_CERTIFICATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_NOTIFICATION_NEW:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_CART:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_PASSWORD:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_EDIT_COIN:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_EMAIL:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_VERIFY_EMAIL:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_PASSWORD_EMAIL:
      return {
        ...state,
        ...action.payload,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
