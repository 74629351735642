import "./FasilitatorInstructor.scss";
import { FC } from "react";

const FasilitatorInstructor: FC<IFasilitatorInstructor> = ({
  dataWebinar,
  loading,
}) => {
  const namaPembicara = new Set();
  return (
    <div className="coursepage-fasilitator-instructor-wrapper">
      {/* <div className="section-fasilitator">
        <h3 className="title">Fasilitator</h3>
        <div className="list-fasilitator d-flex">
          {dataWebinar?.fasilitator?.map((item: any, indeks: any) => {
            return (
              <div className="item-fasilitator d-flex" key={indeks}>
                <div className="image-fasilitator">
                  <img src={item?.avatar} alt="" className="img-fasilitator" />
                </div>
                <div className="body-fasilitator d-flex">
                  <p className="name">{item?.name}</p>
                  <p className="description">{item?.deskripsi}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
      <div className="section-instructor">
        <h3 className="title">Pembicara</h3>
        <div className="list-instructor d-flex">
          {dataWebinar?.pembicara
            ?.filter((item: any) => item?.nama != "")
            .map((item: any, indeks: any) => {
              if (namaPembicara.has(item?.nama)) {
                return null;
              }
              namaPembicara.add(item?.nama);
              return (
                <div className="item-instructor d-flex" key={indeks}>
                  <img src={item?.avatar} alt="" className="img-instructor" />
                  <div className="body-instructor d-flex">
                    <p className="name">{item?.nama}</p>
                    <p className="jabatan">{item?.posisi}</p>
                    <p className="description">{item?.deskripsi}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

interface IFasilitatorInstructor {
  dataWebinar: any;
  loading: boolean;
}

export default FasilitatorInstructor;
