import "./Sesi.scss";
import { FC, useEffect, useState } from "react";
import CardSesi from "./CardSesi";
import {
  getDayOnlyNumeric,
  getFullDate,
  getFullDateNoYear,
} from "utils/formatDateWebinar";
import Skeleton from "react-loading-skeleton";

const Sesi: FC<ISesi> = ({
  dataWebinar,
  loading,
  refPilihSesi,
  error,
  sesiPenuh,
}) => {
  // console.log(dataWebinar);
  const sort = dataWebinar?.product_limit?.sort((a: any, b: any) => {
    return a.sesi - b.sesi;
  });

  return (
    <div className="coursepage-sesi-wrapper" ref={refPilihSesi}>
      {dataWebinar?.pilihan_sesi ? (
        <>
          <h3 className="title-voucher">Pilih Sesi Webinar</h3>
          <p className="subtitle-voucher">
            Gelombang {dataWebinar?.gelombang}:{" "}
            {`${getFullDateNoYear(dataWebinar?.tanggal_mulai)} - ${getFullDate(
              dataWebinar?.tanggal_selesai
            )}`}
          </p>
        </>
      ) : (
        <h3 className="title">Sesi Tersedia</h3>
      )}
      <div
        className="list-card-sesi d-flex"
        style={loading ? { justifyContent: "center" } : {}}
      >
        {loading ? (
          <>
            {Array(3)
              .fill(3)
              .map(() => (
                <Skeleton className="" width={"200px"} height={"200px"} />
              ))}
          </>
        ) : (
          dataWebinar?.product_limit
            ?.map((item: any, indeks: number) => {
              if (item?.order_limit > 0) {
                if (
                  dataWebinar?.pembicara[indeks].nama !== "" ||
                  dataWebinar?.pembicara[indeks].deskripsi !== ""
                ) {
                  return (
                    <CardSesi
                      sesi={item?.sesi}
                      orderCount={item?.order_count}
                      orderLimit={item?.order_limit}
                      pembicara={
                        dataWebinar?.pembicara || dataWebinar?.pilihan_sesi
                      }
                      error={error}
                    />
                  );
                }
              }
            })
            .sort(() => sort)
        )}
      </div>
      {/* {error && <p className="label-error">*Wajib pilih sesi</p>} */}
      {sesiPenuh && (
        <p className="label-error">
          *Semua sesi telah penuh, silakan tunggu gelombang berikutnya pada
          tanggal {getDayOnlyNumeric(dataWebinar?.tanggal_mulai_next)} -{" "}
          {getFullDate(dataWebinar?.tanggal_selesai_next)}
        </p>
      )}
    </div>
  );
};

interface ISesi {
  dataWebinar: any;
  loading: boolean;
  refPilihSesi: any;
  error: boolean;
  sesiPenuh: boolean;
}

export default Sesi;
