import {
  SET_MESSAGE,
  CLEAR_MESSAGE,
  SET_COURSE_STATUS_CLASS,
  SET_DATA,
} from "store/types";

export const setMessage = (message) => ({
  type: SET_MESSAGE,
  payload: message,
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});

/**
 * @deprecated gunakan useSetDataWatchCourse()
 * @param {string} dataName 
 * @param {any} data
 * @returns 
 */
export const setDataGlobalWatchCourse = (dataName, data) => ({
  type: SET_DATA,
  payload: {
    [dataName]: data,
  },
});
