import "./Fitur.scss";
import { FC } from "react";
import IconOnline from "./icon-online.svg";
import IconApp from "./icon-app.svg";

const Fitur: FC<IFitur> = ({}) => {
  return (
    <div className="coursepage-fitur-wrapper">
      <h3 className="title">Fitur Pelatihan</h3>
      <div className="list-fitur d-flex">
        <div className="item-fitur d-flex">
          <img src={IconOnline} alt="" className="icon-fitur" />
          <p className="text">100% online</p>
        </div>
        <div className="item-fitur d-flex">
          <img src={IconApp} alt="" className="icon-fitur" />
          <p className="text">Tersedia di App dan Web</p>
        </div>
      </div>
    </div>
  );
};

interface IFitur {}

export default Fitur;
