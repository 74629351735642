import {
  FETCH_AVATAR,
  UPLOAD_AVATAR,
  FETCH_PROFILE_USER,
  FETCH_COURSES_USER,
  FETCH_CERTIFICATE_USER,
  FETCH_NOTIFICATION_NEW,
  UPDATE_NOTIFICATION,
  UPDATE_PASSWORD,
  UPDATE_PROFILE,
  UPDATE_EDIT_COIN,
  UPDATE_EMAIL,
  FETCH_VERIFY_EMAIL,
  RESET_PASSWORD_EMAIL,
  CHANGE_PASSWORD,
} from "store/types";

import axios from "axios";
import { authHeaderFile, authHeader } from "utils/auth-header";
import store from "store";

const api_url = process.env.REACT_APP_API_BACKEND_OLD;
const api_url_member = process.env.REACT_APP_API_BACKEND_MEMBER;
const api_url_coin = process.env.REACT_APP_API_FIREBASE;
export const fetchProfileUser = (dasboard) => (dispatch) => {
  return axios
    .get(api_url + `/api/v1/profile?tab=profile`, {
      headers: authHeader(),
    })
    .then((response) => {
      dispatch({
        type: FETCH_PROFILE_USER,
        payload: { [dasboard]: response.data.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updatePassword = (data, dashboard, dispatch) => {
  return axios
    .put(api_url_member + `/api/v1/prakerja/auth/password/change`, data, {
      headers: authHeader(),
    })
    .then((response) => {
      // if (response.data.success != true) {
      //   console.log("password salah", response.data);
      // } else {
      //   console.log("password benar", response.data);
      // }
      dispatch({
        type: UPDATE_PASSWORD,
        payload: { [dashboard]: response.data },
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_PASSWORD,
        payload: { [dashboard]: err.response },
      });
      // console.log("gagal", err.response);
    });
};
export const resetPasswordEmail = (data, dashboard, dispatch) => {
  return axios
    .post(api_url_member + `/api/v1/user/password/reset`, data, {
      headers: authHeader(),
    })
    .then((response) => {
      // console.log('reset email', response);
      // if (response.data.success != true) {
      //   console.log("password salah", response.data);
      // } else {
      //   console.log("password benar", response.data);
      // }
      dispatch({
        type: RESET_PASSWORD_EMAIL,
        payload: { [dashboard]: response.data },
      });
    })
    .catch((err) => {
      dispatch({
        type: RESET_PASSWORD_EMAIL,
        payload: { [dashboard]: err.response },
      });
      // console.log("gagal", err.response);
    });
};

export const updatePicture = (file) => {};

export const updateProfile = (data, dispatch, isPromo) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const id_user = user?.user?.id;
  return axios
    .put(api_url_member + `/api/v1/prakerja/auth/update/profile`, data, {
      headers: authHeader(),
    })
    .then((response) => {
      // console.log("res", response);
      if (response?.data?.success) {
        // console.log("s", isPromo);
        const profileNew = {
          fullname: data.nama_lengkap,
          location: data.lokasi,
          profession: data.profesi,
          bio: data.biodata,
          facebook: data.facebook,
          twitter: data.twitter,
          jenis_kelamin: data.jenis_kelamin,
          no_hp: data.no_hp,
          tgl_lahir: data.tgl_lahir,
          provinsi: data.provinsi,
          kota: data.kota,
          pendidikan_terakhir: data.pendidikan_terakhir,
        };

        dispatch({
          type: UPDATE_PROFILE,
          payload: { ["profile"]: profileNew },
        });

        if (isPromo == false) {
          updateEditCoin(
            {
              user_id: Number(id_user),
              already_completed: isPromo,
            },
            dispatch
          );
        }

        return { success: true, data: response?.data };
      } else {
        return { success: false, data: null };
      }
    })
    .catch((err) => {
      // console.log("gagal dapat data", err);
      console.log(err);
      return { success: false, data: err };
    });
};

const cekLengkap = (data) => {
  return (
    data.nama_lengkap &&
    data.lokasi &&
    data.profesi &&
    data.biodata &&
    data.facebook &&
    data.twitter &&
    data.jenis_kelamin &&
    data.no_hp &&
    data.tgl_lahir &&
    data.provinsi &&
    data.kota &&
    data.pendidikan_terakhir
  );
};

export const updateEditCoin = (data, dispatch) => {
  console.log("data up koin:", data);
  return axios
    .post(api_url_coin + `/v2/edit-akun-arkademi-coins`, data, {
      headers: authHeader(),
    })
    .then((response) => {
      // console.log("respon", response);
      dispatch({
        type: UPDATE_EDIT_COIN,
        payload: { ["changeCoins"]: response.data },
      });
    })
    .catch((err) => {
      // console.log("gagal dapat data", err);
      console.log(err);
    });
};

export const fetchCourseUser = (dashboard) => (dispatch) => {
  return axios
    .get(api_url + `/api/v1/prakerja/profile?tab=courses`, {
      headers: authHeader(),
    })
    .then((response) => {
      dispatch({
        type: FETCH_COURSES_USER,
        payload: { [dashboard]: response.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchCertificateUser = (dashboard) => (dispatch) => {
  // console.log("2. masuk action");
  const user = JSON.parse(localStorage.getItem("user"));
  const idU = user?.user?.id;
  return axios
    .get(api_url_member + `/api/arkademi/get_user_certificate/${idU}`, {
      headers: authHeader(),
    })
    .then((response) => {
      // console.log("3. berhasil dapat data", response);
      dispatch({
        type: FETCH_CERTIFICATE_USER,
        payload: { [dashboard]: response.data },
      });
    })
    .catch((err) => {
      // console.log("3. gagal dapat data");
      console.log(err);
    });
};

export const fetchProfile = (dasboard, dispatch) => {
  return axios
    .get(api_url_member + `/api/v1/prakerja/profile?tab=profile`, {
      headers: authHeader(),
    })
    .then((response) => {
      dispatch({
        type: FETCH_PROFILE_USER,
        payload: { [dasboard]: response.data.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchCourse = (dasboard, dispatch) => {
  return axios
    .get(api_url_member + `/api/v1/prakerja/profile?tab=courses`, {
      headers: authHeader(),
    })
    .then((response) => {
      dispatch({
        type: FETCH_COURSES_USER,
        payload: { [dasboard]: response },
      });
    })
    .catch((err) => {
      // console.log(err.response);
      dispatch({
        type: FETCH_COURSES_USER,
        payload: { [dasboard]: err.response?.data?.status_code },
      });
    });
};

export const fetchCertificate = (dasboard, dispatch) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const idU = user?.userPrakerja?.id;
  return axios
    .get(api_url_member + `/api/arkademi/get_user_certificate/${idU}`, {
      headers: authHeader(),
    })
    .then((response) => {
      dispatch({
        type: FETCH_CERTIFICATE_USER,
        payload: { [dasboard]: response.data.certificates },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchAvatar = (dasboard, dispatch) => {
  return axios
    .get(api_url_member + `/api/v1/prakerja/user/avatar`, {
      headers: authHeader(),
    })
    .then((response) => {
      dispatch({
        type: FETCH_AVATAR,
        payload: { [dasboard]: response.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const uploadAvatar = (data, dispatch) => {
  const formData = new FormData();
  formData.append("image", data.image);
  return axios
    .post(api_url_member + `/api/v1/prakerja/user/avatar`, formData, {
      headers: authHeaderFile(),
    })
    .then((response) => {
      // console.log("response", response);
      dispatch({
        type: UPLOAD_AVATAR,
        payload: { ["avatar"]: response.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateEmail = (data, dispatch) => {
  // console.log("data", data);
  return axios
    .post(api_url_member + `/api/v1/user/email`, data, {
      headers: authHeader(),
    })
    .then((response) => {
      // console.log("response", response);
      dispatch({
        type: UPDATE_EMAIL,
        payload: { ["changeEmail"]: response.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const verifyEmail = (token, dispatch) => {
  // console.log("data", token);
  return axios
    .get(api_url_member + `/api/v1/user/email/verify?token=${token}`)
    .then((response) => {
      // console.log("berhasil ganti", response);
      dispatch({
        type: FETCH_VERIFY_EMAIL,
        payload: { ["verifyEmail"]: response.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const changePasswordEmail = (data, token, dispatch) => {
  return axios
    .post(
      api_url_member + `/api/v1/user/password/change?token=${token}`,
      data,
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      // console.log('reset email', response);
      // if (response.data.success != true) {
      //   console.log("password salah", response.data);
      // } else {
      //   console.log("password benar", response.data);
      // }
      dispatch({
        type: CHANGE_PASSWORD,
        payload: { ["changePasswordEmail"]: response.data },
      });
    })
    .catch((err) => {
      dispatch({
        type: CHANGE_PASSWORD,
        payload: { ["changePasswordEmail"]: err.response },
      });
      // console.log("gagal", err.response);
    });
};
